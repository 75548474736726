import { Box, Skeleton, Stack, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

export const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  boxShadow: `0px 6px 20px 0px ${theme.colors.action.hoverShadow}`,
}));

export const HeaderTextSkeleton = styled(Skeleton)(({ theme }) => ({
  [theme.breakpoints.up("mobile")]: {
    width: "100%",
  },
  [theme.breakpoints.up("tablet")]: {
    width: "50%",
  },
  [theme.breakpoints.up("desktop")]: {
    width: "60%",
  },
  height: "48px",
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.up("mobile")]: {
    width: "100%",
  },
  [theme.breakpoints.up("tablet")]: {
    width: "50%",
  },
  [theme.breakpoints.up("desktop")]: {
    width: "60%",
  },
}));

export const HeaderStack = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const HeaderActionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.up("mobile")]: {
    width: "100%",
  },
  [theme.breakpoints.up("tablet")]: {
    width: "50%",
    justifyContent: "right",
  },
  [theme.breakpoints.up("desktop")]: {
    width: "40%",
    justifyContent: "right",
  },
}));
