import * as Styled from "./AttendanceTab.styles";

import {
  Box,
  CircularProgress,
  IconButton,
  Skeleton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { Attendee } from "../../../../types/attendee";
import { Contact } from "../../../../components";
import RefreshIcon from "@mui/icons-material/Refresh";
import { RootState } from "../../../../store/store";
import { refreshLiveEventAttendees } from "../../../../store/actions/liveEventActions";
import { useAppDispatch } from "../../../../store/hooks";
import { useSelector } from "react-redux";

const AttendanceTab = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { eventAttendees, event } = useSelector((state: RootState) => ({
    event: state.liveEvent.data,
    eventAttendees: state.liveEvent.attendees,
  }));

  return (
    <Styled.PaddedContainer>
      {eventAttendees.loading ? (
        <>
          <Stack
            direction="row"
            sx={{
              marginBottom: theme.spacing(3),
            }}
          >
            <Styled.AttendanceText variant="h3">
              Attendance
            </Styled.AttendanceText>
            <Styled.AttendanceCountContainer>
              <Styled.AttendanceIcon />
              <Skeleton variant="text" sx={{ width: "24px" }}></Skeleton>
            </Styled.AttendanceCountContainer>
          </Stack>
          {Array(12).fill(
            <Styled.ContactSkeletonContainer>
              <Styled.ContactAvatarSkeleton variant="circular" />
              <Skeleton
                variant="text"
                sx={{ width: "400px", height: "36px" }}
              />
            </Styled.ContactSkeletonContainer>
          )}
        </>
      ) : (
        <>
          <Stack
            direction="row"
            sx={{
              marginBottom: theme.spacing(3),
            }}
          >
            <Box display="flex" alignItems="center" style={{ width: "80%" }}>
              <Box marginRight="8px">
                {eventAttendees.refreshing ? (
                  <Box margin="8px">
                    <CircularProgress size={"24px"} />
                  </Box>
                ) : (
                  <IconButton
                    onClick={() => {
                      if (event) {
                        dispatch(refreshLiveEventAttendees(event.eventId));
                      }
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                )}
              </Box>
              <Typography variant="h3">Attendance</Typography>
            </Box>
            <Styled.AttendanceCountContainer>
              <Styled.AttendanceIcon />
              <Typography variant="body1">
                {eventAttendees.data.length}
              </Typography>
            </Styled.AttendanceCountContainer>
          </Stack>
          {eventAttendees.data.map((attendee: Attendee) => {
            return <Contact size={36} name={attendee.participantName} />;
          })}
        </>
      )}
    </Styled.PaddedContainer>
  );
};

export default AttendanceTab;
