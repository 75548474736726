import { Box, BoxProps } from "@mui/material";

import { styled } from "@mui/material/styles";

interface DraggableBoxProps extends BoxProps {
  "data-is-dragging": boolean;
}

export const DraggableBox = styled(Box)<DraggableBoxProps>(
  ({ theme, "data-is-dragging": isDragging }) => ({
    padding: theme.spacing(1),
    backgroundColor: isDragging ? theme.colors.neutral.softGray : "initial",
    transition: "background-color 0.3s ease, box-shadow 0.3s ease",
    boxShadow: isDragging
      ? `0px 6px 20px 0px ${theme.colors.action.hoverShadow}`
      : "initial",
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1),
  })
);

export const QuestionTypeContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("mobile")]: {
    width: "100%",
    marginRight: 0,
    marginBottom: theme.spacing(1),
  },
  [theme.breakpoints.up("tablet")]: {
    width: "200px",
    marginRight: "16px",
  },
  [theme.breakpoints.up("desktop")]: {
    width: "200px",
    marginRight: "16px",
  },
  flexShrink: 0,
}));

export const MultipleChoiceDragDropContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(1),
  marginLeft: 0,
  [theme.breakpoints.up("tablet")]: {
    marginLeft: "200px",
  },
  [theme.breakpoints.up("desktop")]: {
    marginLeft: "200px",
  },
  paddingLeft: "8px",
}));

export const MultipleChoiceAnswerContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.up("mobile")]: {
    width: "95%",
  },
  [theme.breakpoints.up("tablet")]: {
    width: "85%",
  },
  [theme.breakpoints.up("desktop")]: {
    width: "80%",
  },
}));
