import axios, { CancelTokenSource } from "axios";

import { SEARCH_EVENTS } from "../../services/endpoints";
import axiosAPI from "../../services/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

// Declare variables for cancel token and timer
let cancelTokenSource: CancelTokenSource;
let timerId: NodeJS.Timeout;

// This function is used to perform the API request. It's wrapped in a Promise to handle the debounce functionality.
const debouncedSearch = (term: string, filter: any, thunkAPI: any) =>
  new Promise(async (resolve, reject) => {
    // If there's a previous request, cancel it
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }

    // If there's a previous timer, clear it
    if (timerId) {
      clearTimeout(timerId);
    }

    // Set a new timer
    timerId = setTimeout(async () => {
      // Create a new cancel token for the new request
      cancelTokenSource = axios.CancelToken.source();

      try {
        // Perform the API request
        const { data } = await axiosAPI.request({
          url: SEARCH_EVENTS(term, filter),
          method: "GET",
          cancelToken: cancelTokenSource.token,
        });
        // If the request is successful, resolve the Promise with the response data
        resolve(data);
      } catch (error: any) {
        // If an error occurs, check if it's because the request was cancelled
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          // If the error is not due to cancellation, reject the Promise with the error message
          if (error.response && error.response.data.message) {
            thunkAPI.rejectWithValue(error.response.data.message);
          } else {
            thunkAPI.rejectWithValue(error.message);
          }
        }
        // Regardless of the error, resolve the Promise with an empty array
        resolve({ result: [] });
      }
    }, 500); // The debounce delay in milliseconds
  });

// This is the async thunk that uses the debouncedSearch function
export const searchEvents = createAsyncThunk<
  { data: any; term: string; filter: string },
  { term: string; filter: string }
>("events/search", async ({ term, filter }, thunkAPI) => {
  // Modify the call to debouncedSearch to include the filter parameter
  const data = await debouncedSearch(term, filter, thunkAPI);
  return { data, term, filter };
});
