import * as Styled from "./SearchMenu.styles";

import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback } from "react";

import ClearIcon from "@mui/icons-material/Clear";
import { Event } from "../../types/event";
import { RootState } from "../../store/store";
import SearchEventCard from "../SearchEventCard/SearchEventCard";
import SearchIcon from "@mui/icons-material/Search";
import { SearchLense } from "../../assets";
import { clearResults } from "../../store/reducers/SearchReducer/SearchReducer";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

interface SearchProps {
  inputValue: string;
  filter: string;
  setFilter: any;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleDrawerClose: any;
}

const SearchMenu: React.FC<SearchProps> = ({
  inputValue,
  filter,
  setFilter,
  setInputValue,
  onInputChange,
  handleDrawerClose,
}) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const searchResults = useSelector((state: RootState) => state.search.data);
  const searchLoading = useSelector((state: RootState) => state.search.loading);

  const isDesktop = useMediaQuery((theme: any) =>
    theme.breakpoints.up("desktop")
  );

  const clearInput = useCallback(() => {
    setInputValue("");
    dispatch(clearResults());
  }, [dispatch, setInputValue]);

  return (
    <Styled.MenuContainer>
      {!isDesktop && (
        <Styled.Search>
          <Styled.SearchIconWrapper>
            <SearchIcon />
          </Styled.SearchIconWrapper>
          <Styled.InputPlaceholder
            placeholder="Find an event…"
            inputProps={{ "aria-label": "search" }}
            value={inputValue}
            onChange={onInputChange}
          />
          {inputValue && (
            <Styled.ClearIconWrapper>
              <ClearIcon style={{ color: "black" }} onClick={clearInput} />
            </Styled.ClearIconWrapper>
          )}
        </Styled.Search>
      )}
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={filter === "ALL"}
            onChange={() => {
              setFilter(filter === "ALL" ? "ACTIVE" : "ALL");
            }}
          />
        }
        style={{ marginBottom: theme.spacing(1) }}
        label="Include past events"
      />
      {searchLoading ? (
        <Styled.CenteredBox padding={theme.spacing(12)}>
          <CircularProgress />
        </Styled.CenteredBox>
      ) : searchResults && searchResults.length === 0 && inputValue !== "" ? (
        <Styled.NoResultsContainer>
          <Styled.ResultImage src={SearchLense} alt="search-lense" />
          <Styled.ResultTextContainer>
            <Typography variant="h2" marginBottom={theme.spacing(1)}>
              No events found
            </Typography>
            <Typography variant="body1">
              Looks like we can’t find the event you’re looking for. Try
              searching by a different event title.
            </Typography>
          </Styled.ResultTextContainer>
        </Styled.NoResultsContainer>
      ) : (
        inputValue !== "" && (
          <Box style={{}}>
            <Typography variant="h3">{`Results (${searchResults.length})`}</Typography>
            {searchResults.map((event: Event) => (
              <SearchEventCard
                key={event.eventId}
                {...event}
                onClick={() => {
                  navigate(`/events?eventId=${event.eventId}`, {
                    replace: true,
                  });
                  handleDrawerClose();
                }}
              />
            ))}
          </Box>
        )
      )}
    </Styled.MenuContainer>
  );
};

export default SearchMenu;
