import * as Styled from "../UploadEventPhotoModal/UploadEventPhotoModal.styles";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { ChipListInput, Input } from "../../components";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import BaseModal from "../BaseModal/BaseModal";
import { POST_EVENT_IMAGE } from "../../services/endpoints";
import axiosAPI from "../../services/axios";
import imageCompression from "browser-image-compression";
import { refreshLiveEventPhotos } from "../../store/actions/liveEventActions";
import { toBase64 } from "../../utils/utils";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useTheme } from "@mui/material";

type UploadEventPhotoModalProps = {
  open: any;
  onClose: any;
  selectedImg?: any;
  photoName: string;
  eventId?: any;
  uploadEventPhotoFile?: File | null;
};

type UploadEventPhotoForm = {
  tags: string[];
  caption?: string;
};

const UploadEventPhotoModal = ({
  open,
  onClose,
  selectedImg,
  photoName,
  eventId,
  uploadEventPhotoFile,
}: UploadEventPhotoModalProps) => {
  const theme = useTheme();
  const user = useSelector((state: any) => state.user);
  const [errorMessage, setErrorMessage] = useState("");
  const [hovered, setHovered] = useState(false);

  const formMethods = useForm({
    defaultValues: {
      tags: [],
      caption: "",
    },
    mode: "onChange",
  });

  const dispatch = useAppDispatch();
  const [uploadPhotoLoading, setUploadPhotoLoading] = useState(false);

  const uploadImage = async (file: File | undefined) => {
    const options = {
      maxSizeMB: 3.0, // (max file size in MB)
      maxWidthOrHeight: 1920, // compress the image to this maximum width or height
      useWebWorker: true,
    };
    if (!file) return "";
    try {
      const compressedFile = await imageCompression(file, options);
      return await toBase64(compressedFile);
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error; // Throw the error so it can be caught in the parent scope
    }
  };

  const postEventImage = async (eventId: string, photoUpload: any) => {
    if (typeof eventId !== "string") return;
    try {
      await axiosAPI.post(POST_EVENT_IMAGE(eventId), photoUpload);
      dispatch(refreshLiveEventPhotos(eventId));
    } catch (error) {
      console.error("Error posting event image:", error);
      throw error; // Throw the error so it can be caught in the parent scope
    }
  };

  const onSubmit: SubmitHandler<UploadEventPhotoForm> = async (data) => {
    setUploadPhotoLoading(true);
    try {
      if (uploadEventPhotoFile) {
        const base64Image = await uploadImage(uploadEventPhotoFile);
        const newPhotoUpload = {
          name: `live-${eventId}-${uploadEventPhotoFile?.name}`,
          data: base64Image,
          uploaderName: user.name,
          uploaderEmail: user.email,
          caption: data.caption,
        };
        await postEventImage(eventId, newPhotoUpload);
        onClose();
        formMethods.reset();
      }
    } catch (error) {
      setErrorMessage("Upload failed. Please try again.");
    } finally {
      setUploadPhotoLoading(false);
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      tabletWidth="80%"
      desktopWidth="940px"
      fullScreenMobile
      overflow="auto"
    >
      <Stack
        direction="column"
        width="100%"
        style={{ marginTop: theme.spacing(1) }}
      >
        <Box
          sx={{
            maxHeight: "95vh",
          }}
        >
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Styled.UploadPhotoHeaderText variant="h1">
                Upload preview
              </Styled.UploadPhotoHeaderText>
              <Styled.UploadPhotoSubText variant="modalBody">
                After upload, photo will be viewable by all attendees on the
                “Photos” tab of the live event space.
              </Styled.UploadPhotoSubText>
              <Styled.UploadPhotoSubText variant="modalBody">
                <span style={{ fontFamily: "Slalom Sans Bold" }}>
                  Reminder:{" "}
                </span>{" "}
                If you plan to upload or appear in event photos please fill out
                this 
                <span
                  style={{
                    color: theme.colors.primary.slalomBlue,
                    textDecoration: "underline",
                    cursor: "pointer",
                    transition: "opacity 0.3s ease",
                    opacity: hovered ? 0.7 : 1,
                  }}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  onClick={() => {
                    window.open(
                      "https://slalom1.na1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhAQGijvLN7znz6NyODCVKpcLGqdP-FohfL3JzXnLpRcfJmbmeZUdWU2iQ4JEN9x4Bk",
                      "_blank"
                    );
                  }}
                >
                  Photo Release Waiver
                </span>
                {`. For the “Subject matter of Media” part of the form, please
                enter {x}.`}
              </Styled.UploadPhotoSubText>
              <Box
                display="flex"
                justifyContent="space-between"
                style={{
                  marginBottom: theme.spacing(3),
                }}
              >
                <Typography variant="modalSubtitle">{photoName}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    "&:hover": {
                      opacity: 0.7,
                    },
                  }}
                  onClick={() => {
                    formMethods.reset();
                    onClose();
                  }}
                >
                  <Typography
                    style={{ marginRight: theme.spacing(1) }}
                    variant="body2"
                  >
                    Remove Photo
                  </Typography>
                  <Styled.RemoveIcon />
                </Box>
              </Box>
              <Styled.SelectedImage src={selectedImg} alt="" />
              <Input
                name={"caption"}
                control={formMethods.control}
                label={"Photo Caption"}
                required
                placeholder="Enter caption..."
                fullWidth
              />

              <ChipListInput
                name={"tags"}
                control={formMethods.control}
                label={"Tags"}
                placeholder="Start typing a name..."
                fullWidth
              />
              <Divider
                sx={{
                  width: "100%",
                  marginTop: theme.spacing(1),
                  marginBottom: theme.spacing(3),
                }}
              ></Divider>
              <Box
                display="flex"
                justifyContent="flex-end"
                sx={{ paddingBottom: theme.spacing(2) }}
              >
                {uploadPhotoLoading ? (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        marginRight: "24px",
                      }}
                    />
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      [theme.breakpoints.down("tablet")]: {
                        width: "100%",
                      },
                    }}
                  >
                    Upload
                  </Button>
                )}
                {errorMessage && (
                  <Typography color="error">{errorMessage}</Typography>
                )}
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Stack>
    </BaseModal>
  );
};

export default UploadEventPhotoModal;
