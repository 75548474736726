import * as Styled from "./EditEventPage.styles";

import {
  APIError,
  ChipListInput,
  DatePickerInput,
  FileInput,
  FormBuilder,
  Input,
  MeetingRoom,
  PageContainer,
  RadioGroup,
  RichTextEditor,
  TimeRangePicker,
} from "../../components";
import {
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import { EditorState, convertToRaw } from "draft-js";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import DOMPurify from "dompurify";
import { EDIT_EVENT } from "../../services/endpoints";
import { Event } from "../../types/event";
import { Room } from "../../types/room";
import { RoomFinderModal } from "../../modals";
import { RootState } from "../../store/store";
import { UserState } from "../../types/redux";
import _ from "lodash";
import axiosAPI from "../../services/axios";
import { combineDayJSToUnix } from "../../utils/utils";
import draftToHtml from "draftjs-to-html";
import { editEvent } from "../../store/reducers/EditEventReducer/EditEventReducer";
import formOptions from "./formOptions";
import { getAllEvents } from "../../store/actions/eventsActions";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import { getEvent } from "../../store/actions/editEventActions";
import imageCompression from "browser-image-compression";
import rooms from "./rooms";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";

type EditEventForm = {
  signUpCreator: boolean;
  title: string;
  description: string;
  eventDate?: Dayjs | null;
  startDateTime?: Dayjs | null;
  endDateTime?: Dayjs | null;
  dressCode: string;
  admissionCost: number;
  eventImageFile: File | null;
  format: string;
  businessAreaTags: string[];
  groupTags: string[];
  teamTags: string[];
  location: string;
  transportationParkingDetails: string;
  primaryContacts: string[];
  includeTeamsLink: string;
  teamsLink?: string;
  feedbackLink: string;
  includeSignUpSurvey: string;
  signUpSurvey: { type: string; question: string }[];
  room: Room | null;
};

const EditEventPage = () => {
  const { id } = useParams();

  useEffect(() => {
    if (id && id !== "") {
      dispatch(getEvent(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const event: Event = useSelector((state: any) => state.edit.data);
  const eventLoading: Event[] = useSelector((state: any) => state.edit.loading);
  const eventError: Event[] = useSelector((state: any) => state.edit.error);

  const currentTime = dayjs();
  const roundToNearest30Minutes = (dateTime: Dayjs) => {
    const minutes = dateTime.minute();
    const roundedMinutes = Math.round(minutes / 30) * 30;
    return dateTime.startOf("hour").add(roundedMinutes, "minute");
  };

  const [initialValues, setInitialValues] = useState({});

  const [placeholderFileName, setPlaceholderFileName] = useState("");

  const formMethods = useForm({
    defaultValues: {
      title: "",
      description: "",
      eventDate: dayjs(),
      startDateTime: roundToNearest30Minutes(currentTime),
      endDateTime: roundToNearest30Minutes(currentTime).add(30, "minute"),
      dressCode: "",
      admissionCost: 0,
      format: "",
      eventImageFile: null,
      businessAreaTags: [],
      groupTags: [],
      teamTags: [],
      signUpCreator: true,
      location: "399 Boylston St #1000, Boston, MA",
      transportationParkingDetails: "",
      primaryContacts: [],
      includeTeamsLink: "no",
      teamsLink: "",
      feedbackLink: "",
      includeSignUpSurvey: "yes",
      signUpSurvey: [],
      room: null,
    },
    mode: "onChange",
  });

  const userInfo = useSelector((state: any) => {
    const userState = state.user as UserState;
    return userState;
  });

  useEffect(() => {
    if (event) {
      if (
        !loading &&
        (dayjs.unix(event.endDateTime).isBefore(dayjs()) ||
          (userInfo.name !== event.eventOrganizerName &&
            userInfo.email !== event.eventOrganizerEmail) ||
          event.status !== "ACTIVE") &&
        id === event.eventId
      ) {
        navigate("/events", {
          state: {
            alert: {
              severity: "error",
              message:
                "The event you tried to access cannot be edited because it is either already complete, a draft event, was cancelled, or you do not own it.",
            },
          },
        });
      }

      const setFormValue = (name: keyof EditEventForm, value: any) => {
        formMethods.setValue(name, value);
        setInitialValues((prevValues) => ({ ...prevValues, [name]: value }));
      };

      setFormValue("title", event.title);
      setFormValue("description", event.description);
      setFormValue("eventDate", dayjs.unix(event.startDateTime));
      setFormValue("startDateTime", dayjs.unix(event.startDateTime));
      setFormValue("endDateTime", dayjs.unix(event.endDateTime));
      setFormValue("dressCode", event.dressCode);
      setFormValue("admissionCost", event.admissionCost);
      setFormValue("format", event.format);
      setFormValue("location", event.location);
      setFormValue("includeTeamsLink", event.teamsLink !== "" ? "yes" : "no");
      setFormValue("teamsLink", event.teamsLink);
      setFormValue("eventImageFile", null);
      setFormValue("businessAreaTags", event.businessAreaTags as never[]);
      setFormValue("groupTags", event.groupTags as never[]);
      setFormValue("teamTags", event.teamTags as never[]);
      setFormValue(
        "transportationParkingDetails",
        event.transportationParkingDetails
      );
      setFormValue("feedbackLink", event.feedbackLink);
      setFormValue("primaryContacts", event.primaryContacts as never[]);
      setFormValue(
        "includeSignUpSurvey",
        event.signUpSurvey.length > 0 ? "yes" : "no"
      );
      setFormValue("signUpSurvey", event.signUpSurvey);
      setFormValue(
        "room",
        rooms.find(
          (room) =>
            room.name === event.roomName && room.email === event.roomEmail
        )
      );
      setPlaceholderFileName(
        event.eventHeroS3Url ? `${event.eventId}-hero-image` : ""
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event]);

  const [loading, setLoading] = useState(false);
  const [createEventError, setCreateEventError] = useState(false);
  const [changesCount, setChangesCount] = useState(0);

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { errors } = formMethods.formState;
  const hasErrors = Object.keys(errors).length > 0;

  const { loading: roomsLoading } = useSelector(
    (state: RootState) => state.room
  );

  const includeSignUpSurvey = formMethods.watch("includeSignUpSurvey");
  const format = formMethods.watch("format");

  const [showRoomFinderModal, setShowRoomFinderModal] = useState(false);
  const selectedRoom = formMethods.watch("room");
  const [roomFinderError, setRoomFinderError] = useState(false);
  const [roomFinderErrorMessage, setRoomFinderErrorMessage] = useState("");

  const getNewValue = (oldValue: any, newValue: any) => {
    if (_.isArray(oldValue)) {
      return !_.isEqual(oldValue, newValue) ? newValue : undefined;
    } else {
      return oldValue !== newValue ? newValue : undefined;
    }
  };

  useEffect(() => {
    const updateChangesCount = () => {
      const data = formMethods.getValues();
      let newChangesCount = Object.keys(initialValues).reduce(
        (count: number, key: string) => {
          const keyAsString = key as keyof typeof initialValues;

          if (keyAsString === "description") {
            const initialDescription = initialValues[keyAsString] as any;
            const currentDescription = data[keyAsString] as any;

            let currentDescriptionString = "";

            if (currentDescription instanceof EditorState) {
              currentDescriptionString = DOMPurify.sanitize(
                `<div style="margin: 0;">${draftToHtml(
                  convertToRaw(currentDescription.getCurrentContent())
                )}</div>`
              );
            }

            if (
              !_.isEqual(initialDescription, currentDescriptionString) &&
              currentDescription instanceof EditorState
            ) {
              return count + 1;
            } else {
              return count;
            }
          }

          if (keyAsString === "room") {
            const initialRoom = initialValues[keyAsString] as Room | null;
            const currentRoom = data.room as Room | null;

            if (!_.isEqual(initialRoom, currentRoom)) {
              return count + 1;
            }
          } else if (
            keyAsString !== "eventImageFile" &&
            !_.isEqual(data[keyAsString], initialValues[keyAsString])
          ) {
            return count + 1;
          }

          return count;
        },
        placeholderFileName === "FILE_CHANGED" ? 1 : 0
      );

      if (newChangesCount !== changesCount) {
        setChangesCount(newChangesCount);
      }
    };

    const subscription = formMethods.watch(() => updateChangesCount());
    return () => subscription.unsubscribe();
  }, [formMethods, initialValues, placeholderFileName, changesCount]);

  const onSubmit: SubmitHandler<any> = async (data) => {
    setLoading(true);

    const startDateTime = combineDayJSToUnix(
      data.eventDate as Dayjs,
      data.startDateTime as Dayjs
    );

    const endDateTime = combineDayJSToUnix(
      data.eventDate as Dayjs,
      data.endDateTime as Dayjs
    );

    let newEventHero: string | undefined = undefined;

    if (data.eventImageFile && placeholderFileName === "FILE_CHANGED") {
      const options = {
        maxSizeMB: 3.0,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };
      try {
        const compressedFile = await imageCompression(
          data.eventImageFile as File,
          options
        );
        newEventHero = await imageCompression.getDataUrlFromFile(
          compressedFile
        );
      } catch (error) {
        console.error("Image compression failed:", error);
      }
    }

    const format = getNewValue(event.format, data.format);

    const editEventData: any = {
      title: getNewValue(event.title, data.title),
      description: getNewValue(
        event.description,
        DOMPurify.sanitize(
          `<div style="margin: 0;">${draftToHtml(
            convertToRaw(data.description.getCurrentContent())
          )}</div>`
        )
      ),
      startDateTime: getNewValue(event.startDateTime, startDateTime),
      endDateTime: getNewValue(event.endDateTime, endDateTime),
      dressCode: getNewValue(event.dressCode, data.dressCode),
      admissionCost: getNewValue(event.admissionCost, data.admissionCost),
      format: getNewValue(event.format, data.format),
      businessAreaTags: getNewValue(
        event.businessAreaTags,
        data.businessAreaTags
      ),
      groupTags: getNewValue(event.groupTags, data.groupTags),
      teamTags: getNewValue(event.teamTags, data.teamTags),
      transportationParkingDetails: getNewValue(
        event.transportationParkingDetails,
        data.transportationParkingDetails
      ),
      feedbackLink: getNewValue(event.feedbackLink, data.feedbackLink),
      primaryContacts: getNewValue(event.primaryContacts, data.primaryContacts),
      includeTeamsLink: getNewValue(
        event.teamsLink !== "" ? "yes" : "no",
        data.includeTeamsLink
      ),
      teamsLink: getNewValue(event.teamsLink, data.teamsLink),
      signUpSurvey: getNewValue(event.signUpSurvey, data.signUpSurvey),
      roomName:
        format === "Remote" ? "" : getNewValue(event.roomName, data.room?.name),
      roomEmail:
        format === "Remote"
          ? ""
          : getNewValue(event.roomEmail, data.room?.email),
    };

    if (data.eventImageFile && placeholderFileName === "FILE_CHANGED") {
      editEventData.eventHero = newEventHero;
      editEventData.eventOrganizerName = userInfo.name;
      editEventData.eventOrganizerTitle = userInfo.jobTitle;
      editEventData.eventOrganizerEmail = userInfo.email;
    } else if (placeholderFileName === "FILE_CHANGED" && !data.eventImageFile) {
      editEventData.eventHero = "";
    }

    const filteredEditEventData = Object.fromEntries(
      Object.entries(editEventData).filter(([_, v]) => v !== undefined)
    );

    try {
      await axiosAPI.put(EDIT_EVENT(event.eventId), filteredEditEventData);
      dispatch(editEvent());
      dispatch(getAllEvents());
      dispatch(getAllUserEvents(userInfo.email));
      setLoading(false);
      window.scroll(0, 0);
      navigate("/events");
    } catch (error: any) {
      const errorMessage = error.response?.data?.data;

      if (
        errorMessage.includes("Room is not available for the specified time")
      ) {
        setRoomFinderError(true);
        setRoomFinderErrorMessage(
          "The room that you have selected is no longer available. Please try again."
        );
      } else {
        setCreateEventError(true);
        console.error("Failed to update event:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  if (eventLoading) {
    return (
      <PageContainer>
        <Styled.PageLoaderContainer>
          <CircularProgress />
        </Styled.PageLoaderContainer>
      </PageContainer>
    );
  }

  if (eventError && !eventLoading) {
    return <APIError />;
  }

  return (
    <PageContainer>
      {event && (
        <RoomFinderModal
          name={"room"}
          control={formMethods.control}
          setValue={formMethods.setValue}
          open={showRoomFinderModal}
          onClose={() => {
            setShowRoomFinderModal(false);
          }}
          createdEvent={event}
          roomFinderError={roomFinderError}
          roomFinderErrorMessage={roomFinderErrorMessage}
          setRoomFinderError={setRoomFinderError}
          setRoomFinderErrorMessage={setRoomFinderErrorMessage}
        />
      )}
      {createEventError ? (
        <APIError
          onClick={() => {
            setCreateEventError(false);
          }}
        />
      ) : (
        <Styled.PaddedContainer>
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <Styled.HeaderStack
                direction={{ mobile: "column", desktop: "row" }}
                width="100%"
              >
                <Styled.HeaderText variant="h1" gutterBottom>
                  Edit Event
                </Styled.HeaderText>
              </Styled.HeaderStack>
              <Styled.FormSectionContainer>
                <Typography variant="h3"> Details </Typography>
                <Input
                  name={"title"}
                  control={formMethods.control}
                  label={"Event Title"}
                  required
                  placeholder="Enter title..."
                  fullWidth
                />
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.colors.primary.slalomDarkBlue,
                    marginTop: theme.spacing(2),
                  }}
                >
                  Event Description
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.colors.neutral.darkGray,
                  }}
                >
                  The event description will be added to the Outlook email
                  invite. Use the space below to format your text, or paste text
                  to this area from Word or Outlook. Currently you cannot add
                  images, but that will be coming soon!
                </Typography>
                <RichTextEditor
                  name={"description"}
                  control={formMethods.control}
                  setValue={formMethods.setValue}
                  required
                  label="description"
                  defaultValue={event?.description || ""}
                />
                <Styled.InputStack
                  direction={{ mobile: "column", desktop: "row" }}
                  spacing={theme.spacing(3)}
                  width="100%"
                >
                  <DatePickerInput
                    name={"eventDate"}
                    control={formMethods.control}
                    label="Event Date"
                    required
                  />

                  <TimeRangePicker
                    startName={"startDateTime"}
                    endName={"endDateTime"}
                    required
                    control={formMethods.control}
                  />
                </Styled.InputStack>
                <Styled.InputStack
                  direction={{ mobile: "column", desktop: "row" }}
                  spacing={theme.spacing(3)}
                  width="100%"
                >
                  <Input
                    name={"dressCode"}
                    control={formMethods.control}
                    label={"Dress Code"}
                    placeholder="Enter title..."
                    select
                    selectOptions={formOptions.dressCode}
                    sx={{
                      width: "100%",
                      [theme.breakpoints.up("desktop")]: {
                        width: "33%",
                      },
                    }}
                  />
                  <Input
                    name={"admissionCost"}
                    control={formMethods.control}
                    label={"Cost"}
                    placeholder="0.00"
                    inputProps={{ type: "number" }}
                    startAdornment={
                      <InputAdornment position="start">
                        <Typography
                          variant="body1"
                          style={{ color: "#000000" }}
                        >
                          $
                        </Typography>
                      </InputAdornment>
                    }
                    sx={{
                      width: "100%",
                      [theme.breakpoints.up("desktop")]: {
                        width: "33%",
                      },
                    }}
                  />
                  <FileInput
                    name={"eventImageFile"}
                    control={formMethods.control}
                    label={"Event Image"}
                    placeholder="900x400 png/jpg"
                    placeholderFileName={placeholderFileName}
                    setPlaceholderFileName={setPlaceholderFileName}
                    sx={{
                      width: "100%",
                      [theme.breakpoints.up("desktop")]: {
                        width: "33%",
                      },
                    }}
                  />
                </Styled.InputStack>
                <Styled.InputStack
                  direction={{ mobile: "column", desktop: "row" }}
                  spacing={theme.spacing(3)}
                  width="100%"
                >
                  <Input
                    name={"businessAreaTags"}
                    control={formMethods.control}
                    label={"Business Area(s)"}
                    required
                    select
                    multiple
                    selectOptions={formOptions.businessAreas}
                    sx={{
                      width: "100%",
                      [theme.breakpoints.up("desktop")]: {
                        width: "33%",
                      },
                    }}
                  />
                  <Input
                    name={"teamTags"}
                    control={formMethods.control}
                    label={"Team(s)"}
                    required
                    select
                    multiple
                    selectOptions={formOptions.teams}
                    sx={{
                      width: "100%",
                      [theme.breakpoints.up("desktop")]: {
                        width: "33%",
                      },
                    }}
                  />
                  <Input
                    name={"groupTags"}
                    control={formMethods.control}
                    label={"Group(s)"}
                    required
                    select
                    multiple
                    selectOptions={formOptions.groups}
                    sx={{
                      width: "100%",
                      [theme.breakpoints.up("desktop")]: {
                        width: "33%",
                      },
                    }}
                  />
                </Styled.InputStack>
                <Input
                  name={"format"}
                  control={formMethods.control}
                  label={"Event Format"}
                  required
                  select
                  selectOptions={formOptions.eventFormats}
                  fullWidth
                />
                {format !== "Remote" && format !== "" && (
                  <>
                    {roomFinderError && (
                      <Typography
                        variant="body2"
                        sx={{
                          marginTop: theme.spacing(2),
                          color: theme.colors.action.failure,
                        }}
                      >
                        {roomFinderErrorMessage}
                      </Typography>
                    )}
                    {selectedRoom && (
                      <Box
                        sx={{
                          marginTop: theme.spacing(2),
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: theme.spacing(2),
                        }}
                      >
                        {roomsLoading && <CircularProgress size={24} />}
                        <MeetingRoom
                          room={selectedRoom}
                          actionText={"Change Room"}
                          onClick={() => {
                            setShowRoomFinderModal(true);
                          }}
                          watchedRoom={selectedRoom}
                          border={`1px solid ${theme.colors.neutral.softGray}`}
                          bypassShowAction
                        />
                      </Box>
                    )}
                    {!selectedRoom && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: theme.spacing(2),
                          marginTop: theme.spacing(2),
                        }}
                      >
                        {roomsLoading && <CircularProgress size={24} />}
                        <Button
                          onClick={() => {
                            setShowRoomFinderModal(true);
                          }}
                          variant="outlined"
                        >
                          Show available rooms
                        </Button>
                      </Box>
                    )}
                  </>
                )}
              </Styled.FormSectionContainer>
              <Styled.FormSectionContainer>
                <Typography variant="h3"> Event Feedback </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: theme.colors.neutral.darkGray,
                    marginTop: theme.spacing(1),
                  }}
                >
                  {
                    "Please generate and paste a link to a survey or feedback form (ex. SparkThink) for attendees to fill out during/after the event."
                  }
                </Typography>
                <Input
                  name={"feedbackLink"}
                  control={formMethods.control}
                  label={"Feedback Link"}
                  required
                  placeholder="Enter feedback link..."
                  fullWidth
                  forLink
                />
              </Styled.FormSectionContainer>
              <Styled.FormSectionContainer>
                <Typography variant="h3"> Location </Typography>
                <Input
                  name={"location"}
                  control={formMethods.control}
                  label={"Location"}
                  required
                  placeholder="Enter details..."
                  fullWidth
                />

                <Input
                  name={"transportationParkingDetails"}
                  control={formMethods.control}
                  label={"Transportation & parking details"}
                  placeholder="Enter details..."
                  fullWidth
                  multiline
                />
              </Styled.FormSectionContainer>
              <Styled.FormSectionContainer>
                <Typography variant="h3"> {"Primary Contact(s)"} </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: theme.colors.neutral.darkGray,
                    marginTop: theme.spacing(1),
                  }}
                >
                  {
                    "Include the names of the employees responsible for coordinating this event by typing their name and hitting ENTER."
                  }
                </Typography>
                <ChipListInput
                  name={"primaryContacts"}
                  control={formMethods.control}
                  label={"Primary Contact(s)"}
                  required
                  placeholder="Start typing a name..."
                  fullWidth
                />
              </Styled.FormSectionContainer>
              <Styled.FormSectionContainer>
                <Typography variant="h3"> Signup Survey </Typography>
                <Typography
                  variant="body2"
                  style={{
                    marginTop: theme.spacing(1),
                  }}
                >
                  {
                    "Do you want to ask any specific questions to attendees upon signup for this event?"
                  }
                </Typography>
                <Typography
                  variant="subtitle1"
                  style={{
                    color: theme.colors.neutral.darkGray,
                    marginTop: theme.spacing(1),
                    marginBottom: theme.spacing(1),
                  }}
                >
                  {"For example “Do you have any dietary restrictions?”"}
                </Typography>
                <RadioGroup
                  control={formMethods.control}
                  name={"includeSignUpSurvey"}
                  label=""
                  row
                  options={[
                    { label: "Yes", value: "yes" },
                    { label: "No", value: "no" },
                  ]}
                />
                {includeSignUpSurvey === "yes" && (
                  <FormBuilder
                    control={formMethods.control}
                    formName="signUpSurvey"
                  />
                )}
              </Styled.FormSectionContainer>
              {loading ? (
                <Styled.LoaderContainer>
                  <CircularProgress />
                </Styled.LoaderContainer>
              ) : (
                <Box>
                  <Box
                    display="flex"
                    justifyContent={{ mobile: "center", desktop: "end" }}
                  >
                    {hasErrors && (
                      <Styled.ErrorMessage>
                        Please correct all errors before publishing the event
                      </Styled.ErrorMessage>
                    )}
                  </Box>
                  <Styled.FormActionStack
                    direction={{ mobile: "column", desktop: "row-reverse" }}
                    spacing={theme.spacing(4)}
                    width="100%"
                    alignItems="flex-end"
                  >
                    <Styled.ActionButton
                      variant="contained"
                      type="submit"
                      disabled={
                        changesCount === 0 ||
                        hasErrors ||
                        roomsLoading ||
                        roomFinderError
                      }
                    >
                      Submit Changes ({changesCount})
                    </Styled.ActionButton>
                  </Styled.FormActionStack>
                </Box>
              )}
            </form>
          </FormProvider>
        </Styled.PaddedContainer>
      )}
    </PageContainer>
  );
};

export default EditEventPage;
