import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";

export const AddIcon = styled(AddCircleOutlineIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.colors.primary.slalomBlue,
}));

export const PhotoCaption = styled(DialogTitle)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}));

export const PaddedContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

export const AddPhotoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  cursor: "pointer",
  "&:hover": {
    opacity: 0.7,
  },
}));
