import * as Styled from "./SiteMaintenance.styles";

import { Typography } from "@mui/material";
import { Wrench } from "../../assets";

const SiteMaintenance = () => {
  return (
    <Styled.SiteMaintenanceContainer>
      <Styled.WrenchGIF src={Wrench} alt={"wrench-gif"} />
      <Typography variant="h1" gutterBottom>
        {" "}
        Whoops!{" "}
      </Typography>
      <Typography gutterBottom>
        {" "}
        Something went wrong, but we are working on it! Please check back later.{" "}
      </Typography>
    </Styled.SiteMaintenanceContainer>
  );
};

export default SiteMaintenance;
