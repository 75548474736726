import { COGNITO_TOKEN_ENDPOINT } from "../../services/endpoints";
import { RootState } from "../store";
import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const authenticateUser = createAsyncThunk(
  "auth/login",
  async (code: string, { rejectWithValue }) => {
    try {
      const requestData = new URLSearchParams();
      requestData.append("grant_type", "authorization_code");
      requestData.append(
        "client_id",
        process.env.REACT_APP_COGNITO_CLIENT_ID || ""
      );
      requestData.append("code", code);
      requestData.append(
        "redirect_uri",
        process.env.REACT_APP_COGNITO_REDIRECT_URL || ""
      );

      const { data } = await axios.request({
        url: COGNITO_TOKEN_ENDPOINT(),
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: requestData,
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const refreshToken = createAsyncThunk(
  "auth/refresh",
  async (nil, { rejectWithValue, getState }) => {
    const state = getState() as RootState;
    try {
      const requestData = new URLSearchParams();
      requestData.append("grant_type", "refresh_token");
      requestData.append("refresh_token", state.auth.refreshToken);
      requestData.append(
        "client_id",
        process.env.REACT_APP_COGNITO_CLIENT_ID || ""
      );

      const { data } = await axios.request({
        url: COGNITO_TOKEN_ENDPOINT(),
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: requestData,
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
