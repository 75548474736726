import * as Styled from "./EventEditedModal.styles";

import { Box, Button, Typography } from "@mui/material";

import BaseModal from "../BaseModal/BaseModal";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

type EventEditedModalProps = {
  open: any;
  onClose: any;
  navigateToEvent?: any;
};

const EventEditedModal = ({ open, onClose }: EventEditedModalProps) => {
  const navigate = useNavigate();
  const editEventId = useSelector(
    (state: RootState) => state.edit.data?.eventId
  );

  return (
    <BaseModal open={open} onClose={onClose}>
      <Box>
        <Styled.ModalTitleContainer>
          <Typography variant="h2">Event has been edited!</Typography>
        </Styled.ModalTitleContainer>
        <Typography variant="modalBody">
          Your event has been succesfully saved and published. These changes are
          now viewable by all Slalom employees on the Explore Page.
        </Typography>
        <Styled.ConfirmationButton
          variant="contained"
          fullWidth
          size="large"
          onClick={() => {
            navigate(`/events?eventId=${editEventId}`);
            onClose();
          }}
        >
          View Event
        </Styled.ConfirmationButton>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
      </Box>
    </BaseModal>
  );
};

export default EventEditedModal;
