import { Box, Typography } from "@mui/material";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { styled } from "@mui/material/styles";

export const RemoveIcon = styled(RemoveCircleOutlineIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.colors.primary.slalomBlue,
}));

export const SelectedImage = styled("img")(({ theme }) => ({
  objectFit: "cover",
  objectPosition: "center",
  width: "100%",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "8px",
}));

export const CurrentTimeText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle2.fontSize,
  color: theme.colors.action.modalClose,
}));

export const CaptionText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
}));

export const UploadPhotoHeaderText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

export const UploadPhotoSubText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const ScrollableContent = styled(Box)(({ theme }) => ({
  overflow: "auto",
  paddingRight: "8px",
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  margin: `0 ${theme.spacing(-3)}`,
  padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(1)}`,
  boxShadow: `0px -6px 20px -6px ${theme.colors.action.hoverShadow}`,
}));
