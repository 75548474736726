import { Shadows, createTheme } from "@mui/material/styles";
import {
  SlalomSansBlack,
  SlalomSansBlackItalic,
  SlalomSansBold,
  SlalomSansBoldItalic,
  SlalomSansItalic,
  SlalomSansLight,
  SlalomSansLightItalic,
  SlalomSansRegular,
  SlalomSansThin,
  SlalomSansThinItalic,
} from "../assets";

const theme = createTheme({
  typography: {
    fontFamily: "Slalom Sans",
    button: {
      fontFamily: "Slalom Sans Bold",
      textTransform: "none",
    },
    h1: {
      fontFamily: "Slalom Sans Bold",
      fontSize: "32px",
      lineHeight: "38px",
    },
    h2: {
      fontFamily: "Slalom Sans Bold",
      fontSize: "24px",
      lineHeight: "32px",
    },
    h3: {
      fontFamily: "Slalom Sans Bold",
      fontSize: "18px",
      lineHeight: "24px",
    },
    body1: {
      fontSize: "18px",
      lineHeight: "24px",
    },
    body2: {
      fontSize: "16px",
      lineHeight: "22px",
    },
    subtitle1: {
      fontSize: "14px",
      lineHeight: "14px",
    },
    subtitle2: {
      fontSize: "12px",
      lineHeight: "17px",
    },
    modalBody: {
      fontSize: "14px",
      lineHeight: "20px",
      display: "block",
    },
    modalSubtitle: {
      fontFamily: "Slalom Sans Bold",
      fontSize: "16px",
      lineHeight: "16px",
      display: "block",
    },
    modalSubtitle2: {
      fontSize: "10px",
      lineHeight: "10px",
      display: "block",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Slalom Sans Bold';
        src: url(${SlalomSansBold}) format('woff2'),
        font-weight: bold;
        font-style: normal;
        font-display: swap;
      }
    
      @font-face {
          font-family: 'Slalom Sans BoldItalic';
          src: url(${SlalomSansBoldItalic}) format('woff2'),
          font-weight: bold;
          font-style: italic;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Slalom Sans LightItalic';
          src: url(${SlalomSansLightItalic}) format('woff2'),
          font-weight: 300;
          font-style: italic;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Slalom Sans BlackItalic';
          src: url(${SlalomSansBlackItalic}) format('woff2'),
          font-weight: 900;
          font-style: italic;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Slalom Sans Italic';
          src: url(${SlalomSansItalic}) format('woff2'),
          font-weight: normal;
          font-style: italic;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Slalom Sans Black';
          src: url(${SlalomSansBlack}) format('woff2'),
          font-weight: 900;
          font-style: normal;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Slalom Sans Light';
          src: url(${SlalomSansLight}) format('woff2'),
          font-weight: 300;
          font-style: normal;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Slalom Sans';
          src: url(${SlalomSansRegular}) format('woff2'),
          font-weight: normal;
          font-style: normal;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Slalom Sans Thin';
          src: url(${SlalomSansThin}) format('woff2'),
          font-weight: 100;
          font-style: normal;
          font-display: swap;
      }
      
      @font-face {
          font-family: 'Slalom Sans Thin Italic';
          src: url(${SlalomSansThinItalic}) format('woff2'),
              url('SlalomSans-ThinItalic.woff') format('woff');
          font-weight: 100;
          font-style: italic;
          font-display: swap;
      }    
      `,
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          height: "48px",
          padding: "16px 24px",
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          height: "48px",
          padding: "0 24px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            color: "#002FAF",
          },
          "& label.Mui-focused": {
            color: "#002FAF",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "#E5E6EB",
            },
            "&:hover fieldset": {
              borderColor: "#D9D9D9",
            },
            "&.Mui-focused fieldset": {
              borderColor: "#002FAF",
            },
          },
          "& .MuiSvgIcon-root": {
            color: "#0C62FB",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#000000",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#002FAF",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          color: "#000000",
          "&&:hover": {
            backgroundColor: "#E5E6EB",
          },
        },
        colorPrimary: {
          backgroundColor: "#EFF0F4B2",
        },
      },
    },
  },

  breakpoints: {
    values: {
      mobile: 0,
      tablet: 720,
      desktop: 1000,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#0C62FB",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#002FAF",
      contrastText: "#FFFFFF",
    },
    success: {
      main: "#0E8A22",
    },
    error: {
      main: "#BC0706",
    },
  },
  colors: {
    primary: {
      slalomBlue: "#0C62FB",
      slalomDarkBlue: "#002FAF",
    },
    neutral: {
      black: "#000000",
      darkGray: "#666666",
      gray: "#D9D9D9",
      lightGray: "#EFF0F4B2",
      softGray: "#E5E6EB",
      white: "#FFFFFF",
    },
    secondary: {
      chartreuse: "#DEFF4D",
      coralRed: "#FF4D5F",
      cyan: "#1CE1F2",
      darkPurple: "#725BCF",
      purple: "#C7B9FF",
    },
    action: {
      success: "#0E8A22",
      failure: "#BC0706",
      hoverShadow: "#0C62FB14",
      hoverShadowPurple: "#C7B9FF57",
      backdrop: "#04206C3D",
      modalClose: "#C0C1C3",
    },
  },
  shadows: Array(25).fill("none") as Shadows,
});

export default theme;
