import * as Styled from "./CancelEventModal.styles";

import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";

import BaseModal from "../BaseModal/BaseModal";
import { CANCEL_EVENT } from "../../services/endpoints";
import { RootState } from "../../store/store";
import axiosAPI from "../../services/axios";
import { getAllEvents } from "../../store/actions/eventsActions";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";
import { useState } from "react";

type CancelEventModalProps = {
  open: any;
  onClose: any;
  navigateToEvent?: any;
  eventToCancelId: any;
};

const CancelEventModal = ({
  open,
  onClose,
  eventToCancelId,
}: CancelEventModalProps) => {
  const dispatch = useAppDispatch();
  const userEmail = useSelector((state: RootState) => state.user.email);

  const [cancelLoading, setCancelLoading] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [cancelFailure, setCancelFailure] = useState(false);

  const cancelEvent = async () => {
    setCancelLoading(true);
    setCancelSuccess(false);
    setCancelFailure(false);

    try {
      await axiosAPI.post(CANCEL_EVENT(eventToCancelId));
      setCancelSuccess(true);
      dispatch(getAllEvents());
      dispatch(getAllUserEvents(userEmail));
    } catch (error) {
      setCancelFailure(true);
    } finally {
      setCancelLoading(false);
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={() => {
        if (!cancelLoading) {
          setCancelLoading(false);
          setCancelSuccess(false);
          setCancelFailure(false);
          onClose();
        }
      }}
      showCloseButton={false}
    >
      <Box>
        {cancelFailure && (
          <Alert
            severity="error"
            onClose={() => {
              setCancelFailure(false);
            }}
          >
            An error has occured while attempting to cancel this event. Please
            try again.
          </Alert>
        )}
        <Styled.ModalTitleContainer>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {cancelSuccess && <Styled.CheckmarkIcon />}
            {cancelSuccess ? (
              <Typography variant="h2">Event cancelled</Typography>
            ) : (
              <Typography variant="h2">
                Are you sure you wish to cancel this event?
              </Typography>
            )}
          </Box>
        </Styled.ModalTitleContainer>
        {cancelSuccess ? (
          <Typography variant="modalBody" sx={{ marginBottom: "16px" }}>
            Your event has been successfully cancelled. You will receive an
            email confirmation shortly.
          </Typography>
        ) : (
          <Typography variant="modalBody">
            By continuing, this event will be cancelled, along with any
            associated Outlook calendar invites. This action cannot be undone.
          </Typography>
        )}
        {cancelLoading ? (
          <Box
            sx={{
              padding: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            {!cancelSuccess && (
              <Styled.ConfirmationButton
                variant="contained"
                fullWidth
                size="large"
                onClick={() => {
                  cancelEvent();
                }}
              >
                Yes, I'm sure
              </Styled.ConfirmationButton>
            )}
            <Button
              variant="outlined"
              fullWidth
              size="large"
              onClick={() => {
                setCancelLoading(false);
                setCancelSuccess(false);
                setCancelFailure(false);
                onClose();
              }}
            >
              {cancelSuccess ? "Close" : "No, take me back"}
            </Button>
          </Box>
        )}
      </Box>
    </BaseModal>
  );
};

export default CancelEventModal;
