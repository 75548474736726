import * as Styled from "./ManageEventsPage.styles";

import {
  APIError,
  PageContainer,
  RefreshEventsIconButton,
  ScrollToTopFAB,
  ToggleButton,
} from "../../components";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CancelEventModal,
  DraftEventsModal,
  EventDetailsModal,
  EventEditedModal,
  EventSignUpsModal,
  NewEventCreatedModal,
  ShareEventModal,
} from "../../modals";
import {
  getAmOrPm,
  getHourFromMilliseconds,
  getMinutesFromMilliseconds,
} from "../../utils/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import { CLEAR_NEW_EVENT_CREATED } from "../../store/actions/newEventActions";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Event } from "../../types/event";
import { RootState } from "../../store/store";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import VideocamIcon from "@mui/icons-material/Videocam";
import { clearEvent } from "../../store/reducers/EditEventReducer/EditEventReducer";
import { isCuid } from "cuid";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";

const ManageEventsPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  const eventIdFromQueryParam = queryParams.get("eventId");

  const userRole = useSelector((state: RootState) => state.user.role);

  const newEventState = useSelector((state: RootState) => state.newEvent);
  const editEventState = useSelector((state: RootState) => state.edit);

  const [eventDetailsId, setEventDetailsId] = useState("");

  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [showNewEventCreatedModal, setShowNewEventCreatedModal] =
    useState(false);
  const [showEventEditedModal, setShowEventEditedModal] = useState(false);
  const [showDraftEventsModal, setShowDraftEventsModal] = useState(false);
  const [showQrCodeDetailsModal, setShowQrCodeDetailsModal] = useState(false);
  const [showEventSignUpsModal, setShowEventSignupsModal] = useState(false);
  const [showCancelEventModal, setShowCancelEventModal] = useState(false);

  const [savingToClipboard, setSavingToClipboard] = useState(false);
  const [savingToClipboardSuccess, setSavingToClipboardSuccess] =
    useState(false);
  const [savingToClipboardError, setSavingToClipboardError] = useState(false);

  const generateEventLink = (eventId: string) => {
    const url = new URL(window.location.href);
    const baseLink = `${url.protocol}//${url.host}`;
    return `${baseLink}/events?eventId=${eventId}`;
  };

  const saveEventLinkToClipboard = (eventId: string) => {
    setSavingToClipboard(true);
    const url = new URL(window.location.href);
    const baseLink = `${url.protocol}//${url.host}`;
    const eventLink = `${baseLink}/events?eventId=${eventId}`;

    navigator.clipboard
      .writeText(eventLink)
      .then(() => {
        setSavingToClipboard(false);
        setSavingToClipboardSuccess(true);
        setSavingToClipboardError(false);

        setTimeout(() => {
          setSavingToClipboardSuccess(false);
        }, 8000);
      })
      .catch((err) => {
        console.error("Failed to copy event link: ", err);
        setSavingToClipboard(false);
        setSavingToClipboardSuccess(false);
        setSavingToClipboardError(true);
      });
  };

  useEffect(() => {
    if (eventIdFromQueryParam && isCuid(eventIdFromQueryParam)) {
      setEventDetailsId(eventIdFromQueryParam);
      setShowEventDetailsModal(true);
    }
  }, [eventIdFromQueryParam]);

  useEffect(() => {
    if (!searchParams.has("eventId")) {
      setShowEventDetailsModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setShowNewEventCreatedModal(newEventState.eventCreated);
  }, [newEventState.eventCreated]);

  useEffect(() => {
    setShowEventEditedModal(editEventState.eventEdited);
  }, [editEventState.eventEdited]);

  const handleCloseEventDetailsModal = useCallback(() => {
    navigate("/manage", { replace: true });
    setShowEventDetailsModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseNewEventModal = useCallback(() => {
    dispatch({ type: CLEAR_NEW_EVENT_CREATED });
    setShowNewEventCreatedModal(false);
  }, [dispatch]);

  const handleCloseEventEditedModal = useCallback(() => {
    dispatch(clearEvent());
    setShowEventEditedModal(false);
  }, [dispatch]);

  const selectUserEventsState = useSelector(
    (state: RootState) => state.userEvents
  );

  const {
    loading: selectUserEventsDraftLoading,
    draft: selectUserEventsDraft,
    created: selectUserEventsCreated,
    loading: selectUserEventsLoading,
    error: selectUserEventsError,
    success: selectUserEventsSuccess,
  } = useMemo(() => selectUserEventsState, [selectUserEventsState]);

  const [option, setOption] = useState<number>(0);
  const displayOptions = [
    `Upcoming (${
      selectUserEventsCreated.filter(
        (event) =>
          new Date(event.endDateTime * 1000) > new Date() &&
          event.status === "ACTIVE"
      ).length
    })`,
    `Past (${
      selectUserEventsCreated.filter(
        (event) =>
          new Date(event.endDateTime * 1000) < new Date() &&
          event.status !== "CANCELLED"
      ).length
    })`,
    `Cancelled (${
      selectUserEventsCreated.filter((event) => event.status === "CANCELLED")
        .length
    })`,
  ];

  const tableHeaders = [
    { label: "Event ID", key: "eventId" },
    { label: "Event Name", key: "title" },
    { label: "Date", key: "date" },
    { label: "Time", key: "time" },
    { label: "# of sign ups", key: "signupCount" },
  ];

  const [sortDirection, setSortDirection] = useState<string>("asc");
  const [sortBy, setSortBy] = useState<string>("date");

  const handleSort = (criteria: string) => {
    const newDirection =
      sortBy === criteria && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortBy(criteria);
  };

  // Filtering events based on the option selected
  const filteredEvents = selectUserEventsCreated.filter((event) => {
    const eventEndDate = new Date(event.endDateTime * 1000);
    const currentDate = new Date();

    switch (option) {
      case 0:
        // Future events that are active
        return eventEndDate > currentDate && event.status === "ACTIVE";
      case 1:
        // Past events that are not cancelled
        return eventEndDate < currentDate && event.status !== "CANCELLED";
      case 2:
        // Cancelled events
        return event.status === "CANCELLED";
      default:
        return true;
    }
  });

  const sortedEvents = [...filteredEvents].sort((a, b) => {
    if (sortBy === "date" || sortBy === "time") {
      const timeA = a.startDateTime || a.endDateTime;
      const timeB = b.startDateTime || b.endDateTime;

      return sortDirection === "asc" ? timeA - timeB : timeB - timeA;
    } else {
      // Default sorting by title
      return sortDirection === "asc"
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    }
  });

  const formatEventDate = (eventCreatedAt: number) => {
    const eventDate = new Date(eventCreatedAt * 1000).getTime();
    return new Date(eventDate).toLocaleDateString();
  };

  const formatEventTime = (startDateTime: number, endDateTime: number) => {
    return (
      <Typography variant="body2">
        {`${getHourFromMilliseconds(startDateTime)}${
          getMinutesFromMilliseconds(startDateTime) !== "00"
            ? `:${getMinutesFromMilliseconds(startDateTime)}`
            : ""
        }`}
        <Styled.SuperScript>{getAmOrPm(startDateTime)}</Styled.SuperScript>
        {" - "}
        {`${getHourFromMilliseconds(endDateTime)}${
          getMinutesFromMilliseconds(endDateTime) !== "00"
            ? `:${getMinutesFromMilliseconds(endDateTime)}`
            : ""
        }`}
        <Styled.SuperScript>{getAmOrPm(endDateTime)}</Styled.SuperScript>
      </Typography>
    );
  };
  const isEventLive = (startDateTime: number, endDateTime: number): boolean => {
    const start = startDateTime * 1000;
    const end = endDateTime * 1000;
    const now = Date.now();

    return now >= start && now <= end;
  };

  const EventLink = ({
    event,
    setEventDetailsId,
    setShowEventDetailsModal,
  }: any) => {
    const location = useLocation();

    const handleClick = (eventId: any) => {
      navigate(`${location.pathname}?eventId=${eventId}`);
      setEventDetailsId(eventId);
      setShowEventDetailsModal(true);
    };

    return (
      <Typography
        variant="body2"
        onClick={() => handleClick(event.eventId)}
        sx={{
          overflow: "hidden",
          pointer: "cursor",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          color: theme.colors.primary.slalomBlue,
          textDecoration: "underline",
          transition: "color 0.3s ease",
          "&:hover": {
            color: theme.colors.primary.slalomDarkBlue,
          },
        }}
      >
        <Tooltip title="View Event Details">
          {event.title
            ? event.title.length > 32
              ? `${event.title.substring(0, 32)}...`
              : event.title
            : "[No Title]"}
        </Tooltip>
      </Typography>
    );
  };

  const signUpCount = ({ eventId, count }: any) => {
    if (count === 0) {
      return <Typography variant="body2">{count}</Typography>;
    }

    return (
      <Typography
        variant="body2"
        onClick={() => {
          setEventDetailsId(eventId);
          setShowEventSignupsModal(true);
        }}
        sx={{
          overflow: "hidden",
          pointer: "cursor",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "100%",
          color: theme.colors.primary.slalomBlue,
          textDecoration: "underline",
          transition: "color 0.3s ease",
          "&:hover": {
            color: theme.colors.primary.slalomDarkBlue,
          },
        }}
      >
        <Tooltip title="View Event Signups">{count}</Tooltip>
      </Typography>
    );
  };

  return (
    <PageContainer>
      <NewEventCreatedModal
        open={showNewEventCreatedModal}
        onClose={handleCloseNewEventModal}
      />
      <EventEditedModal
        open={showEventEditedModal}
        onClose={handleCloseEventEditedModal}
      />
      <EventDetailsModal
        eventId={eventDetailsId}
        open={showEventDetailsModal}
        onClose={handleCloseEventDetailsModal}
      />
      <DraftEventsModal
        open={showDraftEventsModal}
        onClose={() => {
          setShowDraftEventsModal(false);
        }}
      />
      <ShareEventModal
        link={generateEventLink(eventDetailsId)}
        open={showQrCodeDetailsModal}
        onClose={() => {
          setShowQrCodeDetailsModal(false);
        }}
      />
      <EventSignUpsModal
        open={showEventSignUpsModal}
        onClose={() => {
          setShowEventSignupsModal(false);
        }}
        eventId={eventDetailsId}
      />
      <CancelEventModal
        eventToCancelId={eventDetailsId}
        open={showCancelEventModal}
        onClose={() => {
          setShowCancelEventModal(false);
        }}
      />
      <ScrollToTopFAB />
      <Styled.PaddedContainer>
        <Styled.Header>
          <Styled.HeaderStack
            direction={{ mobile: "column", tablet: "row", desktop: "row" }}
            width="100%"
            spacing={3}
          >
            <Styled.HeaderText variant="h1" gutterBottom>
              Manage Events
            </Styled.HeaderText>
            <Styled.CreateEventActionContainer>
              {userRole === "ADMIN" && (
                <Button
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  variant="contained"
                  onClick={() => {
                    navigate("/create");
                  }}
                  sx={{
                    [theme.breakpoints.down("tablet")]: {
                      width: "100%",
                    },
                  }}
                >
                  Create an Event
                </Button>
              )}
            </Styled.CreateEventActionContainer>
          </Styled.HeaderStack>
          <Box mt={theme.spacing(2)} mb={theme.spacing(1)}>
            <ToggleButton
              option={option}
              setOption={setOption}
              onClick={setOption}
              options={displayOptions}
            />
          </Box>
        </Styled.Header>
        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          style={{ width: "100%" }}
        >
          <Typography
            variant="body2"
            style={{ color: theme.colors.neutral.darkGray, flexGrow: 1 }}
          >
            Select an event name to view the event details. Select the number of
            sign ups to view a list of who plans to attend, and their survey
            response (if applicable).
          </Typography>
          <RefreshEventsIconButton />
        </Stack>
        {selectUserEventsDraftLoading ? (
          <Styled.SubtitleActionTextLoading variant="modalSubtitle">
            {`Loading draft events...`}
          </Styled.SubtitleActionTextLoading>
        ) : (
          <Styled.SubtitleActionText
            variant="modalSubtitle"
            onClick={() => {
              setShowDraftEventsModal(true);
            }}
          >
            {`View draft events (${selectUserEventsDraft.length})`}
          </Styled.SubtitleActionText>
        )}
        {selectUserEventsError ? (
          <APIError />
        ) : selectUserEventsLoading && !selectUserEventsSuccess ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: `${theme.spacing(4)} ${theme.spacing(2)}`,
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              borderRadius: theme.spacing(1),
              marginTop: theme.spacing(1),
            }}
          >
            {sortedEvents.length === 0 ? (
              <Typography
                variant="modalSubtitle"
                sx={{ textAlign: "center", marginTop: theme.spacing(4) }}
              >
                No events to display.
              </Typography>
            ) : (
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    {tableHeaders.map((header) => (
                      <TableCell
                        key={header.key}
                        sx={{
                          position: "sticky",
                          top: 0,
                          zIndex: 1,
                          flex:
                            header.label === "Event Title"
                              ? "1 1 auto"
                              : "0 0 auto",
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                        }}
                        onClick={() => handleSort(header.key)}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Typography
                            variant="modalSubtitle"
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {header.label}
                          </Typography>
                          {sortBy === header.key &&
                            (sortDirection === "asc" ? (
                              <ArrowDropUpOutlinedIcon
                                sx={{
                                  marginLeft:
                                    header.label === "Event Title"
                                      ? "8px"
                                      : "4px",
                                }}
                              />
                            ) : (
                              <ArrowDropDownOutlinedIcon
                                sx={{
                                  marginLeft:
                                    header.label === "Event Title"
                                      ? "8px"
                                      : "4px",
                                }}
                              />
                            ))}
                        </Box>
                      </TableCell>
                    ))}

                    <TableCell sx={{ flex: "0 0 auto" }} />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedEvents.map((event: Event) => (
                    <TableRow key={event.eventId}>
                      <TableCell sx={{ width: "300px", whiteSpace: "nowrap" }}>
                        {event.eventId}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {isEventLive(
                            event.startDateTime,
                            event.endDateTime
                          ) &&
                            event.status === "ACTIVE" && (
                              <Tooltip title="Join Live Event!">
                                <VideocamIcon
                                  sx={{
                                    color: theme.colors.secondary.coralRed,
                                    cursor: "pointer",
                                    marginTop: "4px",
                                  }}
                                  onClick={() => {
                                    navigate(`/live/${event.eventId}`);
                                  }}
                                />
                              </Tooltip>
                            )}
                          <EventLink
                            event={event}
                            setEventDetailsId={setEventDetailsId}
                            setShowEventDetailsModal={setShowEventDetailsModal}
                          />
                        </Box>
                      </TableCell>

                      <TableCell
                        sx={{ flex: "0 0 auto", whiteSpace: "nowrap" }}
                      >
                        {formatEventDate(event.startDateTime)}
                      </TableCell>
                      <TableCell sx={{ whiteSpace: "nowrap" }}>
                        {formatEventTime(
                          event.startDateTime,
                          event.endDateTime
                        )}
                      </TableCell>
                      <TableCell
                        sx={{ flex: "0 0 auto", whiteSpace: "nowrap" }}
                      >
                        {signUpCount({
                          eventId: event.eventId,
                          count: event.signUpCount,
                        })}
                      </TableCell>
                      <TableCell sx={{ flex: "1 1 auto" }}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Tooltip title="Edit Event">
                            <IconButton
                              color="primary"
                              disabled={option !== 0}
                              sx={{ minWidth: "auto" }}
                              onClick={() => navigate(`/edit/${event.eventId}`)}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Cancel Event">
                            <IconButton
                              color="primary"
                              sx={{ minWidth: "auto" }}
                              disabled={option !== 0}
                              onClick={() => {
                                setEventDetailsId(event.eventId);
                                setShowCancelEventModal(true);
                              }}
                            >
                              <DoDisturbIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              savingToClipboardSuccess
                                ? "Event Link Copied!"
                                : savingToClipboardError
                                ? "Failed to Copy Link"
                                : "Copy Link To Clipboard"
                            }
                          >
                            <IconButton
                              color="primary"
                              sx={{ minWidth: "auto" }}
                              disabled={savingToClipboard}
                              onClick={() =>
                                saveEventLinkToClipboard(event.eventId)
                              }
                            >
                              <ContentCopyOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Share Event">
                            <IconButton
                              color="primary"
                              sx={{ minWidth: "auto" }}
                              onClick={() => {
                                setEventDetailsId(event.eventId);
                                setShowQrCodeDetailsModal(true);
                              }}
                            >
                              <ShareOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        )}
      </Styled.PaddedContainer>
    </PageContainer>
  );
};

export default ManageEventsPage;
