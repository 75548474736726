import * as Styled from "./CopyToClipboardButton.styles";

import { Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Typography } from "@mui/material";
import theme from "../../theme/theme";
import { useState } from "react";

type CopyToClipboardButtonProps = {
  link: string;
  text: string;
};

const CopyToClipboardButton = ({ link, text }: CopyToClipboardButtonProps) => {
  const [copyStatus, setCopyStatus] = useState<
    "not copied" | "copied" | "failed"
  >("not copied");

  const handleCopyClick = () => {
    setCopyStatus("not copied");
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopyStatus("copied");
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
        setCopyStatus("failed");
      });
  };

  return (
    <Box onClick={handleCopyClick}>
      {copyStatus === "not copied" && (
        <Styled.ButtonContainer>
          <ContentCopyIcon fontSize="inherit" color="primary" />
          <Typography
            variant="body2"
            style={{
              marginLeft: theme.spacing(1),
              marginRight: theme.spacing(1),
              color: theme.colors.primary.slalomBlue,
            }}
          >
            {text}
          </Typography>
        </Styled.ButtonContainer>
      )}
      {copyStatus === "copied" && (
        <Styled.ButtonContainer>
          <Typography variant="body2">{"Copied"}</Typography>
          <CheckCircleOutlineIcon
            fontSize="inherit"
            color="success"
            style={{
              marginLeft: theme.spacing(1),
              marginTop: "2px",
            }}
          />
        </Styled.ButtonContainer>
      )}
      {copyStatus === "failed" && (
        <Styled.ButtonContainer>
          <Typography variant="body2">{"Failed"}</Typography>
          <ErrorOutlineIcon
            fontSize="inherit"
            color="error"
            style={{
              marginLeft: theme.spacing(1),
              marginTop: "2px",
            }}
          />
        </Styled.ButtonContainer>
      )}
    </Box>
  );
};

export default CopyToClipboardButton;
