import * as Styled from "./PageContainer.styles";

import { forwardRef } from "react";

const PageContainer = forwardRef((props: any, ref) => {
  const { children, ...restOfProps } = props;
  return (
    <Styled.PageContainer ref={ref} {...restOfProps}>
      {children}
    </Styled.PageContainer>
  );
});

export default PageContainer;
