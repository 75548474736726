import { SearchState } from "../../../types/redux";
import { createSlice } from "@reduxjs/toolkit";
import { searchEvents } from "../../actions/searchActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  data: [],
  term: "",
  filter: "",
} as SearchState;

const searchSlice = createSlice({
  name: "events/search",
  initialState,
  reducers: {
    clearResults: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(searchEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      if (action.payload.data !== undefined) {
        state.data = action.payload.data.result; // Change this line
      }
      if (action.payload.term !== undefined) {
        state.term = action.payload.term;
      }
      if (action.payload.filter !== undefined) {
        state.filter = action.payload.filter;
      }
    });
    builder.addCase(searchEvents.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.data = [];
      state.error = action.payload;
      state.data = [];
    });
  },
});

export const { clearResults } = searchSlice.actions; // Export the new action

export default searchSlice.reducer;
