import { GET_EVENT } from "../../services/endpoints";
import axiosAPI from "../../services/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import cuid from "cuid";

export const getDraftEvent = createAsyncThunk(
  "draft",
  async (eventId: string, { rejectWithValue }) => {
    if (!cuid.isCuid(eventId)) {
      return rejectWithValue("Invalid eventId");
    }
    try {
      const { data } = await axiosAPI.request({
        url: GET_EVENT(eventId),
        method: "GET",
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
