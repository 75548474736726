import * as Styled from "./DraftEventsModal.styles";

import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@mui/icons-material/ArrowDropUpOutlined";
import BaseModal from "../BaseModal/BaseModal";
import { DELETE_EVENT } from "../../services/endpoints";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Event } from "../../types/event";
import { RootState } from "../../store/store";
import { UserState } from "../../types/redux";
import axiosAPI from "../../services/axios";
import { getAllEvents } from "../../store/actions/eventsActions";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

type DraftEventsModalProps = {
  open: any;
  onClose: any;
};

const DraftEventsModal = ({ open, onClose }: DraftEventsModalProps) => {
  const navigate = useNavigate();
  const selectUserEventsDraft = useSelector(
    (state: RootState) => state.userEvents.draft
  );
  const selectUserEventsLoading = useSelector(
    (state: RootState) => state.userEvents.loading
  );

  const dispatch = useAppDispatch();
  const userState: UserState = useSelector((state: any) => state.user);
  const userEmail = useSelector((state: RootState) => state.user.email);

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteFailure, setDeleteFailure] = useState(false);

  const deleteDraft = async (eventId: string) => {
    setDeleteLoading(true);
    setDeleteSuccess(false);
    setDeleteFailure(false);

    try {
      await axiosAPI.delete(DELETE_EVENT(eventId));
      setDeleteSuccess(true);
      dispatch(getAllEvents());
      dispatch(getAllUserEvents(userEmail));
    } catch (error) {
      setDeleteFailure(true);
    } finally {
      setDeleteLoading(false);
    }
  };

  const [sortDirection, setSortDirection] = useState<"asc" | "desc">("asc");
  const [sortBy, setSortBy] = useState<"date" | "title">("date");

  const formatEventDate = (eventCreatedAt: number) => {
    const now = new Date().getTime();
    const eventDate = new Date(eventCreatedAt * 1000).getTime();
    const diffInHours = Math.floor((now - eventDate) / (1000 * 60 * 60));

    if (diffInHours < 1) {
      return "Just now";
    } else if (diffInHours < 24) {
      return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
    } else {
      return new Date(eventDate).toLocaleDateString();
    }
  };

  const handleSort = (criteria: "date" | "title") => {
    const newDirection =
      sortBy === criteria && sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortBy(criteria);
  };

  useEffect(() => {
    if (
      !userState.loading &&
      userState.success &&
      userState.email !== "" &&
      open
    ) {
      dispatch(getAllUserEvents(userState.email));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const sortedEvents = [...selectUserEventsDraft].sort((a, b) => {
    if (sortBy === "date") {
      return sortDirection === "asc"
        ? a.eventCreatedAt - b.eventCreatedAt
        : b.eventCreatedAt - a.eventCreatedAt;
    } else {
      return sortDirection === "asc"
        ? a.title.localeCompare(b.title)
        : b.title.localeCompare(a.title);
    }
  });

  return (
    <BaseModal
      open={open}
      onClose={() => {
        setDeleteLoading(false);
        setDeleteSuccess(false);
        setDeleteFailure(false);
        onClose();
      }}
      tabletWidth="80%"
      desktopWidth="940px"
    >
      <Box>
        <Styled.ModalTitleContainer>
          <Typography variant="h2">Manage Your Draft Events</Typography>
        </Styled.ModalTitleContainer>
        <Typography variant="modalBody" sx={{ marginBottom: "8px" }}>
          From here, you can either delete a draft event or select the edit
          option to modify and save your draft, or publish it as a live event.
          Choose an action to proceed with your draft events.
        </Typography>
        {deleteSuccess && (
          <Alert
            severity="success"
            onClose={() => {
              setDeleteSuccess(false);
            }}
          >
            {" "}
            Draft has been successfully deleted
          </Alert>
        )}
        {deleteFailure && (
          <Alert
            severity="error"
            onClose={() => {
              setDeleteFailure(false);
            }}
          >
            We were unable to delete this draft. Please try again.
          </Alert>
        )}
        {selectUserEventsLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "32px 16px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              borderRadius: "4px",
            }}
          >
            {sortedEvents.length === 0 ? (
              <Typography
                variant="modalSubtitle"
                sx={{ textAlign: "center", margin: "16px" }}
              >
                No draft events were found.
              </Typography>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ flex: "0 0 auto", cursor: "pointer" }}
                      onClick={() => handleSort("date")}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="modalSubtitle">
                          Last Updated
                        </Typography>
                        {sortBy === "date" &&
                          (sortDirection === "asc" ? (
                            <ArrowDropUpOutlinedIcon
                              sx={{ marginLeft: "4px", marginTop: "2px" }}
                            />
                          ) : (
                            <ArrowDropDownOutlinedIcon
                              sx={{ marginLeft: "4px", marginTop: "2px" }}
                            />
                          ))}
                      </Box>
                    </TableCell>
                    <TableCell
                      sx={{ flex: "1 1 auto", cursor: "pointer" }}
                      onClick={() => handleSort("title")}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography variant="modalSubtitle">
                          Event Title
                        </Typography>
                        {sortBy === "title" &&
                          (sortDirection === "asc" ? (
                            <ArrowDropUpOutlinedIcon
                              sx={{ marginLeft: "8px" }}
                            />
                          ) : (
                            <ArrowDropDownOutlinedIcon
                              sx={{ marginLeft: "8px" }}
                            />
                          ))}
                      </Box>
                    </TableCell>
                    <TableCell sx={{ flex: "0 0 auto" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedEvents.map((event: Event) => (
                    <TableRow key={event.eventId}>
                      <TableCell sx={{ flex: "0 0 auto" }}>
                        {formatEventDate(event.eventCreatedAt)}
                      </TableCell>
                      <TableCell sx={{ flex: "1 1 auto" }}>
                        <Typography
                          variant="body2"
                          sx={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                          }}
                        >
                          {event.title
                            ? event.title.length > 32
                              ? `${event.title.substring(0, 32)}...`
                              : event.title
                            : "[No Title]"}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ flex: "0 0 auto" }}>
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          {deleteLoading ? (
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "4px",
                              }}
                            >
                              <CircularProgress size={18} />
                            </Box>
                          ) : (
                            <Tooltip title="Delete Draft">
                              <IconButton
                                sx={{ minWidth: "auto" }}
                                onClick={() => deleteDraft(event.eventId)}
                              >
                                <DeleteOutlineIcon color="primary" />
                              </IconButton>
                            </Tooltip>
                          )}
                          <Tooltip title="Edit/Publish Draft">
                            <IconButton
                              color="primary"
                              sx={{ minWidth: "auto", marginRight: "8px" }}
                              onClick={() =>
                                navigate(`/draft/${event.eventId}`)
                              }
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        )}
      </Box>
    </BaseModal>
  );
};

export default DraftEventsModal;
