import * as Styled from "./Contact.styles";

import { Box, IconButton, Typography } from "@mui/material";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type ContactProps = {
  name: string;
  variant?: any;
  size?: number | string;
  spacing?: number | string;
  image?: string;
  onClick?: () => void;
  isSelected?: boolean;
  selectedBackgroundColor?: string;
  selectedBorderColor?: string;
  selectedBoxShadowColor?: string;
  displayChevron?: boolean;
};

const Contact = ({
  name,
  variant = "body1",
  size = 36,
  spacing = "16px",
  image = "data:image/jpeg;base64",
  onClick,
  isSelected = false,
  selectedBackgroundColor,
  selectedBorderColor,
  selectedBoxShadowColor,
  displayChevron = false,
}: ContactProps) => {
  const isClickable = onClick !== undefined;
  return (
    <Styled.ContactContainer
      onClick={onClick}
      isSelected={isSelected}
      isClickable={isClickable}
      selectedBackgroundColor={selectedBackgroundColor}
      selectedBorderColor={selectedBorderColor}
      selectedBoxShadowColor={selectedBoxShadowColor}
      sx={{
        margin: `${spacing} 0`,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Styled.Avatar
          src={image}
          sx={{
            width: size,
            height: size,
            marginRight: 1,
          }}
        />
        <Typography variant={variant}>{name}</Typography>
      </Box>
      {displayChevron && (
        <IconButton
          sx={{
            marginLeft: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      )}
    </Styled.ContactContainer>
  );
};

export default Contact;
