import { Box, Typography as MUITypography } from "@mui/material";

import { Search } from "../../assets";
import { styled } from "@mui/material/styles";

export const NotFoundContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  margin: 16,
  textAlign: "center",
}));

export const SearchIcon = styled(Search)(() => ({
  width: "15vh",
  height: "auto",
  margin: "50px",
}));

export const Typography = styled(MUITypography)(() => ({
  marginBottom: "32px",
}));
