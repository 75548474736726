import {
  GET_ATTENDESS,
  GET_EVENT,
  GET_EVENT_IMAGES,
} from "../../services/endpoints";

import axiosAPI from "../../services/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import cuid from "cuid";

export const getLiveEvent = createAsyncThunk(
  "live-event",
  async (eventId: string, { rejectWithValue }) => {
    if (!cuid.isCuid(eventId)) {
      return rejectWithValue("Invalid eventId");
    }
    try {
      const { data } = await axiosAPI.request({
        url: GET_EVENT(eventId),
        method: "GET",
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const refreshLiveEvent = createAsyncThunk(
  "live-event/refresh",
  async (eventId: string, { rejectWithValue }) => {
    if (!cuid.isCuid(eventId)) {
      return rejectWithValue("Invalid eventId");
    }
    try {
      const { data } = await axiosAPI.request({
        url: GET_EVENT(eventId),
        method: "GET",
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getLiveEventAttendees = createAsyncThunk(
  "live-event/attendees",
  async (eventId: string, { rejectWithValue }) => {
    if (!cuid.isCuid(eventId)) {
      return rejectWithValue("Invalid eventId");
    }
    try {
      const { data } = await axiosAPI.request({
        url: GET_ATTENDESS(eventId),
        method: "GET",
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const refreshLiveEventAttendees = createAsyncThunk(
  "live-event/attendees/refresh",
  async (eventId: string, { rejectWithValue }) => {
    if (!cuid.isCuid(eventId)) {
      return rejectWithValue("Invalid eventId");
    }
    try {
      const { data } = await axiosAPI.request({
        url: GET_ATTENDESS(eventId),
        method: "GET",
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getLiveEventPhotos = createAsyncThunk(
  "live-event/photos",
  async (eventId: string, { rejectWithValue }) => {
    try {
      const { data } = await axiosAPI.request({
        url: GET_EVENT_IMAGES(eventId),
        method: "GET",
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const refreshLiveEventPhotos = createAsyncThunk(
  "live-event/photos/refresh",
  async (eventId: string, { rejectWithValue }) => {
    try {
      const { data } = await axiosAPI.request({
        url: GET_EVENT_IMAGES(eventId),
        method: "GET",
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
