import * as Styled from "./Footer.styles";

import { Link, Stack, Typography } from "@mui/material";

import { SlalomLogoPrimary } from "../../assets";
import pkg from "../../../package.json";

const Footer = () => {
  return (
    <Styled.FooterContainer>
      <Stack
        direction={{ mobile: "column", desktop: "row" }}
        spacing={2}
        width="100%"
      >
        <Styled.StackItem
          onClick={() => {
            window.open("https://www.slalom.com/us/en", "_blank");
          }}
          sx={{
            cursor: "pointer",
            transition: "opacity 0.3s",
            "&:hover": {
              opacity: 0.7,
            },
          }}
        >
          {<SlalomLogoPrimary />}
        </Styled.StackItem>
        <Styled.StackItem>
          <Link
            variant="body2"
            href="https://www.slalom.com/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
        </Styled.StackItem>
        <Styled.StackItem>
          <Link
            variant="body2"
            href="https://www.slalom.com/terms-use"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </Link>
        </Styled.StackItem>
        <Styled.StackItem>
          <Typography
            variant="body2"
            color="inherit"
          >{`Version ${pkg.version}`}</Typography>
        </Styled.StackItem>
        <Styled.StackItem>
          <Typography variant="body2">
            {" "}
            2024 Slalom, LLC. - All Rights Reserved.
          </Typography>
        </Styled.StackItem>
      </Stack>
    </Styled.FooterContainer>
  );
};

export default Footer;
