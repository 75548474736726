import { DecodedCognitoToken } from "../types/axios";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { refreshToken } from "../store/actions/authActions";
import { store } from "../store/store";
import { v4 as uuidv4 } from "uuid";

const axiosAPI = axios.create();

let isRefreshing = false;
let failedQueue: {
  resolve: (value: unknown) => void;
  reject: (reason?: any) => void;
}[] = [];

const processQueue = (error: any, token: any = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const executeRefresh = async () => {
  if (isRefreshing) {
    return new Promise(function (resolve, reject) {
      failedQueue.push({ resolve, reject });
    });
  }

  isRefreshing = true;

  try {
    const token = await store.dispatch(refreshToken());
    isRefreshing = false;
    processQueue(null, token);
  } catch (error_1) {
    isRefreshing = false;
    processQueue(error_1, null);
  }
};

axiosAPI.interceptors.request.use(
  async (config) => {
    const { accessToken } = store?.getState()?.auth;
    config.headers["Authorization"] = `Bearer ${accessToken}`;
    config.headers["Correlation-Object"] = JSON.stringify({
      correlationId: uuidv4(),
    });
    if (accessToken) {
      const decodedToken: DecodedCognitoToken = jwt_decode(accessToken);
      const currentTimestamp = new Date().getTime() / 1000;
      if (
        decodedToken?.exp !== undefined &&
        decodedToken.exp - currentTimestamp < 1200
      ) {
        await executeRefresh();
        config.headers["Authorization"] = `Bearer ${
          store?.getState()?.auth.accessToken
        }`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosAPI;
