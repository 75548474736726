import { Box, Skeleton, Typography } from "@mui/material";

import GroupIcon from "@mui/icons-material/Group";
import { styled } from "@mui/material/styles";

export const PaddedContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

export const AttendanceCountContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "20%",
}));

export const AttendanceIcon = styled(GroupIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.colors.secondary.purple,
}));

export const AttendanceText = styled(Typography)(({ theme }) => ({
  width: "80%",
}));

export const ContactAvatarSkeleton = styled(Skeleton)(({ theme }) => ({
  width: 36,
  height: 36,
  flexShrink: 0,
  marginRight: theme.spacing(1),
}));

export const ContactSkeletonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: `${theme.spacing(2)} 0`,
}));
