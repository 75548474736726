import { Box, Button, Stack, Typography, useTheme } from "@mui/material";

import BusinessIcon from "@mui/icons-material/Business";
import MeetingRoomOutlinedIcon from "@mui/icons-material/MeetingRoomOutlined";
import PeopleIcon from "@mui/icons-material/People";
import { Room } from "../../types/room";

type MeetingRoomProps = {
  room: Room;
  onClick: any;
  watchedRoom: any;
  border: string;
  actionText: string;
  bypassShowAction?: boolean;
};

const MeetingRoom = ({
  room,
  onClick,
  watchedRoom,
  border,
  actionText,
  bypassShowAction = false,
}: MeetingRoomProps) => {
  const theme = useTheme();
  return (
    <Box
      p={2}
      sx={{
        border: border,
        width: "100%",
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            sx={{
              width: 40,
              height: 40,
              backgroundColor: theme.colors.action.hoverShadow,
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MeetingRoomOutlinedIcon color="primary" />
          </Box>
          <Stack direction="column" spacing={0} alignItems="flex-start">
            <Typography variant="body2">{room?.name}</Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <PeopleIcon
                  sx={{
                    width: "12px",
                    height: "12px",
                    color: theme.colors.neutral.darkGray,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ color: theme.colors.neutral.darkGray }}
                >
                  {`${room?.capacity} people`}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <BusinessIcon
                  sx={{
                    width: "12px",
                    height: "12px",
                    color: theme.colors.neutral.darkGray,
                  }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ color: theme.colors.neutral.darkGray }}
                >
                  {`Floor ${room?.floor}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        {(!watchedRoom || bypassShowAction) && (
          <Button
            variant="outlined"
            onClick={onClick}
            sx={{
              padding: "8px 16px",
              minHeight: "auto",
              lineHeight: 1,
              height: "auto",
              fontSize: "14px",
            }}
          >
            {actionText}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default MeetingRoom;
