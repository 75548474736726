import {
  getLiveEvent,
  getLiveEventAttendees,
  getLiveEventPhotos,
  refreshLiveEvent,
  refreshLiveEventAttendees,
  refreshLiveEventPhotos,
} from "../../actions/liveEventActions";

import { LiveEventState } from "../../../types/redux";
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: false,
  data: null,
  refreshing: false,
  attendees: {
    loading: false,
    refreshing: false,
    error: null,
    success: false,
    data: [],
  },
  photos: {
    loading: false,
    refreshing: false,
    error: null,
    success: false,
    data: [],
  },
} as LiveEventState;

const liveEventSlice = createSlice({
  name: "live-event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLiveEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      if (!_.isEqual(state.data, action.payload.result)) {
        state.data = action.payload.result;
      }
    });
    builder.addCase(getLiveEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLiveEvent.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.data = null;
    });
    builder.addCase(refreshLiveEvent.fulfilled, (state, action) => {
      state.refreshing = false;
      state.error = null;
      if (!_.isEqual(state.data, action.payload.result)) {
        state.data = action.payload.result;
      }
    });
    builder.addCase(refreshLiveEvent.pending, (state) => {
      state.refreshing = true;
    });
    builder.addCase(refreshLiveEvent.rejected, (state, action) => {
      state.loading = false;
      state.refreshing = false;
      state.success = false;
      state.error = action.payload;
      state.data = null;
    });
    builder.addCase(getLiveEventAttendees.fulfilled, (state, action) => {
      state.attendees.loading = false;
      state.attendees.success = true;
      state.attendees.error = null;
      if (!_.isEqual(state.data, action.payload.result)) {
        state.attendees.data = action.payload.result;
      }
    });
    builder.addCase(getLiveEventAttendees.pending, (state) => {
      state.attendees.loading = true;
    });
    builder.addCase(getLiveEventAttendees.rejected, (state, action) => {
      state.attendees.loading = false;
      state.attendees.success = false;
      state.attendees.error = action.payload;
      state.attendees.data = [];
    });
    builder.addCase(refreshLiveEventAttendees.fulfilled, (state, action) => {
      if (!_.isEqual(state.data, action.payload.result)) {
        state.attendees.data = action.payload.result;
      }
      state.attendees.refreshing = false;
      state.attendees.error = null;
      state.attendees.success = true;
    });
    builder.addCase(refreshLiveEventAttendees.pending, (state) => {
      state.attendees.refreshing = true;
    });
    builder.addCase(refreshLiveEventAttendees.rejected, (state, action) => {
      state.attendees.loading = false;
      state.attendees.success = false;
      state.attendees.refreshing = false;
      state.attendees.error = action.payload;
      state.attendees.data = [];
    });
    builder.addCase(getLiveEventPhotos.fulfilled, (state, action) => {
      state.photos.loading = false;
      state.photos.success = true;
      state.photos.error = null;
      if (!_.isEqual(state.data, action.payload.result)) {
        state.photos.data = action.payload.result;
      }
    });
    builder.addCase(getLiveEventPhotos.pending, (state) => {
      state.photos.loading = true;
    });
    builder.addCase(getLiveEventPhotos.rejected, (state, action) => {
      state.photos.loading = false;
      state.photos.success = false;
      state.photos.error = action.payload;
      state.photos.data = [];
    });
    builder.addCase(refreshLiveEventPhotos.fulfilled, (state, action) => {
      state.photos.refreshing = false;
      state.photos.error = null;
      state.photos.success = true;
      if (!_.isEqual(state.data, action.payload.result)) {
        state.photos.data = action.payload.result;
      }
    });
    builder.addCase(refreshLiveEventPhotos.pending, (state) => {
      state.photos.refreshing = true;
    });
    builder.addCase(refreshLiveEventPhotos.rejected, (state, action) => {
      state.photos.loading = false;
      state.photos.success = false;
      state.photos.refreshing = false;
      state.photos.error = action.payload;
      state.photos.data = [];
    });
  },
});

export default liveEventSlice.reducer;
