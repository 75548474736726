import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StackItem = styled(Box)(() => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 1,
}));

export const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.neutral.white,
  borderTop: `1px solid ${theme.colors.neutral.softGray}`,
  display: "flex",
  justifyContent: "center",
  padding: "24px 32px 24px 32px",
}));
