import { RoomState } from "../../../types/redux";
import { createSlice } from "@reduxjs/toolkit";
import { getAvailableRooms } from "../../actions/roomActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  availableRooms: [],
} as RoomState;

const roomSlice = createSlice({
  name: "room",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAvailableRooms.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.availableRooms = action.payload.result.roomsAvailable;
    });
    builder.addCase(getAvailableRooms.pending, (state) => {
      state.loading = true;
      state.error = false;
    });
    builder.addCase(getAvailableRooms.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.availableRooms = [];
    });
  },
});

export default roomSlice.reducer;
