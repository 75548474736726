import * as Styled from "./CreateEventPage.styles";

import {
  APIError,
  Checkbox,
  ChipListInput,
  DatePickerInput,
  FileInput,
  FormBuilder,
  Input,
  MeetingRoom,
  MenuButton,
  PageContainer,
  RadioGroup,
  RichTextEditor,
  TimeRangePicker,
} from "../../components";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  InputAdornment,
  Typography,
  useTheme,
} from "@mui/material";
import {
  CREATE_EVENT,
  TRIGGER_EVENT_EMAIL_ACTION,
} from "../../services/endpoints";
import {
  ClearFormModal,
  DraftEventsModal,
  EventOpsConsentModal,
  RoomFinderModal,
} from "../../modals";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";

import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import DOMPurify from "dompurify";
import { Event } from "../../types/event";
import { NEW_EVENT_CREATED } from "../../store/actions/newEventActions";
import { Room } from "../../types/room";
import { RootState } from "../../store/store";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import { UserState } from "../../types/redux";
import axiosAPI from "../../services/axios";
import { combineDayJSToUnix } from "../../utils/utils";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import formOptions from "./formOptions";
import { getAllEvents } from "../../store/actions/eventsActions";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import imageCompression from "browser-image-compression";
import { useAppDispatch } from "../../store/hooks";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

type CreateEventForm = {
  signUpCreator: boolean;
  title: string;
  description: string;
  eventDate?: Dayjs | null;
  startDateTime?: Dayjs | null;
  endDateTime?: Dayjs | null;
  dressCode: string;
  admissionCost: number;
  eventImageFile: File | null;
  format: string;
  businessAreaTags: string[];
  groupTags: string[];
  teamTags: string[];
  location: string;
  transportationParkingDetails: string;
  primaryContacts: string[];
  includeTeamsLink: string;
  teamsLink?: string;
  feedbackLink: string;
  includeSignUpSurvey: string;
  signUpSurvey: { type: string; question: string }[];
  room: Room | null;
};

const CreateEventPage = () => {
  const currentTime = dayjs();
  const roundToNearest30Minutes = (dateTime: Dayjs) => {
    const minutes = dateTime.minute();
    const roundedMinutes = Math.round(minutes / 30) * 30;
    return dateTime.startOf("hour").add(roundedMinutes, "minute");
  };

  const userInfo = useSelector((state: any) => {
    const userState = state.user as UserState;
    return userState;
  });

  const selectUserEventsDraftLoading = useSelector(
    (state: RootState) => state.userEvents.loading
  );

  const selectUserEventsDraft = useSelector(
    (state: RootState) => state.userEvents.draft
  );

  const { loading: roomsLoading } = useSelector(
    (state: RootState) => state.room
  );

  const formMethods = useForm({
    defaultValues: {
      title: "",
      description: "",
      eventDate: dayjs(),
      startDateTime: roundToNearest30Minutes(currentTime),
      endDateTime: roundToNearest30Minutes(currentTime).add(30, "minute"),
      dressCode: "",
      admissionCost: 0,
      format: "",
      eventImageFile: null,
      businessAreaTags: [],
      groupTags: [],
      teamTags: [],
      signUpCreator: true,
      location: "399 Boylston St #1000, Boston, MA",
      transportationParkingDetails: "",
      primaryContacts: [userInfo.name],
      includeTeamsLink: "no",
      teamsLink: "",
      feedbackLink: "",
      includeSignUpSurvey: "no",
      signUpSurvey: [],
      room: null,
    },
    mode: "onChange",
  });
  const [showClearFormModal, setShowClearFormModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createEventError, setCreateEventError] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [showDraftEventsModal, setShowDraftEventsModal] = useState(false);
  const [showRoomFinderModal, setShowRoomFinderModal] = useState(false);
  const [roomFinderError, setRoomFinderError] = useState(false);
  const [roomFinderErrorMessage, setRoomFinderErrorMessage] = useState("");

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const includeSignUpSurvey = formMethods.watch("includeSignUpSurvey");
  const selectedRoom = formMethods.watch("room");

  const format = formMethods.watch("format");
  const { errors } = formMethods.formState;
  const hasErrors = Object.keys(errors).length > 0;

  const toISOString = (timestamp: number) =>
    new Date(timestamp * 1000).toISOString();

  const compressAndConvertImage = async (imageFile: File) => {
    const options = {
      maxSizeMB: 3.0,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(imageFile, options);
    return await imageCompression.getDataUrlFromFile(compressedFile);
  };

  const createEventEmailPayload = (event: Event, room: Room | null) => ({
    action: "create",
    eventTitle: event.title,
    eventDescription: event.description,
    eventStartDateTime: toISOString(event.startDateTime),
    eventEndDateTime: toISOString(event.endDateTime),
    roomLocation:
      event.format === "In Person" || event.format === "Hybrid"
        ? room?.email || ""
        : "",
    eventInvitees: userInfo.email,
    eventOrganizer: event.eventOrganizerName,
    eventOrganizerEmail: event.eventOrganizerEmail,
    eventFormat: event.format,
    eventImage: "",
    eventLocation:
      event.format === "In Person" || event.format === "Hybrid"
        ? room?.name || event.location
        : event.location,
  });

  const sendEventEmail = async (event: Event, room: Room | null) => {
    const payload = createEventEmailPayload(event, room);
    await axiosAPI.post(TRIGGER_EVENT_EMAIL_ACTION(event.eventId), payload);
  };

  const createEventObj = (data: any, eventHero: string, status: string) => {
    return {
      ...data,
      description: DOMPurify.sanitize(
        `<div style="margin: 0;">${draftToHtml(
          convertToRaw(data.description.getCurrentContent())
        )}</div>`
      ),
      startDateTime: combineDayJSToUnix(
        data.eventDate as Dayjs,
        data.startDateTime as Dayjs
      ),
      endDateTime: combineDayJSToUnix(
        data.eventDate as Dayjs,
        data.endDateTime as Dayjs
      ),
      location: data.room?.name || data.location,
      roomName: data.room?.name || "",
      roomEmail: data.room?.email || "",
      eventHero,
      status,
      eventOrganizerName: userInfo.name,
      eventOrganizerTitle: userInfo.jobTitle,
      eventOrganizerEmail: userInfo.email,
      participantEmail: "NA",
      signUpCreator: status === "DRAFT" ? false : data.signUpCreator,
    };
  };

  const createEvent = async (
    newEventObj: any,
    room: Room | null,
    sendEmail: boolean = true,
    isPublish: boolean = true
  ) => {
    try {
      setLoading(true);
      const response = await axiosAPI.post(CREATE_EVENT(), newEventObj);
      const { result }: { result: Event } = response.data;

      if (sendEmail) {
        await sendEventEmail(result, room);
      }

      if (isPublish) {
        dispatch(getAllEvents());
        dispatch(getAllUserEvents(userInfo.email));
        dispatch({
          type: NEW_EVENT_CREATED,
          payload: { id: result.eventId },
        });
        navigate("/events");
      } else {
        dispatch(getAllEvents());
        dispatch(getAllUserEvents(userInfo.email));
        navigate(`/draft/${response.data.result.eventId}`, {
          state: {
            alert: {
              severity: "success",
              message: "Your draft has successfully been saved!",
            },
          },
        });
      }
    } catch (error: any) {
      const errorMessage = error.response?.data?.data;

      if (
        errorMessage.includes("Room is not available for the specified time")
      ) {
        setRoomFinderError(true);
        setRoomFinderErrorMessage(
          "The room that you have selected is no longer available. Please try again."
        );
      } else {
        setCreateEventError(true);
      }
    } finally {
      setLoading(false);
      window.scroll(0, 0);
    }
  };

  const consentModalCallback = async () => {
    const data = formMethods.getValues();
    setLoading(true);
    try {
      const eventImage = data.eventImageFile
        ? await compressAndConvertImage(data.eventImageFile as File)
        : "";
      const newEventObj = createEventObj(data, eventImage, "ACTIVE");
      await createEvent(newEventObj, data.room, true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const saveDraft = async () => {
    const data = formMethods.getValues();
    setLoading(true);
    try {
      const eventImage = data.eventImageFile
        ? await compressAndConvertImage(data.eventImageFile as File)
        : "";
      const newEventObj = createEventObj(data, eventImage, "DRAFT");
      await createEvent(newEventObj, data.room, false, false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit: SubmitHandler<CreateEventForm> = async (data) => {
    setShowConsentModal(true);
  };

  return (
    <>
      <EventOpsConsentModal
        open={showConsentModal}
        onClose={() => {
          setShowConsentModal(false);
        }}
        onConfirm={consentModalCallback}
      />
      <RoomFinderModal
        name={"room"}
        control={formMethods.control}
        setValue={formMethods.setValue}
        open={showRoomFinderModal}
        onClose={() => {
          setShowRoomFinderModal(false);
        }}
        roomFinderError={roomFinderError}
        roomFinderErrorMessage={roomFinderErrorMessage}
        setRoomFinderError={setRoomFinderError}
        setRoomFinderErrorMessage={setRoomFinderErrorMessage}
      />
      <DraftEventsModal
        open={showDraftEventsModal}
        onClose={() => {
          setShowDraftEventsModal(false);
        }}
      />
      <ClearFormModal
        open={showClearFormModal}
        onClose={() => {
          setShowClearFormModal(false);
        }}
        reset={formMethods.reset}
      />
      <PageContainer>
        {createEventError ? (
          <APIError
            onClick={() => {
              setCreateEventError(false);
            }}
          />
        ) : (
          <Styled.PaddedContainer>
            <FormProvider {...formMethods}>
              <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                {roomFinderError && (
                  <Alert
                    severity="error"
                    sx={{ marginBottom: theme.spacing(2) }}
                  >
                    {roomFinderErrorMessage}
                  </Alert>
                )}
                <Styled.HeaderStack
                  direction={{ mobile: "column", desktop: "row" }}
                  width="100%"
                >
                  <Styled.HeaderText variant="h1" gutterBottom>
                    Create An Event
                  </Styled.HeaderText>
                  <Styled.SignUpCheckboxContainer>
                    <Typography> Sign me up for this event</Typography>
                    <Checkbox
                      name="signUpCreator"
                      control={formMethods.control}
                      defaultChecked
                    />
                  </Styled.SignUpCheckboxContainer>
                </Styled.HeaderStack>
                {selectUserEventsDraftLoading ? (
                  <Styled.SubtitleActionTextLoading variant="modalSubtitle">
                    {`Loading draft events...`}
                  </Styled.SubtitleActionTextLoading>
                ) : (
                  <Styled.SubtitleActionText
                    variant="modalSubtitle"
                    onClick={() => {
                      setShowDraftEventsModal(true);
                    }}
                  >
                    {`View draft events (${selectUserEventsDraft.length})`}
                  </Styled.SubtitleActionText>
                )}
                <Styled.FormSectionContainer>
                  <Typography variant="h3"> Details </Typography>
                  <Input
                    name={"title"}
                    control={formMethods.control}
                    label={"Event Title"}
                    required
                    placeholder="Enter title..."
                    fullWidth
                  />
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.colors.primary.slalomDarkBlue,
                      marginTop: theme.spacing(2),
                    }}
                  >
                    Event Description
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: theme.colors.neutral.darkGray,
                    }}
                  >
                    The event description will be added to the Outlook email
                    invite. Use the space below to format your text, or paste
                    text to this area from Word or Outlook. Currently you cannot
                    add images, but that will be coming soon!
                  </Typography>
                  <RichTextEditor
                    name={"description"}
                    control={formMethods.control}
                    setValue={formMethods.setValue}
                    required
                    label="Event Description"
                  />

                  <Styled.InputStack
                    direction={{ mobile: "column", desktop: "row" }}
                    spacing={theme.spacing(3)}
                    width="100%"
                  >
                    <DatePickerInput
                      name={"eventDate"}
                      control={formMethods.control}
                      label="Event Date"
                      required
                    />

                    <TimeRangePicker
                      startName={"startDateTime"}
                      endName={"endDateTime"}
                      required
                      control={formMethods.control}
                    />
                  </Styled.InputStack>
                  <Styled.InputStack
                    direction={{ mobile: "column", desktop: "row" }}
                    spacing={theme.spacing(3)}
                    width="100%"
                  >
                    <Input
                      name={"dressCode"}
                      control={formMethods.control}
                      label={"Dress Code"}
                      placeholder="Enter title..."
                      select
                      selectOptions={formOptions.dressCode}
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                    <Input
                      name={"admissionCost"}
                      control={formMethods.control}
                      label={"Cost"}
                      placeholder="0.00"
                      inputProps={{ type: "number" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Typography
                            variant="body1"
                            style={{ color: "#000000" }}
                          >
                            $
                          </Typography>
                        </InputAdornment>
                      }
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                    <FileInput
                      name={"eventImageFile"}
                      control={formMethods.control}
                      label={"Event Image"}
                      placeholder="900x400 png/jpg"
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                  </Styled.InputStack>
                  <Styled.InputStack
                    direction={{ mobile: "column", desktop: "row" }}
                    spacing={theme.spacing(3)}
                    width="100%"
                  >
                    <Input
                      name={"businessAreaTags"}
                      control={formMethods.control}
                      label={"Business Area(s)"}
                      required
                      select
                      multiple
                      selectOptions={formOptions.businessAreas}
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                    <Input
                      name={"groupTags"}
                      control={formMethods.control}
                      label={"Group(s)"}
                      required
                      select
                      multiple
                      selectOptions={formOptions.groups}
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                    <Input
                      name={"teamTags"}
                      control={formMethods.control}
                      label={"Team(s)"}
                      required
                      select
                      multiple
                      selectOptions={formOptions.teams}
                      sx={{
                        width: "100%",
                        [theme.breakpoints.up("desktop")]: {
                          width: "33%",
                        },
                      }}
                    />
                  </Styled.InputStack>
                  <Input
                    name={"format"}
                    control={formMethods.control}
                    label={"Event Format"}
                    required
                    select
                    selectOptions={formOptions.eventFormats}
                    fullWidth
                  />
                  {format !== "Remote" && format !== "" && (
                    <>
                      {roomFinderError && (
                        <Typography
                          variant="body2"
                          sx={{
                            marginTop: theme.spacing(2),
                            color: theme.colors.action.failure,
                          }}
                        >
                          {roomFinderErrorMessage}
                        </Typography>
                      )}
                      {selectedRoom && (
                        <Box
                          sx={{
                            marginTop: theme.spacing(2),
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: theme.spacing(2),
                          }}
                        >
                          {roomsLoading && <CircularProgress size={24} />}
                          <MeetingRoom
                            room={selectedRoom}
                            actionText={"Change Room"}
                            onClick={() => {
                              setShowRoomFinderModal(true);
                            }}
                            watchedRoom={selectedRoom}
                            border={`1px solid ${theme.colors.neutral.softGray}`}
                            bypassShowAction
                          />
                        </Box>
                      )}
                      {!selectedRoom && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            gap: theme.spacing(2),
                            marginTop: theme.spacing(2),
                          }}
                        >
                          {roomsLoading && <CircularProgress size={24} />}
                          <Button
                            onClick={() => {
                              setShowRoomFinderModal(true);
                            }}
                            variant="outlined"
                          >
                            Show available rooms
                          </Button>
                        </Box>
                      )}
                    </>
                  )}
                </Styled.FormSectionContainer>
                <Styled.FormSectionContainer>
                  <Typography variant="h3"> Event Feedback </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: theme.colors.neutral.darkGray,
                      marginTop: theme.spacing(1),
                    }}
                  >
                    {
                      "Please generate and paste a link to a survey or feedback form (ex. SparkThink) for attendees to fill out during/after the event."
                    }
                  </Typography>
                  <Input
                    name={"feedbackLink"}
                    control={formMethods.control}
                    label={"Feedback Link"}
                    required
                    placeholder="Enter feedback link..."
                    fullWidth
                    forLink
                  />
                </Styled.FormSectionContainer>
                <Styled.FormSectionContainer>
                  <Typography variant="h3"> Location </Typography>
                  <Input
                    name={"location"}
                    control={formMethods.control}
                    label={"Location"}
                    required
                    placeholder="Enter details..."
                    fullWidth
                  />

                  <Input
                    name={"transportationParkingDetails"}
                    control={formMethods.control}
                    label={"Transportation & parking details"}
                    placeholder="Enter details..."
                    fullWidth
                    multiline
                  />
                </Styled.FormSectionContainer>
                <Styled.FormSectionContainer>
                  <Typography variant="h3"> {"Primary Contact(s)"} </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: theme.colors.neutral.darkGray,
                      marginTop: theme.spacing(1),
                    }}
                  >
                    {
                      "Include the names of the employees responsible for coordinating this event by typing their name and hitting ENTER."
                    }
                  </Typography>
                  <ChipListInput
                    name={"primaryContacts"}
                    control={formMethods.control}
                    label={"Primary Contact(s)"}
                    required
                    placeholder="Start typing a name..."
                    fullWidth
                  />
                </Styled.FormSectionContainer>
                <Styled.FormSectionContainer>
                  <Typography variant="h3"> Signup Survey </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      marginTop: theme.spacing(1),
                    }}
                  >
                    {
                      "Do you want to ask any specific questions to attendees upon signup for this event?"
                    }
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    style={{
                      color: theme.colors.neutral.darkGray,
                      marginTop: theme.spacing(1),
                      marginBottom: theme.spacing(1),
                    }}
                  >
                    {"For example “Do you have any dietary restrictions?”"}
                  </Typography>
                  <RadioGroup
                    control={formMethods.control}
                    name={"includeSignUpSurvey"}
                    label=""
                    row
                    options={[
                      { label: "Yes", value: "yes" },
                      { label: "No", value: "no" },
                    ]}
                  />
                  {includeSignUpSurvey === "yes" && (
                    <FormBuilder
                      control={formMethods.control}
                      formName="signUpSurvey"
                    />
                  )}
                </Styled.FormSectionContainer>
                {loading ? (
                  <Styled.LoaderContainer>
                    <CircularProgress />
                  </Styled.LoaderContainer>
                ) : (
                  <Box>
                    <Box
                      display="flex"
                      justifyContent={{ mobile: "center", desktop: "end" }}
                    >
                      {hasErrors && (
                        <Styled.ErrorMessage>
                          Please correct all errors before publishing the event
                        </Styled.ErrorMessage>
                      )}
                      {roomFinderError && (
                        <Styled.ErrorMessage>
                          {roomFinderErrorMessage}
                        </Styled.ErrorMessage>
                      )}
                    </Box>
                    <Styled.FormActionStack
                      direction={{ mobile: "column", desktop: "row-reverse" }}
                      spacing={theme.spacing(4)}
                      width="100%"
                      alignItems="flex-end"
                    >
                      <Styled.ActionButton
                        variant="contained"
                        type="submit"
                        disabled={roomsLoading || roomFinderError}
                      >
                        Publish Event
                      </Styled.ActionButton>
                      <MenuButton
                        menuItems={[
                          {
                            label: "Save To Drafts",
                            icon: <SaveOutlinedIcon />,
                            onClick: () => {
                              saveDraft();
                            },
                          },
                          {
                            label: "Reset Event Form",
                            icon: <ClearOutlinedIcon />,
                            onClick: () => {
                              setShowClearFormModal(true);
                            },
                          },
                        ]}
                        menuLabel="More Actions"
                        buttonStyles={{
                          [theme.breakpoints.down("desktop")]: {
                            width: "100%",
                          },
                        }}
                      />
                    </Styled.FormActionStack>
                  </Box>
                )}
              </form>
            </FormProvider>
          </Styled.PaddedContainer>
        )}
      </PageContainer>
    </>
  );
};

export default CreateEventPage;
