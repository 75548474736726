import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

type ToggleButtonStyledProps = {
  option: number;
  index: number;
  optionslength: number;
  spacing: number;
  height: string;
  padding: string;
};

export const ToggleButtonOption = styled(Button)<ToggleButtonStyledProps>(
  ({ theme, option, index, optionslength, spacing, height, padding }) => ({
    marginRight: index < optionslength - 1 ? spacing : 0,
    borderRadius: "8px",
    height: height,
    padding: padding,
    backgroundColor:
      index === option ? theme.colors.neutral.softGray : "transparent",
    color:
      index === option
        ? theme.colors.neutral.black
        : theme.colors.neutral.darkGray,
    "@media (hover: none)": {
      "&:hover": {
        backgroundColor: "initial",
      },
    },
  })
);
