import {
  CLEAR_NEW_EVENT_CREATED,
  NEW_EVENT_CREATED,
} from "../../actions/newEventActions";

import { NewEventState } from "../../../types/redux";

const initialState = {
  eventCreated: false,
  id: "",
} as NewEventState;

const NewEventCreatedReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case NEW_EVENT_CREATED:
      return {
        ...state,
        eventCreated: true,
        id: action.payload.id,
      };
    case CLEAR_NEW_EVENT_CREATED:
      return {
        ...state,
        eventCreated: false,
        id: "",
      };
    default:
      return state;
  }
};

export default NewEventCreatedReducer;
