import { Box, Stack } from "@mui/material";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import GroupIcon from "@mui/icons-material/Group";
import { styled } from "@mui/material/styles";

export const CalendarIcon = styled(CalendarMonthIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.colors.primary.slalomBlue,
}));

export const ImageContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("mobile")]: {
    width: "0%",
  },
  [theme.breakpoints.up("tablet")]: {
    width: "25%",
  },
  [theme.breakpoints.up("desktop")]: {
    width: "15%",
  },
}));

export const TextContainer = styled(Stack)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0`,
  [theme.breakpoints.up("mobile")]: {
    width: "100%",
    paddingRight: `${theme.spacing(2)}`,
  },
  [theme.breakpoints.up("tablet")]: {
    width: "75%",
    paddingLeft: `${theme.spacing(2)}`,
  },
  [theme.breakpoints.up("desktop")]: {
    width: "85%",
    paddingLeft: `${theme.spacing(3)}`,
  },
}));

export const SignUpsIcon = styled(GroupIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.colors.secondary.purple,
}));

export const SuperScript = styled("sup")(() => ({
  position: "relative",
  fontSize: 10,
  top: 2.3,
}));
