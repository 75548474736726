import { Box, Button, IconButton, Avatar as MuiAvatar } from "@mui/material";

import { styled } from "@mui/material/styles";

export const HeaderContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "1fr auto 1fr",
  alignItems: "center",
  height: "100px",
  width: "100%",
  borderBottom: `1px solid ${theme.colors.neutral.softGray}`,
  [theme.breakpoints.up("mobile")]: {
    padding: theme.spacing(1, 2),
  },
  [theme.breakpoints.up("tablet")]: {
    padding: theme.spacing(3, 4),
  },
  [theme.breakpoints.up("desktop")]: {
    padding: theme.spacing(3, 4),
  },
  position: "relative",
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: "white",
}));

export const MenuButton = styled(IconButton)(({ theme }) => ({
  display: "flex",
  [theme.breakpoints.up("desktop")]: {
    display: "none",
  },
  color: theme.colors.neutral.black,
  alignItems: "center",
  justifyContent: "center",
}));

export const SearchButtonMobile = styled(IconButton)(() => ({
  padding: 0,
  display: "flex",
  marginRight: "12px",
}));

export const AvatarButtonDesktop = styled(Button)(() => ({
  padding: 0,
  display: "flex",
  margin: "none",
  "&:hover": {
    backgroundColor: "transparent",
  },
  buttonRadius: "50%",
  textDecoration: "none",
}));

export const Avatar = styled(MuiAvatar)(({ theme }) => ({
  width: "2.5rem",
  height: "2.5rem",
}));

export const SearchBar = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.colors.neutral.lightGray,
  borderRadius: "50px",
  marginLeft: 0,
  width: "35%",
  borderColor: "transparent",
  borderWidth: "1px",
  borderStyle: "solid",
  transition: "border-color 0.3s ease-in-out, width 0.3s ease-in-out",
  "&:hover": {
    borderColor: theme.colors.primary.slalomBlue,
  },
  "&:focus-within": {
    width: "60%",
    borderColor: theme.colors.primary.slalomBlue,
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const ClearIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: 0,
  top: 0,
  cursor: "pointer",
}));

export const AvatarButtonMobile = styled(IconButton)(() => ({
  padding: 0,
  display: "flex",
}));
