import { Box, Fab, Skeleton, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

export const HeaderText = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
}));

export const SkeletonCardContainer = styled(Box)(() => ({
  width: "100%",
  height: "354px",
}));

export const SkeletonCardDescription = styled(Skeleton)(() => ({
  borderBottomLeftRadius: 12,
  borderBottomRightRadius: 12,
  width: "100%",
  height: "100px",
}));

export const SkeletonCardImage = styled(Skeleton)(() => ({
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  width: "100%",
  height: "200px",
}));

export const SkeletonCardText = styled(Skeleton)(() => ({
  width: "100%",
  height: "40px",
}));

export const InfiniteScrollContainer = styled(Box)(() => ({
  flexGrow: 1,
  paddingTop: 4,
}));

export const InfiniteScrollEndContainer = styled(Box)(() => ({
  textAlign: "center",
  marginTop: 32,
}));

export const PaddedContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

export const ScrollToTopFab = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: 30,
  left: "50%",
  marginLeft: "-52.55px",
  boxShadow: `0px 6px 6px 0px ${theme.colors.action.hoverShadow}`,
}));
