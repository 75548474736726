import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useEffect, useRef, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import ClearIcon from "@mui/icons-material/Clear";
import { Controller } from "react-hook-form";
import theme from "../../theme/theme";
import { useFormContext } from "react-hook-form";

type InputProps = {
  name: string;
  control: any;
  label?: string;
  required?: boolean;
  autoComplete?: string;
  placeholder?: string;
  variant?: TextFieldProps["variant"];
  multiline?: boolean;
  fullWidth?: boolean;
  sx?: any;
  placeholderFileName?: string;
  setPlaceholderFileName?: any;
};

const FileInput = ({
  name,
  control,
  label,
  required = false,
  autoComplete = "off",
  placeholder,
  variant = "outlined",
  multiline = false,
  fullWidth = false,
  sx,
  placeholderFileName = "",
  setPlaceholderFileName,
}: InputProps & TextFieldProps) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [fileName, setFileName] = useState(placeholderFileName);
  const { setValue } = useFormContext();

  useEffect(() => {
    if (placeholderFileName && placeholderFileName !== "FILE_CHANGED") {
      const blob = new Blob(["Placeholder content"], { type: "text/plain" });
      const simulatedFile = new File([blob], placeholderFileName, {
        type: "text/plain",
      });

      setValue(name, simulatedFile);
      setFileName(placeholderFileName);
    }
  }, [placeholderFileName, setValue, name]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setFileName(file.name);
      setValue(name, file);
      if (setPlaceholderFileName) {
        setPlaceholderFileName("FILE_CHANGED");
      }
    } else {
      if (event.target.value === "") {
        return;
      }

      setFileName("");
      setValue(name, null);
      if (setPlaceholderFileName) {
        setPlaceholderFileName("FILE_CHANGED");
      }
    }
  };

  const handleClearFile = (event: any) => {
    event.stopPropagation();
    setFileName("");
    setValue(name, null);
    if (setPlaceholderFileName) {
      setPlaceholderFileName("FILE_CHANGED");
    }
  };

  const handleInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={placeholderFileName}
        rules={{
          required: { value: required, message: `${label} is required` },
        }}
        render={({ field, fieldState: { error } }) => (
          <TextField
            variant={variant}
            {...field}
            value={fileName}
            label={label}
            placeholder={placeholder}
            onClick={handleInputClick}
            helperText={error?.message}
            autoComplete={autoComplete}
            multiline={multiline}
            error={!!error}
            fullWidth={fullWidth}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {fileName && (
                    <ClearIcon
                      onClick={handleClearFile}
                      style={{
                        cursor: "pointer",
                        marginTop: "3px",
                        color: theme.colors.action.failure,
                      }}
                    />
                  )}
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {fileName ? (
                    <ChangeCircleOutlinedIcon />
                  ) : (
                    <AddCircleOutlineIcon />
                  )}
                </InputAdornment>
              ),
              style: { color: theme.colors.neutral.black },
              readOnly: true,
            }}
            sx={{
              ...sx,
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(0),
            }}
          />
        )}
      />
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/jpeg, image/png, image/heic"
        onChange={handleFileChange}
      />
    </>
  );
};

export default FileInput;
