import { Box, Typography as MUITypography } from "@mui/material";

import { Search } from "../../assets";
import { styled } from "@mui/material/styles";

export const ModalTitleContainer = styled(Box)(({ theme }) => ({
  boxShadow: `0px 10px 20px -10px ${theme.colors.action.hoverShadow}`,
  margin: "-24px -40px",
  padding: "24px 40px",
}));

export const SearchIcon = styled(Search)(() => ({
  width: "10vh",
  height: "auto",
  margin: "16px",
}));

export const Typography = styled(MUITypography)(() => ({
  marginBottom: "16px",
}));

export const GearGIF = styled("img")(() => ({
  width: "10vh",
  height: "auto",
  margin: "16px",
}));
