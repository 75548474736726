import { Box, Button } from "@mui/material";

import { styled } from "@mui/material/styles";

export const ModalTitleContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const ConfirmationButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const DownloadButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  transition: "opacity 0.3s ease",
  "&:hover": {
    opacity: 0.7,
  },
}));
