import * as Styled from "./AvatarMenu.styles";

const AvatarMenu = ({ user }: any) => {
  return (
    <Styled.MenuContainer>
      <Styled.UserContainer>
        <Styled.UserAvatar src={``} alt={`user-avatar`} />
        <Styled.UserInfoContainer>
          <Styled.UserName>{user?.name}</Styled.UserName>
          <Styled.Text>{user?.jobTitle}</Styled.Text>
          <Styled.Text>{user?.department}</Styled.Text>
          <Styled.Text>{user?.city}</Styled.Text>
        </Styled.UserInfoContainer>
      </Styled.UserContainer>
      {/* <Styled.SettingsContainer>
        <Styled.SettingsText>Email Notifications</Styled.SettingsText>
        <div>
          {!emailNotifications && (
            <Styled.SettingsText sx={{ paddingRight: '.5rem' }}>
              Off
            </Styled.SettingsText>
          )}
          <Styled.SettingsSwitch
            color="primary"
            checked={emailNotifications}
            onChange={toggleEmailNotifications}
          />
          {emailNotifications && (
            <Styled.SettingsText sx={{ paddingLeft: '.5rem' }}>
              On
            </Styled.SettingsText>
          )}
        </div>
      </Styled.SettingsContainer> */}
    </Styled.MenuContainer>
  );
};

export default AvatarMenu;
