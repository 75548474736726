import * as Styled from "./ClearFormModal.styles";

import { Box, Button, Typography } from "@mui/material";

import BaseModal from "../BaseModal/BaseModal";

type ClearFormModalProps = {
  open: any;
  onClose: any;
  reset: any;
};

const ClearFormModal = ({ open, onClose, reset }: ClearFormModalProps) => {
  return (
    <BaseModal open={open} onClose={onClose} showCloseButton={false}>
      <Box>
        <Styled.ModalTitleContainer>
          <Typography variant="h2">
            Are you sure you wish to reset the event form?
          </Typography>
        </Styled.ModalTitleContainer>
        <Typography variant="modalBody">
          By choosing to reset the event form, all information entered prior
          will be cleared.
        </Typography>
        <Styled.ConfirmationButton
          variant="contained"
          fullWidth
          size="large"
          onClick={() => {
            reset();
            onClose();
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
          }}
        >
          Yes, Reset Form
        </Styled.ConfirmationButton>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={() => {
            onClose();
          }}
        >
          No, Go Back
        </Button>
      </Box>
    </BaseModal>
  );
};

export default ClearFormModal;
