import { COGNITO_USER_INFO_ENDPOINT } from "../../services/endpoints";
import axiosAPI from "../../services/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getUserInfo = createAsyncThunk(
  "user",
  async (nil, { rejectWithValue }) => {
    try {
      const { data } = await axiosAPI.request({
        url: COGNITO_USER_INFO_ENDPOINT(),
        method: "GET",
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
