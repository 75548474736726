import { Button, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";

import BaseModal from "../BaseModal/BaseModal";

type LinkModalProps = {
  open: boolean;
  selection: string;
  onClose: () => void;
  onSubmit: (displayText: string, url: string) => void;
};

const LinkModal = ({ open, onClose, onSubmit, selection }: LinkModalProps) => {
  const [displayText, setDisplayText] = useState("");
  const [url, setUrl] = useState("");

  useEffect(() => {
    setDisplayText(selection);
  }, [selection]);

  return (
    <BaseModal open={open} onClose={onClose}>
      <Stack direction="column" spacing={2} sx={{ marginTop: "32px" }}>
        <TextField
          label="Display Text"
          value={displayText}
          onChange={(e) => setDisplayText(e.target.value)}
        />
        <TextField
          label="Link"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
        />
        <Button
          variant="contained"
          onClick={() => {
            onSubmit(displayText, url);
            onClose();
            setDisplayText("");
            setUrl("");
          }}
        >
          OK
        </Button>
      </Stack>
    </BaseModal>
  );
};

export default LinkModal;
