import { GET_ALL_EVENTS } from "../../services/endpoints";
import axiosAPI from "../../services/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllEvents = createAsyncThunk(
  "events",
  async (nil, { rejectWithValue }) => {
    try {
      const { data } = await axiosAPI.request({
        url: GET_ALL_EVENTS(),
        method: "GET",
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
