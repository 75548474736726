import { IconButton, Modal as MUIModal } from "@mui/material";

import { styled } from "@mui/material/styles";

export const CloseModalButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: 0,
  marginRight: theme.spacing(3),
  marginTop: theme.spacing(-2),
  overflowY: "scroll",
  color: theme.colors.action.modalClose,
}));

export const Modal = styled(MUIModal)(() => ({}));
