import { Container } from "@mui/material";
import { styled } from "@mui/material/styles";

export const PageContainer = styled(Container)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  padding: 0,
  minHeight: "80vh",
}));
