import {
  AuthReducer,
  DraftEventReducer,
  EditEventReducer,
  EventReducer,
  EventsReducer,
  LiveEventReducer,
  NewEventCreatedReducer,
  RoomReducer,
  SearchReducer,
  UserEventsReducer,
  UserReducer,
} from "./reducers";
import { persistReducer, persistStore } from "redux-persist";

import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // only auth will be persisted
};

const reducer = combineReducers({
  auth: AuthReducer,
  edit: EditEventReducer,
  liveEvent: LiveEventReducer,
  event: EventReducer,
  events: EventsReducer,
  draft: DraftEventReducer,
  newEvent: NewEventCreatedReducer,
  room: RoomReducer,
  search: SearchReducer,
  user: UserReducer,
  userEvents: UserEventsReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  devTools: process.env.REACT_APP_ENVIRONMENT !== "prod",
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
