import * as Styled from "./Chip.styles";

import { ChipProps } from "@mui/material";

const Chip = ({ label, onClick, onDelete }: ChipProps) => {
  return (
    <Styled.Chip
      label={label}
      onClick={onClick}
      onDelete={onDelete}
      deleteIcon={<Styled.CloseIcon />}
      color="primary"
    />
  );
};

export default Chip;
