import * as Styled from "./ViewPhotoModal.styles";

import { Box, Stack, Typography } from "@mui/material";

import BaseModal from "../BaseModal/BaseModal";
import { Contact } from "../../components";
import { format } from "date-fns";
import { useTheme } from "@mui/material";

type ViewPhotoModalProps = {
  open: any;
  onClose: any;
  selectedImg: any;
  caption: string;
  currentTime: string;
  uploaderName: string;
};

const ViewPhotoModal = ({
  open,
  onClose,
  selectedImg,
  caption,
  currentTime,
  uploaderName,
}: ViewPhotoModalProps) => {
  const theme = useTheme();

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      tabletWidth="80%"
      desktopWidth="940px"
      fullScreenMobile
      overflow="auto"
    >
      <Stack
        direction="column"
        width="100%"
        style={{ marginTop: theme.spacing(3) }}
      >
        <Styled.SelectedImage src={selectedImg} alt="" />
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Contact
            variant="modalBody"
            size={24}
            spacing={theme.spacing(1)}
            name={uploaderName}
          />
          <Styled.CurrentTimeText>
            {currentTime !== "" &&
              format(new Date(currentTime), "hh:mma - MM/dd/yy")}
          </Styled.CurrentTimeText>
        </Box>
        <Typography
          variant="body2"
          style={{
            wordWrap: "break-word",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            boxSizing: "border-box",
          }}
        >
          {caption}
        </Typography>
      </Stack>
    </BaseModal>
  );
};

export default ViewPhotoModal;
