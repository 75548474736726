import { Box, Button, Stack } from "@mui/material";

import { styled } from "@mui/material/styles";

export const ModalTitleContainer = styled(Box)(({ theme }) => ({
  boxShadow: `0px 10px 20px -10px ${theme.colors.action.hoverShadow}`,
  padding: "24px 40px",
}));

export const ConfirmationButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

interface ColumnProps {
  isSelected: boolean;
}

export const LeftColumn = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<ColumnProps>(({ theme, isSelected }) => ({
  width: "100%",
  padding: theme.spacing(2),
  display: "block",
  [theme.breakpoints.up("tablet")]: {
    width: "50%",
    borderRight: `1px solid ${theme.colors.action.modalClose}`,
  },
  [theme.breakpoints.up("desktop")]: {
    width: "30%",
  },
  [theme.breakpoints.down("tablet")]: {
    display: isSelected ? "none" : "block",
  },
}));

export const RightColumn = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSelected",
})<ColumnProps>(({ theme, isSelected }) => ({
  width: "100%",
  padding: theme.spacing(2),
  position: "relative",
  display: "none",
  [theme.breakpoints.up("tablet")]: {
    width: "50%",
    display: "block",
  },
  [theme.breakpoints.up("desktop")]: {
    width: "70%",
    display: "block",
  },
  [theme.breakpoints.down("tablet")]: {
    display: isSelected ? "block" : "none",
  },
}));

export const InnerLeftColumn = styled(Box)(({ theme }) => ({
  height: "100%",
  overflowY: "auto",
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  "&::-webkit-scrollbar-track": {
    backgroundColor: theme.colors.neutral.white,
    borderRadius: "6px",
    marginBottom: "12px",
    padding: "2px",
  },
  "&::-webkit-scrollbar": {
    width: "6px",
    WebkitAppearance: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.colors.primary.slalomBlue,
    borderRadius: "6px",
    margin: "2px",
  },
}));

export const InnerRightColumn = styled(Box)(({ theme }) => ({
  height: "100%",
  overflowY: "auto",
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(6),
  "&::-webkit-scrollbar-track": {
    backgroundColor: theme.colors.neutral.white,
    borderRadius: "6px",
    marginBottom: "12px",
    padding: "2px",
  },
  "&::-webkit-scrollbar": {
    width: "10px",
    WebkitAppearance: "none",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.colors.primary.slalomBlue,
    borderRadius: "6px",
    margin: "2px",
  },
}));

export const HorizontalStack = styled(Stack)(({ theme }) => ({
  height: "100%",
  padding: theme.spacing(1),
  [theme.breakpoints.down("tablet")]: {
    padding: `${theme.spacing(2)} ${theme.spacing(0)}`,
  },
}));

export const SurveyAnswerContainer = styled(Box)(({ theme }) => ({
  backgroundColor: `${theme.colors.secondary.purple}1A`,
  borderRadius: theme.spacing(1),
  margin: theme.spacing(1),
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
}));
