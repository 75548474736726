import * as Styled from "./PastUserEventsPage.styles";

import {
  APIError,
  EventCard,
  PageContainer,
  ScrollToTopFAB,
  ToggleButton,
} from "../../components";
import { Box, ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import {
  EventDetailsModal,
  EventEditedModal,
  NewEventCreatedModal,
} from "../../modals";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { CLEAR_NEW_EVENT_CREATED } from "../../store/actions/newEventActions";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Event } from "../../types/event";
import { RootState } from "../../store/store";
import { clearEvent } from "../../store/reducers/EditEventReducer/EditEventReducer";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import { getUserInfo } from "../../store/actions/userActions";
import { isCuid } from "cuid";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";

const PastUserEventsPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  const eventIdFromQueryParam = queryParams.get("eventId");

  const [option, setOption] = useState<number>(0);

  const displayOptions = ["All", "Attended", "Signed Up"];

  const userEmail = useSelector((state: RootState) => state.user.email);

  const newEventState = useSelector((state: RootState) => state.newEvent);
  const editEventState = useSelector((state: RootState) => state.edit);

  const userAttendedOrSignedUpEvents = useSelector((state: RootState) => {
    const attendedEvents = state.userEvents.attended;
    const signedUpEvents = state.userEvents.signedUp;

    let eventsToConsider: Event[] = [];
    switch (displayOptions[option]) {
      case "Attended":
        eventsToConsider = attendedEvents;
        break;
      case "Signed Up":
        eventsToConsider = signedUpEvents;
        break;
      case "All":
        eventsToConsider = [...attendedEvents, ...signedUpEvents];
        break;
      default:
        eventsToConsider = [];
    }

    // Step 2 & 3: Remove duplicates for the "All" option
    const uniqueEvents = eventsToConsider.filter(
      (event, index, self) =>
        index === self.findIndex((e) => e.eventId === event.eventId)
    );

    // Step 4: Filter past events
    const pastEvents = uniqueEvents.filter(
      (event) => event.endDateTime < Math.floor(Date.now() / 1000)
    );

    // Step 5: Sort events
    return pastEvents.sort((a, b) => {
      const endDateTimeComparison = b.endDateTime - a.endDateTime;
      if (endDateTimeComparison !== 0) {
        return endDateTimeComparison;
      } else {
        return b.startDateTime - a.startDateTime;
      }
    });
  });

  const userEventsSuccess = useSelector(
    (state: RootState) => state.userEvents.success
  );
  const userEventsError = useSelector(
    (state: RootState) => state.userEvents.error
  );

  const [eventDetailsId, setEventDetailsId] = useState("");
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [showNewEventCreatedModal, setShowNewEventCreatedModal] =
    useState(false);
  const [showEventEditedModal, setShowEventEditedModal] = useState(false);
  useEffect(() => {
    if (eventIdFromQueryParam && isCuid(eventIdFromQueryParam)) {
      setEventDetailsId(eventIdFromQueryParam);
      setShowEventDetailsModal(true);
    }
  }, [eventIdFromQueryParam]);

  useEffect(() => {
    if (!searchParams.has("eventId")) {
      setShowEventDetailsModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setShowNewEventCreatedModal(newEventState.eventCreated);
  }, [newEventState.eventCreated]);

  useEffect(() => {
    setShowEventEditedModal(editEventState.eventEdited);
  }, [editEventState.eventEdited]);

  const handleCloseEventDetailsModal = useCallback(() => {
    navigate("/events/past", { replace: true });
    setShowEventDetailsModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseNewEventModal = useCallback(() => {
    dispatch({ type: CLEAR_NEW_EVENT_CREATED });
    setShowNewEventCreatedModal(false);
  }, [dispatch]);

  const handleCloseEventEditedModal = useCallback(() => {
    dispatch(clearEvent());
    setShowEventEditedModal(false);
  }, [dispatch]);

  return (
    <PageContainer>
      <NewEventCreatedModal
        open={showNewEventCreatedModal}
        onClose={handleCloseNewEventModal}
      />
      <EventEditedModal
        open={showEventEditedModal}
        onClose={handleCloseEventEditedModal}
      />
      <EventDetailsModal
        eventId={eventDetailsId}
        open={showEventDetailsModal}
        onClose={handleCloseEventDetailsModal}
      />
      <ScrollToTopFAB />
      <Styled.PaddedContainer>
        <ButtonBase
          onClick={() => {
            navigate("/events");
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: theme.spacing(4),
            cursor: "pointer",
            transition: "opacity 0.3s ease",
            "&:hover": {
              opacity: 0.7,
            },
          }}
        >
          <ChevronLeftIcon
            color="secondary"
            sx={{ marginRight: theme.spacing(1) }}
          />
          <Typography
            variant="modalSubtitle"
            sx={{
              color: theme.colors.primary.slalomDarkBlue,
            }}
          >
            Back to home
          </Typography>
        </ButtonBase>
        {userEventsSuccess ? (
          <Styled.SubtitleContainer>
            <Styled.SubtitleStackContainer direction="row">
              <Styled.SubtitleTitle variant="h3">
                {`My Past Events (${userAttendedOrSignedUpEvents.length})`}
              </Styled.SubtitleTitle>
            </Styled.SubtitleStackContainer>
            <Styled.SubtitleText variant="body2">
              {userAttendedOrSignedUpEvents.length > 0 &&
                (displayOptions[option] === "Attended"
                  ? "Here are all of the past events that you’ve attended. Select an event to view event details."
                  : displayOptions[option] === "Signed Up"
                  ? "Here are all of the past events that you’ve signed up for. Select an event to view event details."
                  : "Here are all of your past events that you've attended or signed up for. Select an event to view event details.")}
            </Styled.SubtitleText>
            <Box mt={theme.spacing(2)} mb={theme.spacing(1)}>
              <ToggleButton
                option={option}
                setOption={setOption}
                onClick={setOption}
                options={displayOptions}
              />
            </Box>
          </Styled.SubtitleContainer>
        ) : (
          <Styled.SubtitleContainerSkeleton />
        )}
        {userEventsError ? (
          <APIError
            onClick={() => {
              dispatch(getUserInfo());
              dispatch(getAllUserEvents(userEmail));
            }}
          />
        ) : userAttendedOrSignedUpEvents.length === 0 && userEventsSuccess ? (
          <Styled.NoPastEventsContainer>
            <Box>
              <Styled.CalendarIcon />
              <Styled.NoPastEventsText variant="h1">
                No Past Events
              </Styled.NoPastEventsText>
              <Styled.NoPastEventsText>
                You have not attended a Gather hosted event. Once you do, those
                events will be listed here for your future reference.
              </Styled.NoPastEventsText>
            </Box>
          </Styled.NoPastEventsContainer>
        ) : (
          <Styled.ScrollContainer>
            <Grid
              container
              spacing={3}
              columns={{
                mobile: 4,
                tablet: 8,
                desktop: 12,
              }}
            >
              {!userEventsSuccess
                ? Array.from({ length: 3 }, (_, index) => (
                    <Grid key={index} item mobile={4} tablet={4} desktop={4}>
                      <Styled.SkeletonCardContainer>
                        <Styled.SkeletonCardImage
                          variant="rectangular"
                          animation="wave"
                        />
                        <Styled.SkeletonCardText
                          variant="text"
                          animation="wave"
                        />
                        <Styled.SkeletonCardDescription
                          variant="rectangular"
                          animation="wave"
                        />
                      </Styled.SkeletonCardContainer>
                    </Grid>
                  ))
                : userAttendedOrSignedUpEvents.map((event: Event) => (
                    <Grid
                      key={event.eventId}
                      item
                      mobile={4}
                      tablet={4}
                      desktop={4}
                    >
                      <EventCard
                        {...event}
                        onClick={() => {
                          navigate(
                            `${location.pathname}?eventId=${event.eventId}`,
                            { replace: true }
                          );
                          setEventDetailsId(event.eventId);
                          setShowEventDetailsModal(true);
                        }}
                      />
                    </Grid>
                  ))}
            </Grid>
          </Styled.ScrollContainer>
        )}
      </Styled.PaddedContainer>
    </PageContainer>
  );
};

export default PastUserEventsPage;
