import * as Styled from "./DraggableQuestion.styles";

import { Box, Button, IconButton, Stack } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Input from "../../components/Input/Input";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import { useCallback } from "react";
import { useFieldArray } from "react-hook-form";
import { useWatch } from "react-hook-form";

type DraggableQuestionProps = {
  index: number;
  control: any;
  remove: (index?: number | number[] | undefined) => void;
  provided: any;
  snapshot: any;
  formName: string;
  field: any;
};

const questionOptions = [
  {
    label: "Open response",
    value: "Open response",
  },
  {
    label: "Multiple choice",
    value: "Multiple choice",
  },
];

const DraggableQuestion = ({
  index,
  control,
  remove,
  provided,
  snapshot,
  formName,
}: DraggableQuestionProps) => {
  const questionType = useWatch({
    control,
    name: `signUpSurvey[${index}].type`,
    defaultValue: "Open response",
  });

  const {
    fields: optionFields,
    append: appendOption,
    remove: removeOption,
    move,
  } = useFieldArray({
    control,
    name: `${formName}[${index}].options`,
  });

  const handleOptionDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) return;
      const { source, destination } = result;
      if (source.droppableId !== destination.droppableId) return;
      move(source.index, destination.index);
    },
    [move]
  );

  return (
    <Styled.DraggableBox
      {...provided.draggableProps}
      ref={provided.innerRef}
      data-is-dragging={snapshot.isDragging}
    >
      <Box display="flex" sx={{ width: "100%" }}>
        <Stack
          direction={{ mobile: "column", tablet: "row", desktop: "row" }}
          width="100%"
          sx={{ marginRight: 2 }}
        >
          <Styled.QuestionTypeContainer>
            <Input
              name={`${formName}[${index}].type`}
              control={control}
              select
              defaultValue={"Open response"}
              selectOptions={questionOptions}
              label="Question Type"
              required
              removeMarginTop
              fullWidth
            />
          </Styled.QuestionTypeContainer>
          <Input
            name={`${formName}[${index}].question`}
            control={control}
            label="Question"
            required
            fullWidth
            removeMarginTop
          />
        </Stack>
        <Box sx={{ display: "flex", alignItems: "center", width: "80px" }}>
          <IconButton
            {...provided.dragHandleProps}
            onClick={(event) => {
              event.preventDefault();
              remove(index);
            }}
            color="primary"
            sx={{ height: "40px", width: "40px" }}
          >
            <UnfoldMoreIcon />
          </IconButton>
          <IconButton
            onClick={(event) => {
              event.preventDefault();
              remove(index);
            }}
            color="primary"
            sx={{ height: "40px", width: "40px" }}
          >
            <RemoveCircleOutlineIcon />
          </IconButton>
        </Box>
      </Box>
      {questionType === "Multiple choice" && (
        <Styled.MultipleChoiceDragDropContainer>
          <DragDropContext onDragEnd={handleOptionDragEnd}>
            <Droppable droppableId={`options-${index}`} direction="vertical">
              {(provided) => (
                <Box {...provided.droppableProps} ref={provided.innerRef}>
                  {optionFields.map((optionField, optionIndex) => (
                    <Draggable
                      key={optionField.id}
                      draggableId={optionField.id}
                      index={optionIndex}
                    >
                      {(provided, snapshot) => (
                        <Styled.DraggableBox
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                          data-is-dragging={snapshot.isDragging}
                        >
                          <Styled.MultipleChoiceAnswerContainer>
                            <Input
                              name={`signUpSurvey[${index}].options[${optionIndex}].value`}
                              control={control}
                              label={`Answer`}
                              required
                              removeMarginTop
                              fullWidth
                              sx={{
                                marginRight: 2,
                              }}
                            />
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "80px",
                              }}
                            >
                              <IconButton
                                {...provided.dragHandleProps}
                                color="primary"
                              >
                                <UnfoldMoreIcon />
                              </IconButton>
                              <IconButton
                                onClick={(event) => {
                                  event.preventDefault();
                                  removeOption(optionIndex);
                                }}
                                color="primary"
                                sx={{ height: "40px", width: "40px" }}
                              >
                                <RemoveCircleOutlineIcon />
                              </IconButton>
                            </Box>
                          </Styled.MultipleChoiceAnswerContainer>
                        </Styled.DraggableBox>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
          <Button
            startIcon={<AddCircleOutlineIcon />}
            onClick={(event) => {
              event.preventDefault();
              appendOption({ value: "" });
            }}
            sx={{
              fontWeight: "normal",
            }}
          >
            Add Option
          </Button>
        </Styled.MultipleChoiceDragDropContainer>
      )}
    </Styled.DraggableBox>
  );
};

export default DraggableQuestion;
