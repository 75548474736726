import {
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
} from "@mui/material";

import MuiAccordion from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";

type AccordionSummaryContentProps = {
  hasRightComponent: boolean;
};

export const AccordionSummaryContent = styled(
  "div"
)<AccordionSummaryContentProps>(({ hasRightComponent }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  "& > div": {
    display: "flex",
    alignItems: "center",
  },
  "& > div:first-of-type": {
    flex: hasRightComponent ? "0 0 35%" : "1",
    justifyContent: "flex-start",
  },
  "& > div:last-of-type": {
    display: hasRightComponent ? "flex" : "none",
    flex: hasRightComponent ? "0 0 65%" : "0",
    justifyContent: "flex-end",
  },
}));

export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.MuiPaper-root": {
    borderRadius: 0,
  },
  borderBottom: `1px ${theme.colors.neutral.softGray} solid`,
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  paddingTop: 0,
}));

export const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  padding: 0,
  flex: 1,
}));
