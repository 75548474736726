import * as Styled from "./NotFoundPage.styles";

import { Box, Button } from "@mui/material";

import { PageContainer } from "../../components";
import { useNavigate } from "react-router";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <PageContainer>
      <Styled.NotFoundContainer>
        <Box>
          <Styled.SearchIcon />
          <Styled.Typography variant="h1">
            Something's missing...
          </Styled.Typography>
          <Styled.Typography>
            Looks like we can't find the page you are looking for.
          </Styled.Typography>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              navigate("/");
            }}
          >
            Go To Home
          </Button>
        </Box>
      </Styled.NotFoundContainer>
    </PageContainer>
  );
};

export default NotFoundPage;
