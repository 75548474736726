import { Box, Typography } from "@mui/material";

import InputBase from "@mui/material/InputBase";
import { styled } from "@mui/material/styles";

export const MenuContainer = styled(Box)(({ theme }) => ({
  width: "auto",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  marginBottom: "50px",
  padding: theme.spacing(5, 3),
  backgroundColor: theme.colors.neutral.white,
  [theme.breakpoints.up("mobile")]: {
    minHeight: "100vh", // Full height on mobile
    padding: theme.spacing(5, 3),
  },
  [theme.breakpoints.up("tablet")]: {
    minHeight: "100vh", // Full height on tablet
    padding: theme.spacing(5, 3),
  },
  [theme.breakpoints.up("desktop")]: {
    minHeight: "10vh", // Less than full height on desktop
    padding: theme.spacing(5, 3),
  },
}));

export const ResultsFoundText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.colors.neutral.black,
  padding: "24px 0",
  [theme.breakpoints.up("desktop")]: {
    padding: theme.spacing(0),
    height: "auto",
  },
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.h2.fontSize,
  fontWeight: theme.typography.fontWeightBold,
  color: theme.colors.neutral.black,
  padding: "24px 0 8px 0",
}));

export const ParagraphText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightLight,
  color: theme.colors.neutral.black,
  textAlign: "center",
  [theme.breakpoints.up("desktop")]: {
    margin: theme.spacing(0, 60),
  },
}));

export const Search = styled(Box)(({ theme }) => ({
  position: "relative",
  backgroundColor: theme.colors.neutral.lightGray,
  borderRadius: "50px",
  marginLeft: 0,
  width: "100%",
  borderColor: "transparent",
  borderWidth: "1px",
  borderStyle: "solid",
  transition: "border-color 0.3s ease-in-out",
  ":hover": {
    borderColor: theme.colors.primary.slalomBlue,
  },
  marginBottom: theme.spacing(3),
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export const InputPlaceholder = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

export const NoResultsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
}));

export const ClearIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  right: 0,
  top: 0,
  cursor: "pointer",
}));

export const CenteredBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

export const ResultImage = styled("img")(({ theme }) => ({
  height: "128px",
  width: "128px",
}));

export const ResultTextContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
}));
