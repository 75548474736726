import * as Styled from "./RoomFinderModal.styles";

import {
  Box,
  Button,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import { Controller, useWatch } from "react-hook-form";

import BaseModal from "../BaseModal/BaseModal";
import { Gear } from "../../assets";
import { MeetingRoom } from "../../components";
import { Room } from "../../types/room";
import { RootState } from "../../store/store";
import { combineDayJSToUnix } from "../../utils/utils";
import dayjs from "dayjs";
import { getAvailableRooms } from "../../store/actions/roomActions";
import { useAppDispatch } from "../../store/hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";

type RoomFinderModalProps = {
  name: string;
  control: any;
  setValue: any;
  createdEvent?: any;
  open: boolean;
  onClose: () => void;
  roomFinderError: any;
  roomFinderErrorMessage: any;
  setRoomFinderError: any;
  setRoomFinderErrorMessage: any;
};

const RoomFinderModal = ({
  name,
  control,
  setValue,
  open,
  onClose,
  createdEvent = undefined,
  setRoomFinderError,
  setRoomFinderErrorMessage,
  roomFinderError,
  roomFinderErrorMessage,
}: RoomFinderModalProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { success, loading, availableRooms, error } = useSelector(
    (state: RootState) => state.room
  );

  const watchedRoom = useWatch({ control, name });
  const watchedEventDate = useWatch({ control, name: "eventDate" });
  const watchedStartDateTime = useWatch({ control, name: "startDateTime" });
  const watchedEndDateTime = useWatch({ control, name: "endDateTime" });

  const startDateTime = combineDayJSToUnix(
    watchedEventDate,
    watchedStartDateTime
  );
  const endDateTime = combineDayJSToUnix(watchedEventDate, watchedEndDateTime);

  const fetchAvailableRooms = () => {
    const startDate = dayjs.unix(startDateTime);
    const endDate = dayjs.unix(endDateTime);

    const isValidStartDate = startDate.isValid();
    const isValidEndDate = endDate.isValid();
    const isStartBeforeEnd = startDate.isBefore(endDate);

    const isThirtyMinuteInterval = (date: any) => {
      const minutes = date.minute();
      return minutes === 0 || minutes === 30;
    };
    const bothDatesValid = isValidStartDate && isValidEndDate;
    const bothIntervalsCorrect =
      isThirtyMinuteInterval(startDate) && isThirtyMinuteInterval(endDate);

    if (bothDatesValid && isStartBeforeEnd && bothIntervalsCorrect) {
      setRoomFinderError(false);
      setRoomFinderErrorMessage("");
      dispatch(
        getAvailableRooms({
          office: "boston",
          eventStartDateTime: startDate.unix(),
          eventEndDateTime: endDate.unix(),
        })
      );
    } else {
      setRoomFinderError(true);
      setRoomFinderErrorMessage(
        "The selected start and/or end times are invalid. Please correct the times and try again to find available rooms."
      );
    }
  };

  useEffect(() => {
    fetchAvailableRooms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDateTime, endDateTime, dispatch]);

  useEffect(() => {
    if (success && availableRooms.length > 0 && watchedRoom) {
      const roomExists = availableRooms.some(
        (room: Room) => room.email === watchedRoom.email
      );

      const isEditingSameRoom =
        createdEvent &&
        watchedRoom.name === createdEvent.roomName &&
        startDateTime === createdEvent.startDateTime &&
        endDateTime === createdEvent.endDateTime;

      if (!roomExists && !isEditingSameRoom) {
        setRoomFinderError(true);
        setRoomFinderErrorMessage(
          "The room that you have previously selected is no longer available."
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableRooms, success, watchedRoom, createdEvent]);

  return (
    <BaseModal
      open={open}
      onClose={onClose}
      tabletWidth="80%"
      desktopWidth="940px"
    >
      <Box>
        <Styled.ModalTitleContainer>
          <Typography variant="h2" sx={{ marginBottom: theme.spacing(1) }}>
            Event Room Finder
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              color: theme.colors.neutral.darkGray,
              marginTop: theme.spacing(1),
            }}
          >
            Once event is published, your room selection approved/denied via
            email communication
          </Typography>
        </Styled.ModalTitleContainer>
        {error ? (
          <Box sx={{ marginTop: theme.spacing(5) }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: theme.spacing(5),
              }}
            >
              <Styled.GearGIF src={Gear} alt={"gear-gif"} />
              <Styled.Typography variant="h1">Whoops!</Styled.Typography>
              <Styled.Typography>
                We were unable to load rooms. Try refreshing this page or
                checking back later again.
              </Styled.Typography>
              <Button variant="contained" onClick={fetchAvailableRooms}>
                Refresh
              </Button>
            </Box>
          </Box>
        ) : (
          <Box sx={{ marginTop: theme.spacing(5) }}>
            {roomFinderError && (
              <Typography
                variant="body2"
                sx={{
                  marginTop: theme.spacing(2),
                  color: theme.colors.action.failure,
                }}
              >
                {roomFinderErrorMessage}
              </Typography>
            )}
            {watchedRoom && (
              <Box>
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.colors.neutral.darkGray,
                    fontStyle: "italic",
                    marginBottom: theme.spacing(1),
                  }}
                >
                  Selected room
                </Typography>
                <MeetingRoom
                  room={watchedRoom}
                  actionText={"Unselect Room"}
                  onClick={() => {
                    setValue(name, null);
                    setRoomFinderError(false);
                    setRoomFinderErrorMessage("");
                  }}
                  watchedRoom={watchedRoom}
                  border={`1px solid ${theme.colors.neutral.softGray}`}
                  bypassShowAction
                />
              </Box>
            )}
            {loading && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: theme.spacing(5),
                }}
              >
                <Styled.SearchIcon />
                <Styled.Typography variant="h1">Hang tight!</Styled.Typography>
                <Styled.Typography>
                  We are finding rooms for your event...
                </Styled.Typography>
                <LinearProgress sx={{ width: "100%" }} />
              </Box>
            )}
            {!loading && success && availableRooms.length > 0 && (
              <Box
                sx={{
                  position: "relative",
                  height: "500px",
                  overflowY: "auto",
                  marginTop: theme.spacing(2),
                  marginBottom: theme.spacing(2),
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: theme.colors.neutral.darkGray,
                    fontStyle: "italic",
                  }}
                >
                  Found rooms ({availableRooms.length})
                </Typography>
                {availableRooms.map((room) => (
                  <Controller
                    key={room.email}
                    name={name}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <MeetingRoom
                        room={room}
                        actionText={"Select Room"}
                        onClick={() => {
                          onChange(room);
                          setRoomFinderError(false);
                          setRoomFinderErrorMessage("");
                          onClose();
                        }}
                        watchedRoom={watchedRoom}
                        border={`0px 0px 1px 0px solid ${theme.colors.neutral.softGray}`}
                      />
                    )}
                  />
                ))}
              </Box>
            )}
          </Box>
        )}
      </Box>
    </BaseModal>
  );
};

export default RoomFinderModal;
