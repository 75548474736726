import { Box } from "@mui/material";
import { Avatar as MUIAvatar } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Avatar = styled(MUIAvatar)(({ theme }) => ({
  flexShrink: 0,
  marginRight: theme.spacing(1),
}));

interface ContactContainerProps {
  isSelected: boolean;
  isClickable: boolean;
  selectedBackgroundColor?: string;
  selectedBorderColor?: string;
  selectedBoxShadowColor?: string;
}

export const ContactContainer = styled(Box, {
  shouldForwardProp: (prop) =>
    prop !== "isSelected" &&
    prop !== "isClickable" &&
    prop !== "selectedBackgroundColor" &&
    prop !== "selectedBorderColor" &&
    prop !== "selectedBoxShadowColor",
})<ContactContainerProps>(
  ({
    theme,
    isSelected,
    isClickable,
    selectedBackgroundColor = "theme.palette.background.paper",
    selectedBorderColor = "none",
    selectedBoxShadowColor = theme.colors.action.hoverShadow,
  }) => ({
    display: "flex",
    alignItems: "center",
    cursor: isClickable ? "pointer" : "default",
    ...(isClickable && {
      padding: theme.spacing(2),
      [theme.breakpoints.down("tablet")]: {
        padding: theme.spacing(1),
        borderRadius: theme.spacing(1),
      },
      borderRadius: theme.spacing(2),
    }),
    backgroundColor: isSelected ? selectedBackgroundColor : "transparent",
    border: isSelected ? `0.5px solid ${selectedBorderColor}` : "none",
    boxShadow: isSelected
      ? `inset 0 0 0 1px ${selectedBorderColor}, 0px 4px 14px 0px ${selectedBoxShadowColor}`
      : "none",
    "&:hover": isClickable
      ? {
          boxShadow: isSelected
            ? `inset 0 0 0 1px ${selectedBorderColor}, 0px 4px 14px 0px ${selectedBoxShadowColor}`
            : `0px 4px 14px 0px ${selectedBoxShadowColor}`,
        }
      : {},
  })
);
