import { Chip as MUIChip } from "@mui/material";
import MUICloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

export const Chip = styled(MUIChip)(({ theme }) => ({
  margin: theme.spacing(1),
}));

export const CloseIcon = styled(MUICloseIcon)(({ theme }) => ({
  "&&": { color: theme.colors.primary.slalomBlue },
  "&&:hover": { color: theme.colors.primary.slalomDarkBlue },
}));
