import { UserState } from "../../../types/redux";
import { createSlice } from "@reduxjs/toolkit";
import { getUserInfo } from "../../actions/userActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  city: "",
  department: "",
  email: "",
  jobTitle: "",
  name: "",
  role: "",
} as UserState;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.city = action.payload["custom:city"];
      state.department = action.payload["custom:department"];
      state.email = action.payload.name;
      state.jobTitle = action.payload["custom:jobTitle"];
      state.name =
        action.payload["given_name"] + " " + action.payload["family_name"];
      state.role = action.payload["custom:role"];
    });
    builder.addCase(getUserInfo.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserInfo.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.city = "";
      state.department = "";
      state.email = "";
      state.jobTitle = "";
      state.name = "";
      state.role = "";
    });
  },
});

export default userSlice.reducer;
