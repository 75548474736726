import { EventsState } from "../../../types/redux";
import _ from "lodash";
import { createSlice } from "@reduxjs/toolkit";
import { getAllEvents } from "../../actions/eventsActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  data: [],
} as EventsState;

const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllEvents.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      if (!_.isEqual(state.data, action.payload.result)) {
        state.data = action.payload.result;
      }
    });
    builder.addCase(getAllEvents.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllEvents.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.data = [];
    });
  },
});

export default eventsSlice.reducer;
