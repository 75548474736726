import * as Styled from "./EnvironmentIndicator.styles";

import { Typography } from "@mui/material";

type EnvironmentIndicatorProps = {
  environment?: string;
};

const EnvironmentIndicator = ({
  environment = process.env.REACT_APP_ENVIRONMENT || "local",
}: EnvironmentIndicatorProps) => {
  return (
    <Styled.EnvironmentContainer>
      <Typography variant="body1">{environment}</Typography>
    </Styled.EnvironmentContainer>
  );
};

export default EnvironmentIndicator;
