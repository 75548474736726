import * as Styled from "./SignUpSurveyModal.styles";

import { Box, Button, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import {
  SurveyAnswer,
  SurveyData,
  SurveyQuestion,
} from "../../types/surveyquestions";

import BaseModal from "../BaseModal/BaseModal";
import { Input } from "../../components";

type SignUpSurveyModalProps = {
  open: any;
  onClose: any;
  signUpSurvey: SurveyQuestion[];
  navigateToEvent?: any;
  signUpUserCallback: any;
};

const SignUpSurveyModal = ({
  open,
  onClose,
  signUpSurvey,
  signUpUserCallback,
}: SignUpSurveyModalProps) => {
  const formMethods = useForm<SurveyData>();

  const onSubmit = (data: SurveyData) => {
    const surveyAnswers: SurveyAnswer[] = signUpSurvey.map(
      (question, index) => {
        return {
          ...question,
          answer: data.answers[index].toString(),
        };
      }
    );
    onClose();
    signUpUserCallback(surveyAnswers);
  };

  return (
    <BaseModal open={open} onClose={onClose}>
      <Box>
        <Styled.ModalTitleContainer>
          <Typography variant="h2">Sign Up Survey</Typography>
        </Styled.ModalTitleContainer>
        <Typography variant="modalBody">
          The event creator would like to know more about you before you sign up
          for the event. Please take some time to fill out this survey.
        </Typography>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            {signUpSurvey.map((question, index) => {
              return (
                <Box key={index}>
                  <Typography
                    variant="modalBody"
                    sx={{ marginTop: "16px", marginBottom: "8px" }}
                  >
                    {question.question}
                  </Typography>
                  {question.type === "Open response" ? (
                    <Input
                      name={`answers.${index}`}
                      control={formMethods.control}
                      label=""
                      placeholder="Enter answer..."
                      fullWidth
                      removeMarginTop
                    />
                  ) : (
                    <Input
                      name={`answers.${index}`}
                      control={formMethods.control}
                      placeholder="Select answer..."
                      label=""
                      removeMarginTop
                      fullWidth
                      select
                      selectOptions={question.options.map((option) => ({
                        label: option.value,
                        value: option.value,
                      }))}
                    />
                  )}
                </Box>
              );
            })}
            <Box mt={1}>
              <Styled.ConfirmationButton
                variant="contained"
                fullWidth
                size="large"
                type="submit"
              >
                Sign Up
              </Styled.ConfirmationButton>
              <Button
                variant="outlined"
                fullWidth
                size="large"
                onClick={() => {
                  onClose();
                }}
              >
                Close
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </BaseModal>
  );
};

export default SignUpSurveyModal;
