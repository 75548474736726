import { Box, Button, Skeleton, Typography } from "@mui/material";

import GroupIcon from "@mui/icons-material/Group";
import { styled } from "@mui/material/styles";

export const ConfirmationButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

export const DetailsContainer = styled(Box)(({ theme }) => ({
  width: "65%",
  [theme.breakpoints.down("desktop")]: {
    width: "100%",
  },
}));

export const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  margin: `0 -40px`,
  padding: `${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(1)}`,
  boxShadow: `0px -6px 20px -6px ${theme.colors.action.hoverShadow}`,
}));

export const HeroBackground = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  zIndex: -1,
  backgroundSize: "cover",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
}));

export const HeroContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  [theme.breakpoints.up("mobile")]: {
    height: "25vh",
  },
  [theme.breakpoints.up("tablet")]: {
    height: "30vh",
  },
  [theme.breakpoints.up("desktop")]: {
    height: "45vh",
  },
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  zIndex: -0,
  borderRadius: "8px",
  marginBottom: theme.spacing(2),
}));

export const LoaderContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

export const LocationContainer = styled(Box)(({ theme }) => ({
  width: "35%",
  [theme.breakpoints.down("desktop")]: {
    width: "100%",
  },
}));

export const MapContainer = styled("iframe")(({ theme }) => ({
  width: "100%",
  height: "220px",
  border: 0,
  borderRadius: "8px",
  marginBottom: theme.spacing(2),
}));

export const HeroImage = styled("img")(({ theme }) => ({
  position: "relative",
  zIndex: 1,
}));

export const ModalBodyMB2 = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const ModalTitleText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  [theme.breakpoints.up("mobile")]: {
    fontSize: "22px",
    lineHeight: "32px",
  },
  [theme.breakpoints.up("tablet")]: {
    fontSize: "32px",
    lineHeight: "38px",
  },
  [theme.breakpoints.up("desktop")]: {
    fontSize: "32px",
    lineHeight: "38px",
  },
}));

export const SuperScript = styled("sup")(() => ({
  position: "relative",
  fontSize: 10,
  top: 2.3,
}));

export const ScrollableContent = styled(Box)(({ theme }) => ({
  height: "90%",
  overflow: "auto",
  paddingRight: "8px",
  "&::-webkit-scrollbar-track": {
    backgroundColor: theme.colors.neutral.white,
    borderRadius: "6px",
    marginBottom: "12px",
  },
  "&::-webkit-scrollbar": {
    width: "6px",
    WebkitAppearance: "none", // corrected here
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.colors.primary.slalomBlue,
    borderRadius: "6px",
  },
}));

export const SkeletonButton = styled(Skeleton)(({ theme }) => ({
  width: "100%",
  height: "5vh",
}));

export const SkeletonContent = styled(Skeleton)(({ theme }) => ({
  [theme.breakpoints.up("mobile")]: {
    width: "100%",
    height: "35%",
  },
  [theme.breakpoints.up("tablet")]: {
    width: "100%",
    height: "35%",
  },
  [theme.breakpoints.up("desktop")]: {
    width: "50%",
    height: "100%",
  },
}));

export const SkeletonHero = styled(Skeleton)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("mobile")]: {
    height: "25vh",
  },
  [theme.breakpoints.up("tablet")]: {
    height: "30vh",
  },
  [theme.breakpoints.up("desktop")]: {
    height: "35vh",
  },
  marginBottom: theme.spacing(2),
  borderRadius: "8px",
}));

export const SignUpsIcon = styled(GroupIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.colors.secondary.purple,
}));

export const EventActionButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  transition: "opacity 0.3s ease",
  "&:hover": {
    opacity: 0.7,
  },
}));

export const SurveyResultsButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  transition: "opacity 0.3s ease",
  "&:hover": {
    opacity: 0.7,
  },
}));
