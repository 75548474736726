import * as Styled from "./NewEventCreatedModal.styles";

import { Box, Button, Typography } from "@mui/material";

import BaseModal from "../BaseModal/BaseModal";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

type NewEventCreatedModalProps = {
  open: any;
  onClose: any;
  navigateToEvent?: any;
};

const NewEventCreatedModal = ({ open, onClose }: NewEventCreatedModalProps) => {
  const navigate = useNavigate();
  const newEventId = useSelector((state: RootState) => state.newEvent.id);

  return (
    <BaseModal open={open} onClose={onClose}>
      <Box>
        <Styled.ModalTitleContainer>
          <Typography variant="h2">New Event Created!</Typography>
        </Styled.ModalTitleContainer>
        <Typography variant="modalBody">
          Your new event had succesfully been created and published live. This
          event will be viewable by all Slalom employees on the Explore Page. If
          you chose to sign up for this event, it can also be viewed on the My
          Events page.
        </Typography>
        <Styled.ConfirmationButton
          variant="contained"
          fullWidth
          size="large"
          onClick={() => {
            navigate(`/events?eventId=${newEventId}`);
            onClose();
          }}
        >
          View Event
        </Styled.ConfirmationButton>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
      </Box>
    </BaseModal>
  );
};

export default NewEventCreatedModal;
