import * as Styled from "./Accordion.styles";

import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";

type AccordionProps = {
  title?: React.ReactNode;
  children: React.ReactNode;
  summary?: React.ReactNode;
  loading?: boolean;
  rightComponent?: React.ReactNode;
};

const Accordion = ({
  title,
  children,
  loading = false,
  rightComponent,
}: AccordionProps) => {
  return (
    <Styled.Accordion disableGutters>
      <Styled.AccordionSummaryContent hasRightComponent={!!rightComponent}>
        <Styled.AccordionSummary
          disabled={loading}
          expandIcon={<ExpandMoreIcon color="primary" />}
        >
          {title}
        </Styled.AccordionSummary>
        <Box
          sx={{
            display: rightComponent ? "flex" : "none",
            justifyContent: "flex-end",
            flex: 1,
          }}
        >
          {rightComponent}
        </Box>
      </Styled.AccordionSummaryContent>
      <Styled.AccordionDetails>{children}</Styled.AccordionDetails>
    </Styled.Accordion>
  );
};

export default Accordion;
