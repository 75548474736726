import { Box, Button, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DraggableQuestion from "../DraggableQuestion/DraggableQuestion";
import { useFieldArray } from "react-hook-form";

type FormBuilderProps = {
  control: any;
  formName: string;
};

const FormBuilder = ({ control, formName }: FormBuilderProps) => {
  const { fields, append, remove, move } = useFieldArray({
    control,
    name: formName,
  });

  const handleDragEnd = (result: any) => {
    if (!result.destination) return;
    move(result.source.index, result.destination.index);
  };

  return (
    <Box>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId={formName} direction="vertical">
          {(provided) => (
            <Box {...provided.droppableProps} ref={provided.innerRef}>
              {fields.map((field, index) => (
                <Draggable key={field.id} draggableId={field.id} index={index}>
                  {(provided, snapshot) => (
                    <DraggableQuestion
                      formName={formName}
                      index={index}
                      control={control}
                      remove={remove}
                      provided={provided}
                      snapshot={snapshot}
                      field={field}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Button
        startIcon={<AddCircleOutlineIcon />}
        onClick={(event) => {
          event.preventDefault();
          append({ type: "Open response", question: "" });
        }}
      >
        <Typography variant="body1">Add Question</Typography>
      </Button>
    </Box>
  );
};

export default FormBuilder;
