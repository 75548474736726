import { DraftEventState } from "../../../types/redux";
import { createSlice } from "@reduxjs/toolkit";
import { getDraftEvent } from "../../actions/draftEventActions";

const initialState = {
  loading: false,
  error: null,
  success: false,
  data: null,
} as DraftEventState;

const eventSlice = createSlice({
  name: "draft",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDraftEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.data = action.payload.result;
    });
    builder.addCase(getDraftEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDraftEvent.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.data = null;
    });
  },
});

export default eventSlice.reducer;
