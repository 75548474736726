import { Box } from '@mui/material';

import { styled } from '@mui/material/styles';

export const ListContainer = styled(Box)(({ theme }) => ({
  width: 'auto',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(2),
  backgroundColor: theme.colors.neutral.white,
}));
