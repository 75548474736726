import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SiteMaintenanceContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  margin: 16,
  textAlign: "center",
}));

export const WrenchGIF = styled("img")(() => ({
  width: "25vh",
  height: "auto",
}));
