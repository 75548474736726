import { GET_ALL_USER_EVENTS } from "../../services/endpoints";
import axiosAPI from "../../services/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getAllUserEvents = createAsyncThunk(
  "userEvents",
  async (userEmail: string, { rejectWithValue }) => {
    try {
      const { data } = await axiosAPI.request({
        url: GET_ALL_USER_EVENTS(userEmail),
        method: "GET",
      });
      return data;
    } catch (error: any) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
