import * as Styled from "./SearchEventCard.styles";

import { Box, Stack, Typography, useTheme } from "@mui/material";
import {
  getAmOrPm,
  getHourFromMilliseconds,
  getMinutesFromMilliseconds,
  getMonthAndDayFromMilliseconds,
  nthNumber,
} from "../../utils/utils";

import { Event } from "../../types/event";
import { GatherDefaultImage } from "../../assets";
import { memo } from "react";

type SearchEventCardProps = {
  onClick?: any;
} & Event;

const SearchEventCard = memo(
  ({
    title,
    description,
    eventHeroS3Url,
    startDateTime,
    endDateTime,
    signUpCount,
    onClick,
  }: SearchEventCardProps) => {
    const theme = useTheme();

    const currentTime = Date.now();

    const dateFormat = () => {
      const startMinutes = getMinutesFromMilliseconds(startDateTime);
      const endMinutes = getMinutesFromMilliseconds(endDateTime);
      const date = getMonthAndDayFromMilliseconds(startDateTime);
      return (
        <Typography variant="subtitle1">
          {getMonthAndDayFromMilliseconds(startDateTime)}
          {date !== "Today" && (
            <Styled.SuperScript>{nthNumber(startDateTime)}</Styled.SuperScript>
          )}{" "}
          {`${getHourFromMilliseconds(startDateTime)}${
            startMinutes !== "00" ? `:${startMinutes}` : ""
          }`}
          <Styled.SuperScript>{getAmOrPm(startDateTime)}</Styled.SuperScript>
          {" - "}
          {getHourFromMilliseconds(endDateTime)}
          {endMinutes !== "00" ? `:${endMinutes}` : ""}
          <Styled.SuperScript>{getAmOrPm(endDateTime)}</Styled.SuperScript>
        </Typography>
      );
    };

    return (
      <Stack
        direction="row"
        sx={{
          "&:hover": { boxShadow: "0px 6px 20px 0px #0C62FB14" },
          transition: "box-shadow 0.3s ease-in-out",
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          margin: `${theme.spacing(2)} 0`,
          borderRadius: theme.spacing(1),
          height: "150px",
        }}
        onClick={onClick}
      >
        <Styled.ImageContainer>
          <img
            src={eventHeroS3Url || GatherDefaultImage}
            alt={title}
            loading="lazy"
            style={{
              objectFit: "cover",
              width: "100%",
              height: "100%",
              borderRadius: theme.spacing(1),
            }}
          />
        </Styled.ImageContainer>
        <Styled.TextContainer direction="column" justifyContent="space-between">
          <Box display="flex" flexWrap="wrap" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              style={{ marginRight: theme.spacing(1) }}
            >
              {startDateTime * 1000 <= currentTime &&
              currentTime <= endDateTime * 1000 ? (
                <Box
                  sx={{
                    width: 10,
                    height: 10,
                    borderRadius: "50%",
                    backgroundColor: theme.colors.secondary.coralRed,
                    marginRight: theme.spacing(1),
                    marginTop: 0.5,
                  }}
                />
              ) : (
                <Styled.CalendarIcon />
              )}
              <Typography>{dateFormat()}</Typography>
            </Box>
            <Box display="flex" alignItems="center">
              <Styled.SignUpsIcon />
              <Typography variant="subtitle1">{`${signUpCount} Sign Up${
                signUpCount !== 1 ? "s" : ""
              }`}</Typography>
            </Box>
          </Box>
          <Box />
          <Typography
            variant="h3"
            noWrap
            style={{ marginBottom: "4px", wordBreak: "break-word" }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            noWrap
            style={{
              color: theme.colors.neutral.darkGray,
              wordBreak: "break-word",
            }}
          >
            {description}
          </Typography>
        </Styled.TextContainer>
      </Stack>
    );
  }
);

export default SearchEventCard;
