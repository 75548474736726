import * as Styled from "./BaseModal.styles";

import { Box, Fade, ModalProps, useTheme } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { memo } from "react";

type BaseModalProps = {
  desktopWidth?: string | number;
  tabletWidth?: string | number;
  mobileWidth?: string | number;
  fullScreenMobile?: boolean;
  fullScreenTakeover?: boolean;
  height?: string | number;
  showCloseButton?: boolean;
  onClose: () => void;
  overflow?: "auto" | "hidden" | "scroll" | "visible";
  padding?: string;
} & ModalProps;

const BaseModal = ({
  open,
  onClose,
  showCloseButton = true,
  children,
  height,
  fullScreenMobile = false,
  fullScreenTakeover = false,
  desktopWidth = "600px",
  tabletWidth = "66%",
  mobileWidth = "90%",
  overflow,
  padding = "24px 40px",
}: BaseModalProps) => {
  const theme = useTheme();

  const getWidthAndHeight = (
    screenWidth: string | number,
    fullScreen: boolean,
    modalHeight?: string | number
  ) => ({
    width: fullScreen ? "100%" : screenWidth,
    height: fullScreen ? "100%" : modalHeight || "auto",
    maxHeight: fullScreen ? "100%" : "95%",
    borderRadius: fullScreen ? "0px" : "8px",
  });

  const modalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: theme.colors.neutral.white,
    p: padding,
    boxShadow: `0px 6px 20px 0px ${theme.colors.action.hoverShadow}`,
    outline: 0,
    overflow: overflow as "auto" | "hidden" | "scroll" | "visible",
    ...(fullScreenTakeover
      ? getWidthAndHeight("100%", true)
      : {
          ...getWidthAndHeight(mobileWidth, !!fullScreenMobile, height),
          [theme.breakpoints.up("tablet")]: getWidthAndHeight(
            tabletWidth,
            false,
            height
          ),
          [theme.breakpoints.up("desktop")]: getWidthAndHeight(
            desktopWidth,
            false,
            height
          ),
        }),
  };

  return (
    <Styled.Modal
      open={open}
      onClose={onClose}
      slotProps={{
        backdrop: {
          style: { backgroundColor: theme.colors.action.backdrop },
        },
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyles}>
          {showCloseButton && (
            <Styled.CloseModalButton
              onClick={onClose}
              sx={{
                marginTop: fullScreenTakeover
                  ? theme.spacing(1)
                  : theme.spacing(-2),
              }}
            >
              <CloseIcon />
            </Styled.CloseModalButton>
          )}
          {children}
        </Box>
      </Fade>
    </Styled.Modal>
  );
};

export default memo(BaseModal);
