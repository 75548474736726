import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Controller } from "react-hook-form";
import dayjs from "dayjs";
import { useTheme } from "@mui/material";

type DatePickerInputProps = {
  label: string;
  name: string;
  control: any;
  required?: boolean;
};

const DatePickerInput = ({
  name,
  control,
  label,
  required = false,
}: DatePickerInputProps) => {
  const theme = useTheme();
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: `${label} is required *` },
        validate: (value) =>
          !dayjs(value).isBefore(dayjs(), "day") ||
          `${label} cannot be before today`,
      }}
      render={({ field, fieldState: { error } }) => (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            {...field}
            minDate={dayjs()} // Disallow dates before today
            label={label}
            sx={{
              width: "100%",
              [theme.breakpoints.up("desktop")]: {
                width: "33%",
              },
            }}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error?.message,
              },
            }}
          />
        </LocalizationProvider>
      )}
    />
  );
};

export default DatePickerInput;
