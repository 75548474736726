import * as Styled from "./FAQPage.styles";

import { PageContainer } from "../../components";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ManageEventsPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.open(
      "https://twodegrees1.sharepoint.com/:u:/r/sites/officeboston/SitePages/Gather-App-Guide--Overview,-FAQs-and-Helpful-Resources.aspx?csf=1&web=1&e=SKaWIJ",
      "_blank",
      "noopener,noreferrer"
    );

    navigate("/events");
  }, [navigate]);

  return (
    <PageContainer>
      <Styled.PaddedContainer>
        <Styled.HeaderStack
          direction={{ mobile: "column", tablet: "row", desktop: "row" }}
          width="100%"
          spacing={3}
        >
          <Styled.HeaderText variant="h1" gutterBottom>
            FAQ
          </Styled.HeaderText>
        </Styled.HeaderStack>
      </Styled.PaddedContainer>
    </PageContainer>
  );
};

export default ManageEventsPage;
