import { Box, Button, Stack, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

export const FormSectionContainer = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.colors.neutral.softGray}`,
  borderRadius: "8px",
  [theme.breakpoints.up("mobile")]: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  [theme.breakpoints.up("tablet")]: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  [theme.breakpoints.up("desktop")]: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
}));

export const LoaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  marginTop: theme.spacing(3),
}));

export const PageLoaderContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("desktop")]: {
    width: "100%",
  },
}));

export const HeaderStack = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const InputStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const PaddedContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

export const FormActionStack = styled(Stack)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const SignUpCheckboxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row-reverse",
  width: "50%",
  [theme.breakpoints.down("desktop")]: {
    width: "100%",
    flexDirection: "row",
  },
}));

export const ActionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("desktop")]: {
    width: "100%",
  },
}));

export const ErrorMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  fontSize: theme.typography.modalBody.fontSize,
  color: theme.colors.action.failure,
}));
