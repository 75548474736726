import { Avatar, Box, Switch, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

export const MenuContainer = styled(Box)(({ theme }) => ({
  width: "auto",
  height: "auto",
  display: "flex",
  flexDirection: "column",
  padding: theme.spacing(5, 3),
  backgroundColor: theme.colors.neutral.white,
  [theme.breakpoints.up("desktop")]: {
    padding: theme.spacing(10, 5, 7),
  },
}));

export const UserContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingBottom: theme.spacing(5),
  gap: theme.spacing(2),
  alignSelf: "stretch",
  borderBottom: "1px solid " + theme.colors.neutral.lightGray,
}));

export const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7.5),
  height: theme.spacing(7.5),
  flexShrink: 0,
}));

export const UserInfoContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: theme.spacing(1),
}));

export const UserName = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  fontWeight: theme.typography.fontWeightBold,
  color: theme.colors.neutral.black,
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.colors.neutral.darkGray,
}));

export const SettingsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  alignSelf: "stretch",
  marginTop: theme.spacing(5),
  [theme.breakpoints.down("desktop")]: {
    marginBottom: theme.spacing(2),
  },
}));

export const SettingsText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.subtitle1.fontSize,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.colors.neutral.black,
  display: "inline-flex",
}));

export const SettingsSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-thumb": {
    boxShadow: `0px 6px 20px 0px ${theme.colors.action.hoverShadow}`,
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.colors.neutral.white,
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.colors.secondary.darkPurple,
  },
  "& .MuiSwitch-switchBase": {
    color: theme.colors.neutral.white,
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: theme.colors.neutral.softGray,
  },
  "& .MuiSwitch-switchBase:hover": {
    backgroundColor: theme.colors.action.hoverShadow,
  },
}));
