import * as Styled from "./EventsPage.styles";

import {
  APIError,
  EventCard,
  PageContainer,
  RefreshEventsIconButton,
  ScrollToTopFAB,
} from "../../components";
import { Alert, Box, Grid, IconButton, useTheme } from "@mui/material";
import {
  EventDetailsModal,
  EventEditedModal,
  NewEventCreatedModal,
} from "../../modals";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { CLEAR_NEW_EVENT_CREATED } from "../../store/actions/newEventActions";
import { Event } from "../../types/event";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { RootState } from "../../store/store";
import { clearEvent } from "../../store/reducers/EditEventReducer/EditEventReducer";
import { createSelector } from "reselect";
import { getAllEvents } from "../../store/actions/eventsActions";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import { getUserInfo } from "../../store/actions/userActions";
import { isCuid } from "cuid";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";

const EventsPage = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [alert, setAlert] = useState<any>(null);
  const [alertVisible, setAlertVisible] = useState(false);

  const isNavigating = useRef(false);

  useEffect(() => {
    if (location.state?.alert && !isNavigating.current) {
      setAlert(location.state.alert);
      setAlertVisible(true);
      isNavigating.current = true;
      setTimeout(() => {
        navigate(location.pathname, { replace: true, state: {} });

        isNavigating.current = false;
      }, 10000);
    } else {
      setAlert(null);
      setAlertVisible(false);
    }
  }, [location.state, navigate, location.pathname]);

  const queryParams = new URLSearchParams(location.search);
  const [searchParams] = useSearchParams();
  const eventIdFromQueryParam = queryParams.get("eventId");

  const [showAll, setShowAll] = useState(false);

  const handleClick = () => {
    setShowAll(!showAll);
  };

  // Split up useSelector calls
  const newEventState = useSelector((state: RootState) => state.newEvent);
  const editEventState = useSelector((state: RootState) => state.edit);
  const userEmail = useSelector((state: RootState) => state.user.email);
  const userName = useSelector((state: RootState) => state.user.name);
  const allEvents = useSelector((state: RootState) => state.events.data);
  const allEventsSuccess = useSelector(
    (state: RootState) => state.events.success
  );
  const allEventsError = useSelector((state: RootState) => state.events.error);
  const selectUserSignedUpEvents = (state: RootState) =>
    state.userEvents.signedUp;

  const selectFutureUserSignedUpEvents = createSelector(
    [selectUserSignedUpEvents],
    (userEvents) =>
      userEvents.filter(
        (event) => event.endDateTime > Math.floor(Date.now() / 1000)
      )
  );

  const userSignedUpEvents = useSelector(selectFutureUserSignedUpEvents);
  const userEventsSuccess = useSelector(
    (state: RootState) => state.userEvents.success
  );
  const userEventsError = useSelector(
    (state: RootState) => state.userEvents.error
  );

  // Use useMemo to memoize displayUpcomingElements
  const displayUpcomingElements = useMemo(() => {
    return showAll ? userSignedUpEvents : userSignedUpEvents.slice(0, 3);
  }, [showAll, userSignedUpEvents]);

  const [eventDetailsId, setEventDetailsId] = useState("");
  const [showEventDetailsModal, setShowEventDetailsModal] = useState(false);
  const [showNewEventCreatedModal, setShowNewEventCreatedModal] =
    useState(false);
  const [showEventEditedModal, setShowEventEditedModal] = useState(false);
  useEffect(() => {
    if (eventIdFromQueryParam && isCuid(eventIdFromQueryParam)) {
      setEventDetailsId(eventIdFromQueryParam);
      setShowEventDetailsModal(true);
    }
  }, [eventIdFromQueryParam]);

  useEffect(() => {
    if (!searchParams.has("eventId")) {
      setShowEventDetailsModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    setShowNewEventCreatedModal(newEventState.eventCreated);
  }, [newEventState.eventCreated]);

  useEffect(() => {
    setShowEventEditedModal(editEventState.eventEdited);
  }, [editEventState.eventEdited]);

  const handleCloseEventDetailsModal = useCallback(() => {
    navigate("/events", { replace: true });
    setShowEventDetailsModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseNewEventModal = useCallback(() => {
    dispatch({ type: CLEAR_NEW_EVENT_CREATED });
    setShowNewEventCreatedModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseEventEditedModal = useCallback(() => {
    dispatch(clearEvent());
    setShowEventEditedModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <NewEventCreatedModal
        open={showNewEventCreatedModal}
        onClose={handleCloseNewEventModal}
      />
      <EventEditedModal
        open={showEventEditedModal}
        onClose={handleCloseEventEditedModal}
      />
      <EventDetailsModal
        eventId={eventDetailsId}
        open={showEventDetailsModal}
        onClose={handleCloseEventDetailsModal}
      />
      <ScrollToTopFAB />
      <Styled.PaddedContainer>
        {alertVisible && (
          <Alert
            severity={alert.severity}
            sx={{ marginBottom: theme.spacing(2) }}
            onClose={() => {
              setAlert(null);
              setAlertVisible(false);
            }}
          >
            {alert.message}
          </Alert>
        )}
        <Styled.HeaderStack direction="row" width="100%">
          <Styled.HeaderText variant="h1">Hello, {userName} </Styled.HeaderText>
          <Styled.RefreshEventsContainer>
            <RefreshEventsIconButton />
          </Styled.RefreshEventsContainer>
        </Styled.HeaderStack>
        {userEventsSuccess ? (
          <Styled.SubtitleContainer>
            <Styled.SubtitleStackContainer
              direction={{ mobile: "column", tablet: "row", desktop: "row" }}
              spacing={2}
            >
              <Styled.SubtitleTitle variant="h3">
                {displayUpcomingElements.length === 0
                  ? "No Upcoming Events"
                  : `My Upcoming Events (${displayUpcomingElements.length})`}
              </Styled.SubtitleTitle>
              <Styled.SubtitleActionContainer>
                <Styled.SubtitleActionText
                  variant="modalSubtitle"
                  onClick={() => {
                    navigate("/events/past");
                  }}
                >
                  View my past events
                </Styled.SubtitleActionText>
              </Styled.SubtitleActionContainer>
            </Styled.SubtitleStackContainer>
            <Styled.SubtitleText variant="body2">
              {displayUpcomingElements.length === 0
                ? "There are no upcoming events that you’ve signed up to attend. Select an event to view additional details and to adjust your RSVP response."
                : "Here are all of the upcoming events that you’ve signed up to attend. Select an event to view additional details and to adjust your RSVP response."}
            </Styled.SubtitleText>
          </Styled.SubtitleContainer>
        ) : (
          <Styled.SubtitleContainerSkeleton />
        )}
        {/* UPCOMING EVENTS GRID */}
        {userEventsError ? (
          <APIError
            onClick={() => {
              dispatch(getUserInfo());
              dispatch(getAllUserEvents(userEmail));
            }}
          />
        ) : (
          <Styled.ScrollContainer>
            <Grid
              container
              spacing={3}
              columns={{
                mobile: 4,
                tablet: 8,
                desktop: 12,
              }}
            >
              {!userEventsSuccess ? (
                Array.from({ length: 3 }, (_, index) => (
                  <Grid key={index} item mobile={4} tablet={4} desktop={4}>
                    <Styled.SkeletonCardContainer>
                      <Styled.SkeletonCardImage
                        variant="rectangular"
                        animation="wave"
                      />
                      <Styled.SkeletonCardText
                        variant="text"
                        animation="wave"
                      />
                      <Styled.SkeletonCardDescription
                        variant="rectangular"
                        animation="wave"
                      />
                    </Styled.SkeletonCardContainer>
                  </Grid>
                ))
              ) : (
                <>
                  {displayUpcomingElements.map((event: Event) => (
                    <Grid
                      key={event.eventId}
                      item
                      mobile={4}
                      tablet={4}
                      desktop={4}
                    >
                      <EventCard
                        {...event}
                        onClick={() => {
                          navigate(
                            `${location.pathname}?eventId=${event.eventId}`,
                            { replace: true }
                          );
                          setEventDetailsId(event.eventId);
                          setShowEventDetailsModal(true);
                        }}
                      />
                    </Grid>
                  ))}
                  {userSignedUpEvents.length > 3 && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginTop: theme.spacing(1),
                      }}
                    >
                      <IconButton onClick={handleClick}>
                        {showAll ? (
                          <KeyboardArrowUpOutlinedIcon color="primary" />
                        ) : (
                          <KeyboardArrowDownOutlinedIcon color="primary" />
                        )}
                      </IconButton>
                    </Box>
                  )}
                </>
              )}
            </Grid>
          </Styled.ScrollContainer>
        )}
        {/* ALL EVENTS GRID */}
        <Styled.AllEventsText
          variant="h2"
          style={{
            marginTop:
              displayUpcomingElements.length < 3 ? theme.spacing(2) : 0,
          }}
        >
          All Events
        </Styled.AllEventsText>
        {allEventsError ? (
          <APIError
            onClick={() => {
              dispatch(getUserInfo());
              dispatch(getAllEvents());
            }}
          />
        ) : (
          <Styled.ScrollContainer>
            <Grid
              container
              spacing={3}
              columns={{
                mobile: 4,
                tablet: 8,
                desktop: 12,
              }}
            >
              {!allEventsSuccess ? (
                Array.from({ length: 3 }, (_, index) => (
                  <Grid key={index} item mobile={4} tablet={4} desktop={4}>
                    <Styled.SkeletonCardContainer>
                      <Styled.SkeletonCardImage
                        variant="rectangular"
                        animation="wave"
                      />
                      <Styled.SkeletonCardText
                        variant="text"
                        animation="wave"
                      />
                      <Styled.SkeletonCardDescription
                        variant="rectangular"
                        animation="wave"
                      />
                    </Styled.SkeletonCardContainer>
                  </Grid>
                ))
              ) : allEvents.length === 0 ? (
                <Styled.NoUpcomingEventsContainer>
                  <Box>
                    <Styled.CalendarIcon />
                    <Styled.NoUpcomingEventsText variant="h1">
                      No Upcoming Events
                    </Styled.NoUpcomingEventsText>
                    <Styled.NoUpcomingEventsText>
                      There are no upcoming events scheduled. Check back later!
                    </Styled.NoUpcomingEventsText>
                  </Box>
                </Styled.NoUpcomingEventsContainer>
              ) : (
                allEvents.map((event: Event) => (
                  <Grid
                    key={event.eventId}
                    item
                    mobile={4}
                    tablet={4}
                    desktop={4}
                  >
                    <EventCard
                      {...event}
                      onClick={() => {
                        navigate(
                          `${location.pathname}?eventId=${event.eventId}`,
                          { replace: true }
                        );
                      }}
                    />
                  </Grid>
                ))
              )}
            </Grid>
          </Styled.ScrollContainer>
        )}
      </Styled.PaddedContainer>
    </PageContainer>
  );
};

export default EventsPage;
