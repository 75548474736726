import {
  Box,
  Button,
  InputAdornment,
  TextField,
  TextFieldProps,
  useTheme,
} from "@mui/material";
import { Controller, useFieldArray } from "react-hook-form";

import Chip from "../Chip/Chip";
import { useState } from "react";

type ChipListInputProps = {
  name: string;
  control: any;
  required?: boolean;
};

const ChipListInput = ({
  name,
  control,
  label,
  required = false,
  placeholder,
  variant = "outlined",
  fullWidth = false,
  sx,
  autoComplete = "off",
}: ChipListInputProps & TextFieldProps) => {
  const theme = useTheme();
  const { append, remove, replace } = useFieldArray({
    control,
    name: name,
  });
  const [inputValue, setInputValue] = useState("");
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: { value: required, message: `${label} is required *` },
      }}
      render={({ field: { value }, fieldState: { error } }) => (
        <Box>
          <TextField
            value={inputValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInputValue(event.target.value);
            }}
            helperText={error?.root?.message}
            error={!!error}
            fullWidth={fullWidth}
            label={label}
            variant={variant}
            autoComplete={autoComplete}
            placeholder={placeholder}
            InputProps={{
              endAdornment: value.length > 0 && (
                <InputAdornment position="end">
                  <Button
                    onClick={() => {
                      replace([]);
                    }}
                  >
                    Clear All
                  </Button>
                </InputAdornment>
              ),
              style: { color: theme.colors.neutral.black },
            }}
            sx={{
              ...sx,
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(2),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                if (value.indexOf(inputValue) <= -1 && inputValue.length > 0) {
                  append(inputValue);
                  setInputValue("");
                }
              }
            }}
          />
          {value.map((val: string) => {
            return (
              <Chip
                key={val}
                label={val}
                onDelete={() => {
                  remove(value.indexOf(val));
                }}
              />
            );
          })}
        </Box>
      )}
    />
  );
};

export default ChipListInput;
