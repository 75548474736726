import { Fab } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ScrollToTopFab = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: 30,
  left: "50%",
  marginLeft: "-52.55px",
  boxShadow: `0px 6px 6px 0px ${theme.colors.action.hoverShadow}`,
}));
