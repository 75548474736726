import * as Styled from "./APIError.styles";

import { Box, Button } from "@mui/material";

import { Gear } from "../../assets";

type APIErrorProps = {
  onClick?: any;
};

const APIError = ({ onClick }: APIErrorProps) => {
  return (
    <Styled.APIErrorContainer>
      <Box>
        <Styled.GearGIF src={Gear} alt={"gear-gif"} />
        <Styled.Typography variant="h1">Whoops!</Styled.Typography>
        <Styled.Typography>
          Something went wrong. Try refreshing the page or check back later.
        </Styled.Typography>
        {onClick && (
          <Button variant="contained" fullWidth onClick={onClick}>
            Refresh
          </Button>
        )}
      </Box>
    </Styled.APIErrorContainer>
  );
};

export default APIError;
