import { Box, Skeleton, Typography } from "@mui/material";

import GroupIcon from "@mui/icons-material/Group";
import { styled } from "@mui/material/styles";

export const PaddedContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

export const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  boxShadow: `0px 6px 20px 0px ${theme.colors.action.hoverShadow}`,
}));

export const DetailsContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("desktop")]: {
    width: "100%",
  },
}));

export const HeroBackground = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  zIndex: -1,
  backgroundSize: "cover",
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
}));

export const HeroContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  width: "100%",
  [theme.breakpoints.up("mobile")]: {
    height: "25vh",
  },
  [theme.breakpoints.up("tablet")]: {
    height: "30vh",
  },
  [theme.breakpoints.up("desktop")]: {
    height: "35vh",
  },
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  zIndex: -0,
  borderRadius: "8px",
  marginBottom: theme.spacing(4),
}));

export const LocationContainer = styled(Box)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("desktop")]: {
    width: "100%",
  },
}));

export const MapContainer = styled("iframe")(({ theme }) => ({
  width: "100%",
  height: "220px",
  border: 0,
  borderRadius: "8px",
  marginBottom: theme.spacing(2),
}));

export const HeroImage = styled("img")(({ theme }) => ({
  position: "relative",
  zIndex: 1,
}));

export const ModalBodyMB2 = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const SuperScript = styled("sup")(() => ({
  position: "relative",
  fontSize: 10,
  top: 2.3,
}));

export const SkeletonContent = styled(Skeleton)(({ theme }) => ({
  [theme.breakpoints.up("mobile")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.up("tablet")]: {
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.up("desktop")]: {
    width: "50%",
    height: "100%",
  },
}));

export const SignUpsIcon = styled(GroupIcon)(({ theme }) => ({
  marginRight: theme.spacing(1),
  color: theme.colors.secondary.purple,
}));
