import { Box, Stack, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

export const IndicatorContainer = styled(Stack)(({ theme }) => ({
  display: "flex",
  width: "100%",
  backgroundColor: theme.colors.neutral.lightGray,
  padding: `${theme.spacing(2)}`,
}));

export const LoaderContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "48px",
  padding: "0 24px",
}));

export const IndicatorButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "25%",
  justifyContent: "flex-end",
}));

export const IndicatorLinkContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  marginLeft: theme.spacing(1),
}));

export const IndicatorLink = styled(Typography)(({ theme }) => ({
  color: theme.colors.primary.slalomBlue,
  textDecoration: "underline",
  cursor: "pointer",
  transition: "opacity 0.3s ease-in-out",
  "&:hover": {
    textDecoration: "underline",
    opacity: 0.7,
  },
}));

export const SuperScript = styled("sup")(() => ({
  position: "relative",
  fontSize: 10,
  top: 2.3,
}));
