import * as Styled from "./ToggleButton.styles";

import { Stack, Typography, useMediaQuery } from "@mui/material";

type ToggleButtonProps = {
  option: number;
  setOption: React.Dispatch<React.SetStateAction<number>>;
  options: Array<string>;
  spacing?: number;
  height?: string;
  padding?: string;
  labelVariant?: any;
  onClick: (index: number) => void;
};

const ToggleButton = ({
  option,
  options,
  spacing = 8,
  height = "40px",
  padding = "0px 24px",
  labelVariant = "modalSubtitle",
  onClick,
}: ToggleButtonProps) => {
  const isDesktop = useMediaQuery((theme: any) =>
    theme.breakpoints.up("desktop")
  );
  return (
    <Stack direction="row">
      {options.map((label: string, index: number) => {
        return (
          <Styled.ToggleButtonOption
            key={index}
            variant={"text"}
            onClick={() => onClick(index)}
            index={index}
            option={option}
            optionslength={options.length}
            spacing={spacing}
            fullWidth={!isDesktop}
            height={height}
            padding={padding}
          >
            <Typography variant={labelVariant}>{label}</Typography>
          </Styled.ToggleButtonOption>
        );
      })}
    </Stack>
  );
};

export default ToggleButton;
