import {
  getEvent,
  getEventSignups,
  refreshEvent,
  refreshEventSignups,
} from "../../actions/eventActions";

import { EventState } from "../../../types/redux";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  refreshing: false,
  error: null,
  success: false,
  data: null,
  signups: {
    refreshing: false,
    loading: false,
    error: null,
    success: false,
    data: [],
  },
} as EventState;

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvent.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.data = action.payload.result;
    });
    builder.addCase(getEvent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEvent.rejected, (state, action) => {
      state.loading = false;
      state.success = false;
      state.error = action.payload;
      state.data = null;
    });
    builder.addCase(getEventSignups.fulfilled, (state, action) => {
      state.signups.loading = false;
      state.signups.success = true;
      state.signups.error = null;
      state.signups.data = action.payload.result;
    });
    builder.addCase(getEventSignups.pending, (state) => {
      state.signups.loading = true;
    });
    builder.addCase(getEventSignups.rejected, (state, action) => {
      state.signups.loading = false;
      state.signups.success = false;
      state.signups.error = action.payload;
      state.signups.data = [];
    });
    builder.addCase(refreshEvent.pending, (state) => {
      state.refreshing = true;
    });
    builder.addCase(refreshEvent.fulfilled, (state, action) => {
      state.refreshing = false;
      if (
        JSON.stringify(state.data) !== JSON.stringify(action.payload.result)
      ) {
        state.data = action.payload.result;
      }
    });
    builder.addCase(refreshEvent.rejected, (state, action) => {
      state.refreshing = false;
      state.error = action.payload;
    });
    builder.addCase(refreshEventSignups.pending, (state) => {
      state.signups.refreshing = true;
    });
    builder.addCase(refreshEventSignups.fulfilled, (state, action) => {
      state.signups.refreshing = false;
      if (
        JSON.stringify(state.signups.data) !==
        JSON.stringify(action.payload.result)
      ) {
        state.signups.data = action.payload.result;
      }
    });
    builder.addCase(refreshEventSignups.rejected, (state, action) => {
      state.signups.refreshing = false;
      state.signups.error = action.payload;
    });
  },
});

export default eventSlice.reducer;
