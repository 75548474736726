import * as Styled from "./NavigationMenu.styles";

import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

const NavigationMenu = ({ pages, toggleDrawer }: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();

  return (
    <Styled.ListContainer>
      <List disablePadding>
        {pages?.map((page: any, index: any) => (
          <ListItem key={index} disablePadding>
            <ListItemButton
              disableRipple
              onClick={() => {
                navigate(page.path);
                toggleDrawer?.();
              }}
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                },
                "&.Mui-focusVisible": {
                  backgroundColor: "transparent",
                },
                "&.Mui-selected": {
                  backgroundColor: "transparent",
                },
              }}
            >
              <ListItemText>
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: location.pathname === page.path ? 700 : 500,
                    display: "inline-block",
                    color: "black",
                    backgroundColor:
                      location.pathname === page.path
                        ? theme.colors.secondary.chartreuse
                        : "transparent",
                    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
                    borderRadius: theme.spacing(2),
                  }}
                >
                  {page.label}
                </Typography>
              </ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Styled.ListContainer>
  );
};

export default NavigationMenu;
