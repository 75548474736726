import * as Styled from "./EventOpsConsentModal.styles";

import { Box, Link, Stack, Typography, useTheme } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";

import BaseModal from "../BaseModal/BaseModal";
import { Checkbox } from "../../components";

type EventOpsConsentModalProps = {
  open: any;
  onClose: any;
  onConfirm: any;
};

type EventOpsConsentForm = {
  opsFormSubmitted: boolean;
};

const EventOpsConsentModal = ({
  open,
  onClose,
  onConfirm,
}: EventOpsConsentModalProps) => {
  const formMethods = useForm({
    defaultValues: {
      opsFormSubmitted: false,
    },
    mode: "onChange",
  });
  const theme = useTheme();

  const isFormSubmitted = formMethods.watch("opsFormSubmitted");

  const handleClose = () => {
    formMethods.reset(); // Reset the form state when the modal closes
    onClose();
  };

  const onSubmit = (data: EventOpsConsentForm) => {
    if (data.opsFormSubmitted) {
      handleClose();
      onConfirm();
    }
  };

  return (
    <BaseModal open={open} onClose={handleClose} showCloseButton={false}>
      <Box style={{ textAlign: "center" }}>
        <Styled.ModalTitleContainer>
          <Typography variant="h2">Before publishing your event</Typography>
        </Styled.ModalTitleContainer>
        <Typography
          variant="body2"
          style={{
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
          align="left"
        >
          Publishing this event in Gather will make it visible to everyone in
          the market. Before proceeding, please ensure you have submitted the{" "}
          <Link
            href="https://twodegrees1.sharepoint.com/sites/officeboston/SitePages/Ops-Support-Requests.aspx?OR=Teams-HL&CT=1649179007607&params=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMjAzMDcwMTYxMCJ9"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ops Event Request Form
          </Link>
          . If you've already submitted it and haven't heard back, please follow
          up with our operations team to confirm its status.
        </Typography>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(onSubmit)}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              mb={2}
            >
              <Checkbox
                name="opsFormSubmitted"
                control={formMethods.control}
                required
                defaultChecked={false}
              />
              <Typography variant="body2" ml={1} align="left">
                I confirm that I have submitted the{" "}
                <Link
                  href="https://twodegrees1.sharepoint.com/sites/officeboston/SitePages/Ops-Support-Requests.aspx?OR=Teams-HL&CT=1649179007607&params=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyOC8yMjAzMDcwMTYxMCJ9"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ops Event Request Form
                </Link>{" "}
                and understand that this event will be visible to the entire
                office upon publication.
              </Typography>
            </Box>
            <Stack
              direction={{ mobile: "column", tablet: "row", desktop: "row" }}
              spacing={2}
              width="100%"
            >
              <Styled.ConfirmButton
                variant="contained"
                fullWidth
                size="large"
                type="submit"
                disabled={!isFormSubmitted}
              >
                All set, let's go!
              </Styled.ConfirmButton>
              <Styled.ConfirmButton
                variant="outlined"
                fullWidth
                size="large"
                onClick={handleClose}
              >
                Go Back
              </Styled.ConfirmButton>
            </Stack>
          </form>
        </FormProvider>
      </Box>
    </BaseModal>
  );
};

export default EventOpsConsentModal;
