import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
  useTheme,
} from "@mui/material";

import { Controller } from "react-hook-form";

type CheckboxProps = {
  name: string;
  control: any;
  required?: boolean;
} & MUICheckboxProps;

const Checkbox = ({
  name,
  control,
  required = false,
  defaultChecked,
}: CheckboxProps) => {
  const theme = useTheme();
  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required }}
      render={({ field: { onChange, value } }) => (
        <MUICheckbox
          onChange={onChange}
          value={value}
          sx={{
            color: theme.colors.neutral.black,
          }}
          defaultChecked={defaultChecked}
        ></MUICheckbox>
      )}
    />
  );
};

export default Checkbox;
