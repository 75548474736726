import { authenticateUser, refreshToken } from "../../actions/authActions";

import { AuthState } from "../../../types/redux";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  success: false,
  accessToken: "",
  refreshToken: "",
  isAuthorized: false,
} as AuthState;

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(authenticateUser.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.accessToken = action.payload.access_token;
      state.refreshToken = action.payload.refresh_token;
      state.isAuthorized = true;
    });
    builder.addCase(authenticateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(authenticateUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
      state.accessToken = "";
      state.refreshToken = "";
      state.isAuthorized = false;
    });
    builder.addCase(refreshToken.fulfilled, (state, action) => {
      state.loading = false;
      state.success = true;
      state.error = null;
      state.accessToken = action.payload.access_token;
      state.isAuthorized = true;
    });
    builder.addCase(refreshToken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(refreshToken.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
      state.accessToken = "";
      state.refreshToken = "";
      state.isAuthorized = false;
    });
  },
});

export default userSlice.reducer;
