import { Box, CircularProgress, IconButton } from "@mui/material";

import { EventsState } from "../../types/redux";
import RefreshIcon from "@mui/icons-material/Refresh";
import { RootState } from "../../store/store";
import { getAllEvents } from "../../store/actions/eventsActions";
import { getAllUserEvents } from "../../store/actions/userEventsActions";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";

const RefreshEventsIconButton = () => {
  const eventsState: EventsState = useSelector((state: any) => state.events);
  const dispatch = useAppDispatch();
  const userEmail = useSelector((state: RootState) => state.user.email);
  return (
    <>
      {eventsState.loading ? (
        <>
          <Box style={{ margin: "8px" }}>
            <CircularProgress size={"24px"} />
          </Box>
        </>
      ) : (
        <IconButton
          onClick={() => {
            dispatch(getAllEvents());
            dispatch(getAllUserEvents(userEmail));
          }}
        >
          <RefreshIcon />
        </IconButton>
      )}
    </>
  );
};

export default RefreshEventsIconButton;
