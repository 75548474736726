import { Controller, useFormContext } from "react-hook-form";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { useCallback, useEffect } from "react";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useTheme } from "@mui/material";

type TimeRangePickerProps = {
  startName: string;
  endName: string;
  control: any;
  required?: boolean;
};

const TimeRangePicker = ({
  startName,
  endName,
  control,
  required = true,
}: TimeRangePickerProps) => {
  const theme = useTheme();
  const { setValue, getValues, watch } = useFormContext();

  const eventDate = getValues("eventDate") || dayjs().startOf("day");

  const roundToNearest30 = (value: Dayjs) =>
    value.minute(Math.round(value.minute() / 30) * 30).second(0);

  const getStartTime = (value: Dayjs) =>
    roundToNearest30(
      dayjs(eventDate).hour(value.hour()).minute(value.minute())
    );

  const handleTimeAdjustment = useCallback(() => {
    const startDateTime = dayjs(getValues(startName));
    const endDateTime = dayjs(getValues(endName));

    // If the start time is after or equal to the end time, adjust the end time
    if (!startDateTime.isBefore(endDateTime)) {
      setValue(endName, startDateTime.add(30, "minute"), {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
  }, [getValues, setValue, startName, endName]);

  useEffect(() => {
    // Watch for changes in startDateTime and endDateTime to adjust times accordingly
    const subscription = watch(handleTimeAdjustment);
    return () => subscription.unsubscribe();
  }, [watch, handleTimeAdjustment]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={startName}
        control={control}
        rules={{
          required: {
            value: required,
            message: "Please select a start time *",
          },
          validate: {
            isBeforeEndTime: (value) =>
              dayjs(value).isBefore(dayjs(getValues(endName))) ||
              "Start time must be before end time",
            validMinutes: (value) => {
              const minutes = dayjs(value).minute();
              return (
                [0, 15, 30, 45].includes(minutes) ||
                "Minute must be 00, 15, 30, or 45"
              );
            },
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TimePicker
            {...field}
            label="Start Time"
            ampm={true}
            onAccept={(value) =>
              setValue(startName, getStartTime(dayjs(value)), {
                shouldValidate: true,
                shouldDirty: true,
              })
            }
            sx={{
              width: "100%",
              [theme.breakpoints.up("desktop")]: {
                width: "33%",
              },
            }}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error?.message,
                inputProps: {
                  "aria-invalid": !!error,
                  "aria-label": "Start Time",
                },
              },
            }}
          />
        )}
      />
      <Controller
        name={endName}
        control={control}
        rules={{
          required: {
            value: required,
            message: "Please select an end time *",
          },
          validate: {
            isAfterStartTime: (value) =>
              dayjs(value).isAfter(dayjs(getValues(startName))) ||
              "End time must be after start time",
            validMinutes: (value) => {
              const minutes = dayjs(value).minute();
              return (
                [0, 15, 30, 45].includes(minutes) ||
                "Minute must be 00, 15, 30, or 45"
              );
            },
          },
        }}
        render={({ field, fieldState: { error } }) => (
          <TimePicker
            {...field}
            label="End Time"
            ampm={true}
            onAccept={(value) =>
              setValue(endName, getStartTime(dayjs(value)), {
                shouldValidate: true,
                shouldDirty: true,
              })
            }
            sx={{
              width: "100%",
              [theme.breakpoints.up("desktop")]: {
                width: "33%",
              },
            }}
            slotProps={{
              textField: {
                error: !!error,
                helperText: error?.message,
                inputProps: {
                  "aria-invalid": !!error,
                  "aria-label": "End Time",
                },
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default TimeRangePicker;
