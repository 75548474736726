import { Box, Typography as MUITypography } from "@mui/material";

import { styled } from "@mui/material/styles";

export const APIErrorContainer = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
  margin: 16,
  textAlign: "center",
}));

export const GearGIF = styled("img")(() => ({
  width: "25vh",
  height: "auto",
}));

export const Typography = styled(MUITypography)(() => ({
  marginBottom: "32px",
}));
