import * as Styled from "./ShareEventModal.styles";

import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import { useRef, useState } from "react";

import BaseModal from "../BaseModal/BaseModal";
import { CopyToClipboardButton } from "../../components";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import DownloadIcon from "@mui/icons-material/Download";
import DownloadingIcon from "@mui/icons-material/Downloading";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import logo from "../../assets/images/QR-code-logo.png";
import { saveAs } from "file-saver";

type ShareEventModalProps = {
  open: any;
  onClose: any;
  navigateToEvent?: any;
  link: string;
};

const ShareEventModal = ({ open, onClose, link }: ShareEventModalProps) => {
  const theme = useTheme();
  const qrCodeRef = useRef(null);

  const [downloadStatus, setDownloadStatus] = useState<
    "not downloaded" | "downloading" | "downloaded" | "failed"
  >("not downloaded");

  const handleDownloadClick = () => {
    setDownloadStatus("downloading");
    if (qrCodeRef.current) {
      html2canvas(qrCodeRef.current)
        .then((canvas) => {
          canvas.toBlob((blob) => {
            if (blob) {
              setDownloadStatus("downloaded");
              saveAs(blob, `gather-qr-code-${link.split("=")[1]}.png`);
            } else {
              setDownloadStatus("failed");
            }
          });
        })
        .catch((error) => {
          setDownloadStatus("failed");
          console.error("oops, something went wrong!", error);
        });
    } else {
      setDownloadStatus("failed");
    }
  };

  return (
    <BaseModal
      open={open}
      onClose={() => {
        setDownloadStatus("not downloaded");
        onClose();
      }}
      overflow="auto"
    >
      <Box
        sx={{
          maxHeight: "95vh",
        }}
      >
        <Styled.ModalTitleContainer>
          <Typography variant="h2">
            How would you like to share this event?
          </Typography>
        </Styled.ModalTitleContainer>
        <Stack
          direction="row"
          justifyContent="space-between"
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Typography variant="modalSubtitle">Share event Url </Typography>
          <CopyToClipboardButton text="Copy" link={link} />
        </Stack>
        <Typography
          variant="modalBody"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            textAlign: "center",
            maxWidth: "100%",
            padding: theme.spacing(2),
            border: `1px solid ${theme.colors.neutral.softGray}`,
            borderRadius: "8px",
            marginBottom: theme.spacing(4),
          }}
        >
          {link}
        </Typography>
        <Stack
          direction="row"
          justifyContent="space-between"
          style={{ marginBottom: theme.spacing(2) }}
        >
          <Typography variant="modalSubtitle">Share QR Code</Typography>
          <Box>
            {downloadStatus === "not downloaded" && (
              <Styled.DownloadButtonContainer onClick={handleDownloadClick}>
                <DownloadIcon
                  fontSize="inherit"
                  color="primary"
                  style={{
                    marginTop: "2px",
                  }}
                />
                <Typography
                  variant="body2"
                  style={{
                    marginLeft: theme.spacing(1),
                    marginRight: theme.spacing(1),
                    color: theme.colors.primary.slalomBlue,
                  }}
                >
                  {"Download"}
                </Typography>
              </Styled.DownloadButtonContainer>
            )}
            {downloadStatus === "downloading" && (
              <Styled.DownloadButtonContainer>
                <DownloadingIcon
                  fontSize="inherit"
                  color="primary"
                  style={{
                    marginTop: "2px",
                  }}
                />
                <Typography
                  variant="body2"
                  style={{
                    marginLeft: theme.spacing(1),
                    marginRight: theme.spacing(1),
                    color: theme.colors.primary.slalomBlue,
                  }}
                >
                  {"Download"}
                </Typography>
              </Styled.DownloadButtonContainer>
            )}
            {downloadStatus === "downloaded" && (
              <Styled.DownloadButtonContainer>
                <Typography variant="body2">{"Downloaded"}</Typography>
                <DownloadDoneIcon
                  fontSize="inherit"
                  color="success"
                  style={{
                    marginLeft: theme.spacing(1),
                    marginTop: "2px",
                  }}
                />
              </Styled.DownloadButtonContainer>
            )}
            {downloadStatus === "failed" && (
              <Styled.DownloadButtonContainer>
                <Typography variant="body2">{"Failed"}</Typography>
                <ErrorOutlineIcon
                  fontSize="inherit"
                  color="error"
                  style={{
                    marginLeft: theme.spacing(1),
                    marginTop: "2px",
                  }}
                />
              </Styled.DownloadButtonContainer>
            )}
          </Box>
        </Stack>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            padding: theme.spacing(2),
            border: `1px solid ${theme.colors.neutral.softGray}`,
            borderRadius: "8px",
            marginBottom: theme.spacing(4),
          }}
        >
          <div
            style={{ position: "relative", display: "inline-block" }}
            ref={qrCodeRef}
          >
            <QRCode value={link} size={200} level={"H"} includeMargin={true} />
            <img
              src={logo}
              alt={"QR-code-logo.png"}
              style={{
                width: "75px", // Adjust to your needs
                height: "75px", // Adjust to your needs
                borderRadius: "50%",
                border: "5px solid white", // Add a white border
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
        </Box>
        <Button
          variant="outlined"
          fullWidth
          size="large"
          onClick={() => {
            onClose();
          }}
        >
          Close
        </Button>
      </Box>
    </BaseModal>
  );
};

export default ShareEventModal;
