import * as Styled from "./EventSignUpsModal.styles";

import {
  Box,
  CircularProgress,
  Divider,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SignUp, SurveyAnswer } from "../../types/signup";
import {
  getEvent,
  getEventSignups,
  refreshEvent,
  refreshEventSignups,
} from "../../store/actions/eventActions";
import { useCallback, useEffect, useState } from "react";

import BaseModal from "../BaseModal/BaseModal";
import CloseIcon from "@mui/icons-material/Close";
import { Contact } from "../../components";
import { RootState } from "../../store/store";
import { createSelector } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";

type EventSignUpsModalProps = {
  open: boolean;
  onClose: () => void;
  navigateToEvent?: () => void;
  eventId: string;
};

const EventSignUpsModal = ({
  open,
  onClose,
  eventId,
}: EventSignUpsModalProps) => {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const [selectedSignupEmail, setSelectedSignupEmail] = useState<string | null>(
    null
  );
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"));

  const selectUser = (state: RootState) => state.user;
  const selectSignupsLoading = (state: RootState) =>
    state.event.signups.loading;
  const selectSignups = (state: RootState) => state.event.signups.data;
  const selectEventLoading = (state: RootState) => state.event.loading;
  const selectEvent = (state: RootState) => state.event.data;

  const selectUserAndEvents = createSelector(
    [
      selectUser,
      selectSignupsLoading,
      selectSignups,
      selectEventLoading,
      selectEvent,
    ],
    (user, signupsLoading, signups, eventLoading, event) => {
      return { user, signupsLoading, signups, eventLoading, event };
    }
  );

  const { signups, event, signupsLoading, eventLoading } =
    useSelector(selectUserAndEvents);

  useEffect(() => {
    if (eventId !== "" && open) {
      if (event && event.eventId === eventId) {
        dispatch(refreshEvent(eventId));
        dispatch(refreshEventSignups(eventId));
      } else {
        dispatch(getEvent(eventId));
        dispatch(getEventSignups(eventId));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, eventId]);

  const handleContactClick = useCallback((signupEmail: string) => {
    setSelectedSignupEmail((prevEmail) =>
      prevEmail === signupEmail ? null : signupEmail
    );
  }, []);

  const renderSurveyAnswers = (signup: SignUp) => {
    if (
      !signup.signUpSurveySubmission ||
      signup.signUpSurveySubmission.length === 0
    ) {
      return <Typography>No survey responses available.</Typography>;
    }

    return signup.signUpSurveySubmission.map(
      (answer: SurveyAnswer, index: number) => (
        <Box key={index}>
          <Typography
            variant="body2"
            sx={{
              color: theme.colors.secondary.darkPurple,
              marginBottom: theme.spacing(1),
            }}
          >
            {answer.type}
          </Typography>
          <Typography variant="h3" sx={{ marginBottom: theme.spacing(1) }}>
            {answer.question}
          </Typography>
          <Typography sx={{ marginBottom: theme.spacing(1) }}>
            {answer.answer}
          </Typography>
          {index !== signup.signUpSurveySubmission!.length - 1 && (
            <Divider
              sx={{
                width: "100%",
                marginTop: theme.spacing(3),
                marginBottom: theme.spacing(4),
                backgroundColor: theme.colors.secondary.purple,
              }}
            />
          )}
        </Box>
      )
    );
  };

  return (
    <BaseModal open={open} onClose={onClose} fullScreenTakeover padding="0px">
      {eventLoading || signupsLoading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            padding: (theme) => theme.spacing(8),
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Styled.ModalTitleContainer>
            <Typography
              variant="h2"
              sx={{ marginBottom: theme.spacing(2) }}
            >{`Event sign ups (${signups.length})`}</Typography>
          </Styled.ModalTitleContainer>
          <Styled.HorizontalStack direction="row">
            <Styled.LeftColumn isSelected={!!selectedSignupEmail}>
              <Styled.InnerLeftColumn>
                <Typography variant="modalBody">
                  <Typography
                    variant="modalSubtitle"
                    component="span"
                    sx={{ display: "inline" }}
                  >
                    {event?.title}
                  </Typography>
                  <Typography
                    variant="modalBody"
                    component="span"
                    sx={{ display: "inline" }}
                  >
                    {` (${event?.eventId})`}
                  </Typography>
                  <Divider
                    sx={{
                      width: "100%",
                      marginTop: theme.spacing(3),
                      marginBottom: theme.spacing(4),
                    }}
                  />
                </Typography>
                <Typography variant="h3">{`Employee (${signups.length})`}</Typography>
                {signups.map((signup: SignUp, index: number) => (
                  <>
                    <Contact
                      key={signup.participantEmail}
                      size={36}
                      displayChevron={isMobile}
                      name={signup.participantName}
                      onClick={() =>
                        handleContactClick(signup.participantEmail)
                      }
                      isSelected={
                        signup.participantEmail === selectedSignupEmail
                      }
                      selectedBorderColor={theme.colors.secondary.purple}
                      selectedBackgroundColor={`${theme.colors.secondary.purple}1A`}
                      selectedBoxShadowColor={
                        theme.colors.action.hoverShadowPurple
                      }
                    />
                    {index !== signups.length - 1 && (
                      <Divider
                        sx={{
                          width: "100%",
                          marginTop: theme.spacing(1),
                          marginBottom: theme.spacing(1),
                          backgroundColor: theme.colors.action.modalClose,
                        }}
                      />
                    )}
                  </>
                ))}
              </Styled.InnerLeftColumn>
            </Styled.LeftColumn>
            <Styled.RightColumn isSelected={!!selectedSignupEmail}>
              {selectedSignupEmail && isMobile && (
                <IconButton
                  sx={{
                    position: "absolute",
                    top: theme.spacing(1),
                    right: theme.spacing(2),
                  }}
                  onClick={() => setSelectedSignupEmail(null)}
                >
                  <CloseIcon />
                </IconButton>
              )}
              <Styled.InnerRightColumn>
                {selectedSignupEmail ? (
                  <>
                    <Typography
                      variant="h3"
                      sx={{ marginBottom: theme.spacing(3) }}
                    >
                      Survey Response
                    </Typography>
                    {signups
                      .filter(
                        (signup: SignUp) =>
                          signup.participantEmail === selectedSignupEmail
                      )
                      .map((signup: SignUp) => (
                        <Styled.SurveyAnswerContainer
                          key={signup.participantEmail}
                        >
                          {renderSurveyAnswers(signup)}
                        </Styled.SurveyAnswerContainer>
                      ))}
                  </>
                ) : (
                  <Typography variant="body1">
                    Select a signup to see details
                  </Typography>
                )}
              </Styled.InnerRightColumn>
            </Styled.RightColumn>
          </Styled.HorizontalStack>
        </>
      )}
    </BaseModal>
  );
};

export default EventSignUpsModal;
