import { Box, Divider, useTheme } from "@mui/material";
import {
  CompositeDecorator,
  ContentState,
  Editor,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
  convertFromHTML,
} from "draft-js";
import { useEffect, useRef, useState } from "react";

import { Controller } from "react-hook-form";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import LinkIcon from "@mui/icons-material/Link";
import LinkModal from "../../modals/LinkModal/LinkModal";
import Looks3Icon from "@mui/icons-material/Looks3";
import Looks4Icon from "@mui/icons-material/Looks4";
import Looks5Icon from "@mui/icons-material/Looks5";
import Looks6Icon from "@mui/icons-material/Looks6";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import React from "react";

type RichTextEditorProps = {
  name: string;
  control: any;
  label: string;
  setValue: any;
  required?: boolean;
  defaultValue?: string;
};

const findLinkEntities = (
  contentBlock: any,
  callback: any,
  contentState: any
) => {
  contentBlock.findEntityRanges((character: any) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === "LINK"
    );
  }, callback);
};

const Link = (props: any) => {
  const { contentState, entityKey } = props;
  const { url } = contentState.getEntity(entityKey).getData();

  return (
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ cursor: "pointer" }}
    >
      {props.children}
    </a>
  );
};

const RichTextEditor = ({
  name,
  control,
  setValue,
  required = false,
  label,
  defaultValue,
}: RichTextEditorProps) => {
  const decorator = new CompositeDecorator([
    { strategy: findLinkEntities, component: Link },
  ]);

  const [editorState, setEditorState] = useState(() => {
    const { contentBlocks, entityMap } = convertFromHTML(defaultValue || "");
    const contentState = ContentState.createFromBlockArray(
      contentBlocks,
      entityMap
    );
    return EditorState.createWithContent(contentState, decorator);
  });

  const editorRef = useRef<Editor | null>(null);
  const theme = useTheme();
  const [activeHeader, setActiveHeader] = useState("");
  const [activeStyles, setActiveStyles] = useState({
    bold: false,
    italic: false,
    underline: false,
  });
  const [showLinkModal, setShowLinkModal] = useState(false);

  const focusEditor = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  const handleKeyCommand = (command: string) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  const toggleInlineStyle = (style: string) => {
    setEditorState((prevState) => {
      let newState;
      newState = RichUtils.toggleInlineStyle(prevState, style);

      updateActiveStyles(newState);
      return newState;
    });
  };

  const toggleBlockType = (blockType: string) => {
    if (activeHeader === blockType) {
      setEditorState(RichUtils.toggleBlockType(editorState, "unstyled"));
      setActiveHeader("");
    } else {
      setEditorState(RichUtils.toggleBlockType(editorState, blockType));
      setActiveHeader(blockType);
    }
    updateActiveStyles(editorState);
  };

  const updateActiveStyles = (state: EditorState) => {
    const currentStyle = state.getCurrentInlineStyle();
    const blockType = RichUtils.getCurrentBlockType(state);

    setActiveStyles({
      bold: currentStyle.has("BOLD"),
      italic: currentStyle.has("ITALIC"),
      underline: currentStyle.has("UNDERLINE"),
    });
    setActiveHeader(blockType);
  };

  useEffect(() => {
    focusEditor();
  }, []);

  const handleToolbarClick = (e: React.MouseEvent, action: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    action();
    setTimeout(focusEditor, 0);
  };

  const handleEditorChange = (newState: EditorState) => {
    setEditorState(newState);
    setValue(name, newState);
    updateActiveStyles(newState);
  };

  const addLink = (displayText: string, url: string) => {
    let link =
      url.includes("http://") || url.includes("https://")
        ? url
        : `https://${url}`;
    const contentState = editorState.getCurrentContent();
    const contentWithEntity = contentState.createEntity("LINK", "MUTABLE", {
      url: link,
    });
    const entityKey = contentState.getLastCreatedEntityKey();
    const selection = editorState.getSelection();

    const contentWithText = Modifier.replaceText(
      contentWithEntity,
      selection,
      displayText,
      editorState.getCurrentInlineStyle(),
      entityKey
    );

    const newState = EditorState.push(
      editorState,
      contentWithText,
      "apply-entity"
    );
    setEditorState(newState);
  };

  const removeLink = () => {
    const selection = editorState.getSelection();

    if (selection.isCollapsed()) return;

    const newState = RichUtils.toggleLink(editorState, selection, null);

    setEditorState(newState);
  };

  const getSelectedText = (editorState: EditorState): string => {
    const selectionState: SelectionState = editorState.getSelection();
    const contentState: ContentState = editorState.getCurrentContent();
    const startKey = selectionState.getStartKey();
    const endKey = selectionState.getEndKey();
    const startOffset = selectionState.getStartOffset();
    const endOffset = selectionState.getEndOffset();

    const blockMap = contentState.getBlockMap();
    const selectedBlocks = blockMap
      .skipUntil((_, k) => k === startKey)
      .takeUntil((_, k) => k === endKey)
      .concat([[endKey, blockMap.get(endKey)]]);

    let selectedText = "";

    selectedBlocks.forEach((block: any) => {
      const key = block.getKey();
      const text = block.getText();
      const start = key === startKey ? startOffset : 0;
      const end = key === endKey ? endOffset : text.length;
      selectedText += text.slice(start, end);
    });

    return selectedText;
  };

  return (
    <Box sx={{ marginTop: theme.spacing(2) }}>
      <LinkModal
        open={showLinkModal}
        onClose={() => {
          setShowLinkModal(false);
        }}
        onSubmit={addLink}
        selection={getSelectedText(editorState)}
      />
      <Box
        sx={{
          border: `1px solid ${theme.colors.neutral.softGray}`,
          borderRadius: theme.spacing(1),
          marginTop: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
            backgroundColor: theme.colors.neutral.lightGray,
          }}
        >
          {[
            "header-one",
            "header-two",
            "header-three",
            "header-four",
            "header-five",
            "header-six",
          ].map((type, index) => (
            <div
              key={index}
              onClick={(e) =>
                handleToolbarClick(e, () => toggleBlockType(type))
              }
            >
              {React.createElement(
                type === "header-one"
                  ? LooksOneIcon
                  : type === "header-two"
                  ? LooksTwoIcon
                  : type === "header-three"
                  ? Looks3Icon
                  : type === "header-four"
                  ? Looks4Icon
                  : type === "header-five"
                  ? Looks5Icon
                  : Looks6Icon,
                {
                  sx: {
                    width: "18px",
                    height: "18px",
                    cursor: "pointer",
                    marginRight: theme.spacing(1.5),
                    marginTop: "4px",
                    color:
                      activeHeader === type
                        ? theme.colors.primary.slalomBlue
                        : theme.colors.neutral.black,
                  },
                }
              )}
            </div>
          ))}

          <Divider
            orientation="vertical"
            sx={{ height: 24, margin: `0 ${theme.spacing(1)} ` }}
          />

          <FormatBoldIcon
            onMouseDown={(e) =>
              handleToolbarClick(e, () => toggleInlineStyle("BOLD"))
            }
            sx={{
              cursor: "pointer",
              marginRight: theme.spacing(1.5),
              marginLeft: theme.spacing(1),
              color: activeStyles.bold
                ? theme.colors.primary.slalomBlue
                : theme.colors.neutral.black,
              width: "18px",
              height: "18px",
            }}
          />
          <FormatItalicIcon
            onMouseDown={(e) =>
              handleToolbarClick(e, () => toggleInlineStyle("ITALIC"))
            }
            sx={{
              cursor: "pointer",
              marginRight: theme.spacing(1.5),
              color: activeStyles.italic
                ? theme.colors.primary.slalomBlue
                : theme.colors.neutral.black,
              width: "18px",
              height: "18px",
            }}
          />
          <FormatUnderlinedIcon
            onMouseDown={(e) =>
              handleToolbarClick(e, () => toggleInlineStyle("UNDERLINE"))
            }
            sx={{
              cursor: "pointer",
              marginRight: theme.spacing(1.5),
              color: activeStyles.underline
                ? theme.colors.primary.slalomBlue
                : theme.colors.neutral.black,
              width: "18px",
              height: "18px",
            }}
          />
          <Divider
            orientation="vertical"
            sx={{ height: 24, margin: `0 ${theme.spacing(1)}` }}
          />
          <FormatListBulletedIcon
            onClick={(e) =>
              handleToolbarClick(e, () =>
                toggleBlockType("unordered-list-item")
              )
            }
            sx={{
              cursor: "pointer",
              marginRight: theme.spacing(1.5),
              marginLeft: theme.spacing(1),
              color:
                activeHeader === "unordered-list-item"
                  ? theme.colors.primary.slalomBlue
                  : theme.colors.neutral.black,
              width: "18px",
              height: "18px",
            }}
          />
          <FormatListNumberedIcon
            onClick={(e) =>
              handleToolbarClick(e, () => toggleBlockType("ordered-list-item"))
            }
            sx={{
              cursor: "pointer",
              marginRight: theme.spacing(1.5),
              color:
                activeHeader === "ordered-list-item"
                  ? theme.colors.primary.slalomBlue
                  : theme.colors.neutral.black,
              width: "18px",
              height: "18px",
            }}
          />
          <Divider
            orientation="vertical"
            sx={{ height: 24, margin: `0 ${theme.spacing(1)} ` }}
          />
          <LinkIcon
            sx={{
              cursor: "pointer",
              marginLeft: theme.spacing(1),
              color:
                activeHeader === "link"
                  ? theme.colors.primary.slalomBlue
                  : theme.colors.neutral.black,
              width: "18px",
              height: "18px",
            }}
            onClick={() => {
              const selection = editorState.getSelection();
              const contentState = editorState.getCurrentContent();
              const startKey = selection.getStartKey();
              const startOffset = selection.getStartOffset();
              const block = contentState.getBlockForKey(startKey);
              const entityKey = block.getEntityAt(startOffset);

              if (entityKey) {
                const entity = contentState.getEntity(entityKey);
                if (entity.getType() === "LINK") {
                  removeLink();
                } else {
                  setShowLinkModal(true);
                }
              } else {
                setShowLinkModal(true);
              }
            }}
          />
        </Box>
        <Box
          sx={{
            minHeight: "13em",
            padding: theme.spacing(2),
          }}
          onMouseDown={focusEditor}
        >
          <Controller
            name={name}
            control={control}
            rules={{
              required: { value: required, message: `${label} is required` },
            }}
            render={({ field, fieldState }) => (
              <>
                <Editor
                  ref={editorRef}
                  editorState={editorState}
                  onChange={(newState) => {
                    handleEditorChange(newState);
                    field.onChange(newState);
                  }}
                  handleKeyCommand={handleKeyCommand}
                  onFocus={() => updateActiveStyles(editorState)}
                />
                {fieldState.error && (
                  <span style={{ color: "red" }}>
                    {fieldState.error.message}
                  </span>
                )}
              </>
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RichTextEditor;
