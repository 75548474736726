import { Box, CircularProgress, useTheme } from "@mui/material";

import BaseModal from "../BaseModal/BaseModal";

type LoadingModalProps = {
  open: any;
  onClose: any;
};

const LoadingModal = ({ open, onClose }: LoadingModalProps) => {
  const theme = useTheme();
  return (
    <BaseModal open={open} onClose={onClose} showCloseButton={false}>
      <Box
        padding={theme.spacing(12)}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress />
      </Box>
    </BaseModal>
  );
};

export default LoadingModal;
