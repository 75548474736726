import { Box, Stack, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

export const PaddedContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

export const HeaderStack = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(4),
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
  width: "80%",
  [theme.breakpoints.down("tablet")]: {
    width: "100%",
  },
}));
