import * as Styled from "./ScrollToTopFAB.styles";

import { useEffect, useState } from "react";

import { Fade } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

type ScrollToTopFABProps = {
  distanceToShow?: number;
};

const ScrollToTopFAB = ({ distanceToShow = 100 }: ScrollToTopFABProps) => {
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    function onScroll() {
      let currentPosition = window.scrollY;
      if (currentPosition > distanceToShow) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Fade in={showScrollToTop}>
      <Styled.ScrollToTopFab
        variant="extended"
        color="primary"
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }}
      >
        <KeyboardArrowUpIcon sx={{ mr: 1 }} />
        Top
      </Styled.ScrollToTopFab>
    </Fade>
  );
};

export default ScrollToTopFAB;
