import * as Styled from "./LoginPage.styles";

import { PageContainer, SiteMaintenance } from "../../components";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import { COGNITO_AUTH_ENDPOINT } from "../../services/endpoints";
import { CircularProgress } from "@mui/material";
import { authenticateUser } from "../../store/actions/authActions";
import { useAppDispatch } from "../../store/hooks";
import { useSelector } from "react-redux";

const LoginPage = () => {
  const { isAuthorized, error } = useSelector((state: any) => state.auth);

  const SAFE_PATHS = ["/events", "/manage", "/edit", "/create", "/live"];

  const [showError, setShowError] = useState(false);

  useEffect(() => {
    let errorTimeout: string | number | NodeJS.Timeout | undefined;
    if (error) {
      errorTimeout = setTimeout(() => {
        setShowError(true);
      }, 10000); // Delay error message for 10 seconds in case of a redirect/refresh
    } else {
      setShowError(false);
    }

    return () => {
      clearTimeout(errorTimeout);
    };
  }, [error]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const state = urlParams.get("state");
  const location = useLocation();

  const initiateAuthorizationCodeFlow = useCallback((deepLink: string) => {
    const authUrl = COGNITO_AUTH_ENDPOINT(encodeURIComponent(deepLink));
    window.location.href = authUrl;
  }, []);

  useEffect(() => {
    const deepLink = location.state?.from || "/events";
    if (code && !isAuthorized) {
      dispatch(authenticateUser(code));
    } else if (!isAuthorized) {
      initiateAuthorizationCodeFlow(deepLink);
    } else if (state) {
      const decodedState = decodeURIComponent(state);
      const url = new URL(
        decodedState,
        process.env.REACT_APP_COGNITO_REDIRECT_URL
      );
      const decodedStateBasePath = "/" + url.pathname.split("/")[1];
      if (SAFE_PATHS.includes(decodedStateBasePath)) {
        navigate(url.pathname + url.search);
      } else {
        navigate("/events");
      }
    } else {
      navigate("/events");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code, isAuthorized, state]);

  if (showError) {
    return (
      <PageContainer>
        <SiteMaintenance />
      </PageContainer>
    );
  } else {
    return (
      <PageContainer>
        <Styled.LoaderContainer>
          <CircularProgress />
        </Styled.LoaderContainer>
      </PageContainer>
    );
  }
};

export default LoginPage;
