import { Box, Skeleton, Stack, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";

export const PaddedContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
}));

export const HeaderStack = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

export const Header = styled(Box)(({ theme }) => ({
  boxShadow: `0px 6px 20px 0px ${theme.colors.action.hoverShadow}`,
  margin: `0 ${theme.spacing(-3)} ${theme.spacing(4)}`,
  padding: `0 ${theme.spacing(3)} ${theme.spacing(2)}`,
}));

export const RefreshEventsContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row-reverse",
  width: "10%",
}));

export const SubtitleText = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral.darkGray,
}));

export const SubtitleContainerSkeleton = styled(Skeleton)(({ theme }) => ({
  height: "56px",
  width: "100%",
  marginBottom: theme.spacing(3),
}));

export const CreateEventActionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "row-reverse",
  width: "45%",
  [theme.breakpoints.down("tablet")]: {
    width: "100%",
  },
}));

export const HeaderText = styled(Typography)(({ theme }) => ({
  width: "80%",
  [theme.breakpoints.down("tablet")]: {
    width: "100%",
  },
}));

export const SubtitleActionText = styled(Typography)(({ theme }) => ({
  cursor: "pointer",
  transition: "opacity 0.3s ease",
  "&:hover": {
    opacity: 0.7,
  },
  color: theme.colors.primary.slalomDarkBlue,
  textAlign: "right",
  marginTop: theme.spacing(2),
}));

export const SubtitleActionTextLoading = styled(Typography)(({ theme }) => ({
  color: theme.colors.neutral.gray,
  textAlign: "right",
  marginTop: theme.spacing(2),
}));

export const SuperScript = styled("sup")(() => ({
  position: "relative",
  fontSize: 10,
  top: 2.3,
}));
