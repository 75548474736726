import * as Styled from "./PhotosTab.styles";

import {
  Box,
  CircularProgress,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import { UploadEventPhotoModal, ViewPhotoModal } from "../../../../modals";
import { useRef, useState } from "react";

import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import RefreshIcon from "@mui/icons-material/Refresh";
import { S3Image } from "../../../../types/s3image";
import { Stack } from "@mui/system";
import { refreshLiveEventPhotos } from "../../../../store/actions/liveEventActions";
import { useAppDispatch } from "../../../../store/hooks";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/material";

const PhotosTab = () => {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("desktop"));
  const columns = isLargeScreen ? 4 : 1;

  const eventLoading = useSelector((state: any) => state.liveEvent.loading);

  const event = useSelector((state: any) => state.liveEvent.data);

  const eventImages = useSelector((state: any) => state.liveEvent.photos);

  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectedImage, setSelectedImage] = useState<S3Image | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  const [openPhotoUploadDialog, setOpenPhotoUploadDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [uploadEventPhotoFile, setUploadEventPhotoFile] = useState<File | null>(
    null
  );

  const handleImageClick = (item: S3Image) => {
    setSelectedImage(item);
    setOpenDialog(true);
  };

  const handleFileChange = (event: any) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (file) {
      setUploadEventPhotoFile(file);
      const reader = new FileReader();

      // Set up the reader onload function
      reader.onload = (e: any) => {
        // Set the image source with the data URL
        setImageSrc(e.target.result);
      };

      // Read the file as a data URL
      reader.readAsDataURL(file);
      setOpenPhotoUploadDialog(true);
    } else {
      setUploadEventPhotoFile(null);
    }
  };

  const handleInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  const closeModal = () => {
    setOpenPhotoUploadDialog(false);
  };

  return (
    <Styled.PaddedContainer>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          marginBottom: theme.spacing(3),
        }}
      >
        <Box display="flex" alignItems="center">
          <Box marginRight="8px">
            {eventImages.refreshing ? (
              <Box margin="8px">
                <CircularProgress size={"24px"} />
              </Box>
            ) : (
              <IconButton
                onClick={() => {
                  dispatch(refreshLiveEventPhotos(event.eventId));
                }}
              >
                <RefreshIcon />
              </IconButton>
            )}
          </Box>
          {!eventLoading && event?.eventId !== "" ? (
            <Typography variant="h3">{`Photos (${eventImages.data.length})`}</Typography>
          ) : (
            <Skeleton variant="text" sx={{ width: "100px", height: "24px" }} />
          )}
        </Box>

        <Styled.AddPhotoContainer
          onClick={() => {
            if (!eventImages.loading && !eventLoading) {
              handleInputClick();
            }
          }}
          style={{
            ...(!eventImages.loading && !eventLoading
              ? {}
              : {
                  pointerEvents: "none",
                  opacity: 0.5,
                }),
          }}
        >
          <Styled.AddIcon />
          <Typography style={{ color: "black" }}>Add Photo</Typography>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            accept="image/jpeg, image/png, image/heic"
            onChange={handleFileChange}
            disabled={eventImages.loading || eventLoading}
          />
        </Styled.AddPhotoContainer>
        {!eventLoading && event?.eventId !== "" && (
          <UploadEventPhotoModal
            photoName={uploadEventPhotoFile ? uploadEventPhotoFile.name : ""}
            selectedImg={imageSrc}
            open={openPhotoUploadDialog}
            onClose={closeModal}
            eventId={event?.eventId}
            uploadEventPhotoFile={uploadEventPhotoFile}
          />
        )}
      </Stack>
      {!eventImages.success ? (
        <ImageList gap={24} cols={columns}>
          {Array.from(new Array(12)).map((_, index) => (
            <ImageListItem key={index}>
              <Skeleton
                variant="rectangular"
                style={{ borderRadius: "8px", height: "200px" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      ) : (
        <ImageList gap={24} cols={columns}>
          {eventImages?.data.map((photo: S3Image) => (
            <ImageListItem
              key={photo.key}
              onClick={() => handleImageClick(photo)}
            >
              <img
                srcSet={photo.s3Url}
                src={photo.s3Url}
                alt={photo.name}
                loading="lazy"
                style={{ borderRadius: "8px", height: "200px" }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
      <ViewPhotoModal
        caption={selectedImage?.caption || ""}
        selectedImg={selectedImage?.s3Url}
        currentTime={selectedImage?.lastModified?.toString() || ""}
        uploaderName={selectedImage?.uploaderName || ""}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </Styled.PaddedContainer>
  );
};

export default PhotosTab;
