import {
  Box,
  Button,
  Divider,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type MenuItemProps = {
  label: string;
  icon: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
};

type MenuButtonProps = {
  menuItems: MenuItemProps[];
  menuLabel: string;
  disabled?: boolean;
  buttonStyles?: React.CSSProperties;
};

const MenuButton = ({
  menuItems,
  menuLabel,
  disabled = false,
  buttonStyles = {},
}: MenuButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.down("desktop"));

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-haspopup="true"
        variant="outlined"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={disabled}
        sx={buttonStyles}
      >
        {menuLabel}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: isDesktop ? "100%" : "auto",
            maxWidth: "calc(100% - 32px)",
            maxHeight: "calc(100vh - 32px)",
            marginLeft: 0,
            marginRight: 0,
            padding: 0,
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
            boxSizing: "border-box",
            overflow: "hidden",
            boxShadow: `6px 6px 6px 6px ${theme.colors.action.hoverShadow}`,
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {menuItems.map((item, index) => (
          <Box key={index} width="100%">
            <MenuItem
              onClick={() => {
                item.onClick();
                handleClose();
              }}
              disabled={item.disabled}
              sx={{ width: "100%", paddingLeft: "16px", paddingRight: "16px" }}
            >
              {item.icon}
              <Box ml={1}>{item.label}</Box>
            </MenuItem>
            {index < menuItems.length - 1 && <Divider />}
          </Box>
        ))}
      </Menu>
    </>
  );
};

export default MenuButton;
