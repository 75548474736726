import * as Styled from "./LiveEventWelcomeModal.styles";

import { Box, Typography, useTheme } from "@mui/material";

import BaseModal from "../BaseModal/BaseModal";
import { useState } from "react";

type LiveEventWelcomeModalProps = {
  open: any;
  onClose: any;
};

const LiveEventWelcomeModal = ({
  open,
  onClose,
}: LiveEventWelcomeModalProps) => {
  const theme = useTheme();
  const [hovered, setHovered] = useState(false);
  return (
    <BaseModal open={open} onClose={onClose} showCloseButton={false}>
      <Box style={{ textAlign: "center" }}>
        <Styled.CelebrationIcon fill={theme.colors.primary.slalomBlue} />
        <Styled.ModalTitleContainer>
          <Typography variant="h2">Welcome to the party</Typography>
        </Styled.ModalTitleContainer>
        <Typography
          variant="modalSubtitle"
          style={{ marginBottom: theme.spacing(2) }}
        >
          You're now checked-in to the live event. Enjoy!
        </Typography>
        <Typography variant="body2">
          {`Before the event, please fill out this `}
          <span
            style={{
              color: theme.colors.primary.slalomBlue,
              textDecoration: "underline",
              cursor: "pointer",
              transition: "opacity 0.3s ease",
              opacity: hovered ? 0.7 : 1,
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onClick={() => {
              window.open(
                "https://slalom1.na1.echosign.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhAQGijvLN7znz6NyODCVKpcLGqdP-FohfL3JzXnLpRcfJmbmeZUdWU2iQ4JEN9x4Bk",
                "_blank"
              );
            }}
          >
            Photo Release Waiver
          </span>
          .{" "}
          {`For the “Subject matter of
          Media” part of the form, please enter {x}.`}
        </Typography>
        <Styled.ConfirmButton
          variant="contained"
          fullWidth
          size="large"
          onClick={() => {
            onClose();
          }}
        >
          All set, let's go!
        </Styled.ConfirmButton>
      </Box>
    </BaseModal>
  );
};

export default LiveEventWelcomeModal;
