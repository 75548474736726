import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SelectedImage = styled("img")(({ theme }) => ({
  objectFit: "cover",
  objectPosition: "center",
  width: "100%",
  display: "block",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "8px",
  marginBottom: theme.spacing(1),
}));

export const CurrentTimeText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.modalBody.fontSize,
  color: theme.colors.neutral.darkGray,
}));
